import React from 'react';

import { v4 as uuidv4 } from 'uuid';

export default function DisplaySightingTags(props) {


	const SingleFlag = (props) => {
		let title = props.title;

		if (title === 'rhdv-suspected') {
			title = 'RHDV suspected'
		} else if (title === 'rhdv-confirmed') {
			title = 'RHDV confirmed'
		}

		return (
			<li key={uuidv4()} className={`sighting-tag ${props.tagSize && 'sighting-tag--' + props.tagSize}`
			}>
				{title}
			</li>
		)
	}

	const SightingFlags = (props) => {


		if (props.flags && props.flags.length > 0) {
			return (
				props.flags.map((flag) => {
					return <SingleFlag key={uuidv4()} size={props.size} title={flag} {...props} />
				})
			)
		} else {
			return null
		}

	}

	const CountFlag = (props) => {
		if (props.count && props.count > 0) {
			let title = props.count === 1 ? '1 rabbit' : props.count + ' rabbits';
			return (<SingleFlag title={title} {...props} />)
		} else {
			return null
		}
	}

	const ImagesFlag = (props) => {
		if (props.images && props.images.length > 0) {
			let title = props.images.length === 1 ? '1 image' : props.images.length + ' images';
			return (<SingleFlag title={title} {...props} />)
		} else {
			return null
		}
	}



	return (
		<>
			<SightingFlags {...props} />
			<CountFlag {...props} />
			<ImagesFlag {...props} />
		</>
	)

}