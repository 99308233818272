const dateOptions = [
	{
		value: 'all-time',
		label: 'All time'
	},
	{
		value: 'last-year',
		label: 'Last year'
	},
	{
		value: 'last-90-days',
		label: 'Last 90 days'
	},
	{
		value: 'last-30-days',
		label: 'Last 30 days'
	},
	{
		value: 'last-7-days',
		label: 'Last 7 days'
	},
]

const statusOptions = [
	{
		value: 'all',
		label: 'All'
	},
	{
		value: 'general',
		label: 'General'
	},
	{
		value: 'urgent',
		label: 'Urgent'
	},
	{
		value: 'rescued',
		label: 'Rescued'
	},
	{
		value: 'deceased',
		label: 'Deceased'
	}
]

const countOptions = [
	{
		value: 1000,
		label: 'Any',
	},
	{
		value: 1,
		label: 'Individuals',
	},
	{
		value: 10,
		label: 'Small Groups',
		additionalLabel: '2-10'
	},
	{
		value: 11,
		label: 'Colonies',
		additionalLabel: '11+'
	}
]

module.exports = { dateOptions, statusOptions, countOptions }