import React from "react";

import { useSelector, useDispatch } from "react-redux";
import { updateAppState } from "../actions/appStateActions";

import { Link } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "./Logo";

const Sidebar = (props) => {
  const dispatch = useDispatch();

  const location = useLocation();
  // console.log(location.pathname);

  // console.log(location.pathname)
  let currentPage = location.pathname
  currentPage = currentPage.split('/').join('')

  // const reportPanelOpen = useSelector(
  //   (state) => state.appState.reportPanelOpen
  // );
  // const sightingModalIsOpen = useSelector(
  //   (state) => state.appState.singleSightingModalIsOpen
  // );

  let mobileNavIsOpen = useSelector(
    (state) => state.appState.mobileNavIsOpen
  );

  // let navIsOpen = useSelector(
  //   (state) => state.appState.navIsOpen
  // );

  const NAV_LINKS = [
    {
      label: 'Sighting map',
      href: '/sighting-map',
      icon: 'map-marker-alt'
    },
    {
      label: 'Sighting list',
      href: '/sighting-list',
      icon: 'list'
    },
    {
      label: 'Report sighting',
      href: '/report',
      icon: 'map-marked-alt'
    },
    {
      label: 'Admin',
      href: '/admin',
      icon: 'user'
    }
  ]

  function NavLink({ icon, label, href }) {
    return (
      <Link to={href} className={`nav__link`} onClick={() => dispatch(updateAppState({ mobileNavIsOpen: false }))}>
        <div className='nav-link__icon-wrapper text-gray-green-50'>
          <FontAwesomeIcon

            icon={icon} />
        </div>
        <span>{label}</span>
      </Link>
    )
  }

  function NavLinks() {
    return (
      <div className="nav__links">
        {NAV_LINKS.map((link, i) => {
          return <NavLink key={`nav-link-${i}`} {...link} />
        })}
      </div>)
  }


  const handleMobileNavToggle = () => {
    dispatch(updateAppState({ mobileNavIsOpen: !mobileNavIsOpen }))
  }
  return (
    <>


      {/* <div className='nav-icon-col'>
        <button onClick={handleMobileNavToggle} className='mobile-nav-toggle nav-icon-col-item'>
          <FontAwesomeIcon icon='bars' className='text-green-80' />
        </button>
        <Link to="/sightings" className='nav-icon-col-item'><FontAwesomeIcon icon='map-marker-alt' className='text-green-80' /></Link>
        <Link to="/sightings" className='nav-icon-col-item'><FontAwesomeIcon icon='coffee' className='text-green-80' /></Link>
        <Link to="/sightings" className='nav-icon-col-item'><FontAwesomeIcon icon='filter' className='text-green-80' /></Link>
      </div> */}

      <nav className={`public-nav flex-col   ${currentPage && `${currentPage}-page`} ${props.page ? props.page : ''}`}>
        <div className='sidebar-header'>
          <div className="nav-brand flex items-center px-4">
            <Link to="/" className='text-green-90'>
              <Logo />
            </Link>
          </div>
          <button onClick={() => handleMobileNavToggle()} className='nav-toggle shadow-sm flex md:hidden'>
            <FontAwesomeIcon icon='times' className='text-green-80' />
          </button>
        </div>
        <div className='public-nav-inner'>

          <div className='public-nav-body relative h-full'>

            <NavLinks />
            <div className='mt-auto'>
              <div className='bg-gray-green-20 p-3 pb-6 text-gray-green-80 text-sm '>
                <span className='font-bold flex mb-2'>Thank you for visiting this project</span>
                We're currently in beta and trying resolve the inevitable technical issues that will arise. If you've encountered any issues please let us know <a href='https://airtable.com/shr5NgknI5W8o3UET' className='font-bold hover:underline' target='_blank'>here</a></div>

            </div>

            {/* {currentPage && currentPage === 'sightings' && <SidebarSightings />} */}

          </div>
        </div>

      </nav>
    </>
  );
};

export default Sidebar;
