import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom'
import { updateAppState } from "../../../actions/appStateActions";

import "./FormTabs.scss"

function FormTabs({ step2IsDisabled, step3IsDisabled, tabClickFn }) {

	const dispatch = useDispatch()
	const history = useHistory()
	const location = useLocation();

	const storeLocation = useSelector((state) => state.newSighting.location);
	const storeSpecies = useSelector((state) => state.newSighting.species);
	const storeCount = useSelector((state) => state.newSighting.count);
	const storeSightingDate = useSelector((state) => state.newSighting.sightingDate);
	const storeStatus = useSelector((state) => state.newSighting.status);


	const [stuck, setStuck] = useState(false)
	const ref = useRef()


	useEffect(() => {
		const cachedRef = ref.current
		const observer = new IntersectionObserver(
			([e]) => {
				console.log(e.intersectionRatio)
				console.log(stuck)
				setStuck(e.intersectionRatio < 1)
			},
			{
				threshold: [1],
				rootMargin: '-56px'
			}
		)
		observer.observe(cachedRef)
		return () => observer.unobserve(cachedRef)
	}, [ref])


	const stepTwoDisabled = () => {
		let disabled = storeLocation ? false : true
		return disabled
	}

	const stepThreeDisabled = () => {
		let disabled = true

		if (storeSpecies && storeCount && storeSightingDate && storeStatus && storeLocation) {
			disabled = false
		}

		return disabled
	}


	function handleClick(destination) {
		tabClickFn && tabClickFn()
		// dispatch(updateAppState({ formStep: destination }))
		history.push(`/report/${destination}`)
	}

	function Tab({ disabled, label, destination }) {
		return <button
			disabled={disabled}
			className={`form-tabs__tab ${location.pathname.includes(destination) ? 'active' : ''}`}
			onClick={() => handleClick(destination)}>{label}</button>
	}

	return <>
		<div ref={ref} style={{ top: '-1px' }}></div>
		<div className={`form-tabs ${stuck ? 'shadow-md bg-white' : ''}`}>
			<div className='form-tabs__container'>
				<Tab disabled={false} destination='location' label='1. Location' />
				<Tab disabled={stepTwoDisabled()} destination='details' label='2. Details' />
				<Tab disabled={stepThreeDisabled()} destination='contact' label='3. Contact' />
			</div>
		</div>
	</>
}

export default FormTabs