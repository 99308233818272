import React, { useEffect } from "react";
import { useHistory } from 'react-router-dom'
import { useForm, useWatch, useFormState, FormProvider } from "react-hook-form";

// import { DevTool } from "@hookform/devtools";

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

//Redux
import { connect, useSelector, useDispatch } from "react-redux";
import { updateSightingDetails } from "../../actions/newSightingActions";
import { updateAppState } from "../../actions/appStateActions";

// Components
import ImageUpload from "./ImageUpload/ImageUpload";
import FormFieldSpecies from "./FormFieldSpecies"
import FormFieldInjured from "./FormFieldInjured"
import FormFieldRHDV from "./FormFieldRHDV";
import FormFieldStatus from './FormFieldStatus'
import FormFieldFlags from "./FormFieldFlags";
import FormFieldAppearance from "./FormFieldAppearance";
import FormFieldDate from "./FormFieldDate";
import FormFieldCount from "./FormFieldCount";
import FormFooter from "./FormFooter/FormFooter";
import FormFooterButton from "./FormFooter/FormFooterButton";


const FormSightingDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const formStep = useSelector((state) => state.formStep);

  const storeCount = useSelector((state) => state.newSighting.count);

  const storeLocation = useSelector((state) => state.newSighting.location);


  const storeSpecies = useSelector((state) => state.newSighting.species)
  const storeStatus = useSelector((state) => state.newSighting.status)
  const storeInjuredWild = useSelector((state) => state.newSighting.injuredWild)
  const storeRhdv = useSelector((state) => state.newSighting.rhdv)

  const storeAppearance = useSelector((state) => state.newSighting.appearance)
  const storeFlags = useSelector((state) => state.newSighting.flags)
  const storeSightingDate = useSelector((state) => state.newSighting.sightingDate)





  console.log("<FormSightingDetails/> RENDER")


  const methods = useForm();

  const { isValid, errors } = useFormState({ control: methods.control })
  // const { errors } = useFormState({ control });



  // useEffect(() => {

  //   formStep !== 'details' && dispatch(updateAppState({ formStep: 'details' }))

  // }, [formStep])


  // Redirect to Location page if location is missing
  useEffect(() => {
    if (!storeLocation) {
      history.push(`/report/location`)
    }
  }, [])


  function setFormToStoreValues() {
    console.log('setFormToStoreValues')
    // Set form values to match redux store on mount
    // sightingStore.species && setValue('species', sightingStore.species)
    methods.setValue('species', storeSpecies ? storeSpecies : 'domestic')
    methods.setValue('count', storeCount)
    methods.setValue('injuredWild', storeInjuredWild)
    methods.setValue('wildRhdv', storeRhdv)
    methods.setValue('appearance', storeAppearance)
    methods.setValue('flags', storeFlags)
    methods.setValue('status', storeStatus ? storeStatus : 'general')
    methods.setValue('sightingDate', storeSightingDate)

  }
  function saveDataToStore() {
    dispatch(updateSightingDetails({ species: methods.getValues('species') }));
    dispatch(updateSightingDetails({ injuredWild: methods.getValues('injuredWild') }));
    dispatch(updateSightingDetails({ count: parseInt(methods.getValues('count')) }));
    dispatch(updateSightingDetails({ appearance: methods.getValues('appearance') }));
    dispatch(updateSightingDetails({ flags: methods.getValues('flags') }));
    dispatch(updateSightingDetails({ sightingDate: new Date(methods.getValues('sightingDate')).getTime() }));
  }

  function handleClickNext(e, data) {
    //Trigger form validation
    methods.trigger()

    if (hasRequiredData()) {
      dispatch(updateAppState({ formStep: 'contact' }))
      history.push(`/report/contact`)
    }


  }

  useEffect(() => {
    console.log({ storeCount })
    // If these fields have errors, trigger revalidation when their value changes
    errors?.status && methods.trigger()
    errors?.species && methods.trigger()
    errors?.count && methods.trigger()
  }, [storeCount, storeSightingDate])

  useEffect(() => {
    console.log(`<FormSightingDetails/> MOUNT`)
    setFormToStoreValues()
  }, [])

  useEffect(() => {
    console.log(errors)
  }, [errors])

  const hasRequiredData = () => {
    if (storeSpecies && storeStatus && storeSightingDate && storeCount) {
      return true
    } else {
      return false
    }
  }

  const showDetailFields = () => {
    let showFields = false
    if (storeSpecies === 'domestic') {
      showFields = true
    } else if (storeSpecies === 'wild' && storeInjuredWild === 'false' && storeRhdv === 'yes') {
      showFields = true
    }

    return showFields
  }

  return (
    <>
      <FormProvider {...methods}  >

        {/* <DevTool {...methods} /> */}

        {/* Always show this field */}
        <FormFieldSpecies {...methods} />


        {storeSpecies === 'wild' &&
          <FormFieldInjured {...methods} />
        }
        {storeSpecies === 'wild' && storeInjuredWild === 'false' &&
          <FormFieldRHDV {...methods} />
        }

        <div className={showDetailFields() ? 'block' : 'hidden'}>
          <>
            <FormFieldAppearance {...methods} />
            <FormFieldCount />
            <FormFieldFlags />
            <FormFieldStatus />
            <FormFieldDate />
            <ImageUpload />
          </>
        </div>

        <FormFooter
          prevButton={<FormFooterButton label='Back' clickFn={() => history.push('/report/location')} />}
          nextButton={<FormFooterButton enabled={hasRequiredData()} label='Next' clickFn={() => handleClickNext()} />}
        />

        {/* <button onClick={(e) => handleClick(e)}>Next</button> */}
      </FormProvider>
    </>
  );
};


export default connect()(FormSightingDetails);
