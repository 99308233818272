import React, { useContext } from "react";
import { firebaseAuth } from "../../AuthProvider";
import { useSelector, useDispatch } from "react-redux";

import { updateAuthedUser, resetAuthedUser } from "../../actions/userActions";

const SignOut = (props) => {
  const dispatch = useDispatch();
  const { handleSignout } = useContext(firebaseAuth);


  return (
    <button className="px-4 py-2 text-sm text-green-90 hover:bg-sage-10 rounded flex border-solid border border-sage-20" onClick={handleSignout}>
      Sign out
    </button>
  );
};

const AdminNav = (props) => {
  return (
    <div className="admin-nav">
      <div className="flex w-full px-4 items-center justify-end">
        {props.children}
        <SignOut />
      </div>
    </div>
  );
};

export default AdminNav;
