import React, { useContext, useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AdminSightings from "./components/admin/AdminSightings";
import SingleSighting from "./components/admin/SingleSighting";
// import AdminSingleSighting from "./components/admin/AdminSingleSighting";

import NewSighting from "./components/admin/NewSighting";
import SignIn from "./components/admin/SignIn";
import SightingMap from "./components/SightingMap";
import SightingList from "./components/SightingList";
import FourZeroFour from "./pages/404";

import SingleSightingPage from "./components/SingleSightingPage";
import Sidebar from "./components/Sidebar";
import ReportFormWrapper from "./components/ReportForm/ReportFormWrapper";
import ThankYouPage from "./components/ThankYouPage";
import Index from "./components/Index";
import NavBar from "./components/NavBar";
// import Report from "./components/Report";
// import SightingModal from "./components/SightingModal";
import "./App.css";
import "./sass/main.scss";
import firebase from 'firebase'
import { firebaseAuth } from "./AuthProvider";
import { useSelector, useDispatch } from "react-redux";


import { library } from "@fortawesome/fontawesome-svg-core";
// import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faCheckSquare,
  faCheck,
  faCoffee,
  faExpandAlt,
  faArrowLeft,
  faArrowRight,
  faCalendarAlt,
  faExclamationCircle,
  faMapMarkerAlt,
  faMapMarkedAlt,
  faBars,
  faCaretDown,
  faUser,
  faInfoCircle,
  faQuestionCircle,
  faTimesCircle,
  faLink,
  faList,
  faSearch,
  faTimes,
  faFilter,
  faChevronDown,
  faTree,
  faHome,
  faTrash,
  faSort,
  faImages,
  faSatellite,
} from "@fortawesome/free-solid-svg-icons";
// import UserProfile from "./components/ReportForm/UserProfile";
import { updateAuthedUser, resetAuthedUser } from "./actions/userActions";
// import ReportForm from "./components/ReportForm";

library.add(faCheckSquare, faArrowRight, faCaretDown, faTrash, faList, faUser, faMapMarkedAlt, faSearch, faInfoCircle, faTimes, faMapMarkerAlt, faImages, faCoffee, faBars, faExclamationCircle, faQuestionCircle, faFilter, faChevronDown, faExpandAlt, faTree, faHome, faArrowLeft, faTimesCircle, faCalendarAlt, faLink, faCheck, faSatellite, faSort);
const ADMINS = [ 'josephcoombes@me.com','sasharink@me.com', 'iris@rabbitats.org', 'sorelle@rabbitats.org']
function App() {
  const { token } = useContext(firebaseAuth);

  const dispatch = useDispatch();

  const [isAdmin,setIsAdmin] = useState(false)

  const localToken = localStorage.getItem("ar_firebase_token");
  const mobileNavIsOpen = useSelector(
    (state) => state.appState.mobileNavIsOpen
  );



  // console.log(firebase.auth())
  useEffect(() => {
  firebase.auth().onAuthStateChanged((user) => {
    console.log("AUTHSTATECHANGED")
    console.log({user})
    if (user && !user.isAnonymous) {
      // console.log('user is not anonymous');
      dispatch(updateAuthedUser(JSON.parse(localStorage.getItem("userData"))));
      // console.log({user})
      if(ADMINS.includes(user?.email)){
        console.log("IS ADMIN")
        setIsAdmin(true)
      }
    } else {
      setIsAdmin(false)
      firebase.auth().signInAnonymously()
    }

  });
}, []);

  useEffect(() => {
    // firebase.auth().onAuthStateChanged((user) => {
    //   console.log('USEEFFECT')
    //   if (user && !user.isAnonymous) {
    //     console.log('user is not anonymous');
    //     dispatch(updateAuthedUser(JSON.parse(localStorage.getItem("userData"))));
    //   }
    // })
    // const userData = localStorage.getItem("userData");
    // dispatch(updateAuthedUser(JSON.parse(userData)));
  }, []);





  return (
    <div className={`top-level-app-wrapper ${mobileNavIsOpen ? 'mobileNavIsOpen' : ''} ${localToken ? 'signed-in':'not-signed-in'}`}>
      <NavBar isAuthed={localToken ? true : false}  />
      <Sidebar />
      {/* switch allows switching which components render.  */}
      <main className='app-main'>

        <Switch>
          {/* route allows you to render by url path */}
          {/* <SightingMap /> */}
          <Route exact path="/"
            component={SightingMap}
            layout={'full'} />

          <Route
            exact
            path="/"
            render={() => <ReportFormWrapper formStepRoute='location' isAuthed={localToken ? true : false} />}
          />

          
          <Route exact path="/"
            component={SightingMap}
            layout={'full'}
            render={(props) =>
           <SightingMap />} />

          {/* <Route exact path="/sighting-map"
            layout={'full'} 
            render={(props) =>
                 <SightingMap />
              }
            /> */}

          {/* <Route
            exact path="/sighting-map"
            render={(rProps) =>
              localToken === null ? <SignIn /> : <SightingMap />
            }
          />
             <Route exact path="/sighting-list"
            layout={'full'} 
            render={(rProps) =>
              localToken === null ? <SignIn /> : <SightingList />
            }
            /> */}

          <Route
            path="/sighting/:id"
            render={(props) => <SingleSightingPage {...props}  />}
            // render={(props) => (
            //   localToken === null ? <SignIn /> : <SingleSightingPage {...props} />
            // )}
          />

          <Route exact path="/sighting-map"
            component={SightingMap} /> 
        <Route exact path="/sighting-list"
            component={SightingList} /> 

          <Route
            exact
            path="/report"
            render={() => (<Redirect to="/report/location" />)} />

          <Route
            path="/report/location"
            render={() => <ReportFormWrapper formStepRoute='location' isAuthed={localToken ? true : false} />}
          />
          <Route
            path="/report/details"
            render={() => <ReportFormWrapper formStepRoute='details' isAuthed={localToken ? true : false} />}
          />
          <Route
            path="/report/contact"
            render={() => <ReportFormWrapper formStepRoute='contact' isAuthed={localToken ? true : false} />}
          />

       
          <Route
            path="/thank-you"
            exact
            component={ThankYouPage}
          />

          <Route
            exact
            path="/admin"
            render={(rProps) =>
              isAdmin ? <AdminSightings /> : <SignIn admin={true} /> 
            }
          />
          
          <Route
            exact
            path="/admin/new"
            render={(props) =>
              isAdmin ? <NewSighting {...props} /> : <SignIn admin={true} /> 
            }
          />
          <Route
            path="/admin/sighting/:id"
            render={(props) =>
              isAdmin ? <SingleSighting {...props} /> : <SignIn admin={true} /> 
            }
          />
          {/* <Route
            path="/admin/profile"
            render={(props) =>
              localToken === null ? <SignIn /> : <UserProfile />
            }
          /> */}
          <Route path='' component={FourZeroFour} />
          <Route path='*'><Redirect to="/" /></Route>
        </Switch>
      </main>

    </div>
  );
}

export default App;
