import * as actions from "../actions/appStateActions";

export const initialState = {
  formStep: "location",
  loading: false,
  hasErrors: false,
  mapLoaded: false,
  reportMapLoaded: false,
  showMarkersLoadingSpinner: true,
  error: false,
  errorText: "",
  firebaseStatus: "",
  reportPanelOpen: false,
  locationPageValid: false,
  detailsPageValid: false,
  contactPageValid: false,
  showValidationErrors: false,
  filterDate: "all-time",
  filterSpecies: "all",
  filterCount: 1000,
  filterStatus: 'all',
  filterFlags: [],
  algoliaSearchOpen: false,
  modalVisible: false,
  mapSearchOpen: false,
  mobileNavIsOpen: false,
  mapTab: 'map',
  singleSightingModalIsOpen: false,
  filterDateExpression: ['>=', ['get', 'sightingDate'], 0],
  filterStatusExpression: ['!=', ['string', ['get', 'status']], 'placeholder'],
  filterCountExpression: ['>=', ['get', 'count'], 0],
  filterToggleState: {
    statusFilterIsOpen: false,
    dateFilterIsOpen: false,
    countFilterIsOpen: false,
    flagFilterIsOpen: false
  }
};

export default function appStateReducer(state = initialState, action) {
  switch (action.type) {
    case actions.UPDATE_MAP_LOADED:
      return { ...state, mapLoaded: action.payload };
    case actions.UPDATE_REPORT_MAP_LOADED:
      return { ...state, reportMapLoaded: action.payload };
    case actions.UPDATE_FORM_STEP:
      return { ...state, formStep: action.payload };
    case actions.UPDATE_STATUS_FILTERS:
      return { ...state, filterStatus: action.payload };
    case actions.UPDATE_SINGLE_SIGHTING_MODAL_IS_OPEN:
      return { ...state, singleSightingModalIsOpen: action.payload };
    case actions.REGISTER_RESULT_RETURNED:
      return { ...state, resultReturned: action.payload };
    case actions.SHOW_ERROR:
      return { ...state, error: action.payload };
    case actions.CLOSE_ALL_FILTER_TOGGLES:
      return {
        ...state, filterToggleState: {
          statusFilterIsOpen: false,
          dateFilterIsOpen: false,
          countFilterIsOpen: false
        }
      };
    case actions.RESET_FILTERS:
      return {
        ...state,
        filterDate: "all-time",
        filterSpecies: "all",
        filterCount: 1000,
        filterStatus: 'all',
      };
    case actions.UPDATE_FILTER_TOGGLE_STATE:
      return { ...state, filterToggleState: action.payload };
    case actions.UPDATE_FILTERS:
      let key1 = Object.keys(action.payload)[0];
      let val1 = action.payload[key1];
      return { ...state, [key1]: val1 };
    case actions.UPDATE_SELECTED_SIGHTING:
      return { ...state, selectedSighting: action.payload };

    case actions.UPDATE_ERROR_TEXT:
      return { ...state, errorText: action.payload };
    case actions.UPDATE_APP_STATE:
      let key = Object.keys(action.payload)[0];
      let val = action.payload[key];
      return { ...state, [key]: val };
    default:
      return state;
  }
}
