import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { updateAppState, updateSingleSightingModalIsOpen } from "../actions/appStateActions";
import { Link } from "react-router-dom";
import { formatDate } from "../helpers/formatDate";
import Moment from "react-moment";
import mapboxgl from "mapbox-gl";
import { db } from "../firebase";

import { Helmet } from "react-helmet";

import SingleSightingMap from './ModalHeaderMap.js';

import DisplaySightingTags from './DisplaySightingTags';
import DisplayStatusTag from './DisplayStatusTag';


import { getCity } from '../helpers/getCity'

// import "../sass/_sightingModal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SingleSightingPage = (props) => {
  // const dispatch = useDispatch();


  // const history = useHistory();
  const params = useParams();

  const location = useLocation();
  const history = useHistory();

  const user = useSelector((state) => state.user);
  console.log({ user })

  console.log({ location })
  console.log({ history })
  // console.log(this.props.location.state.from)
  // let sightings = useSelector((state) => state.sightings.allSightings);
  // let sighting = useSelector((state) => state.appState.selectedSighting);

  const [singleSighting, setSingleSighting] = useState();

  const [showSightingImages, setShowSightingImages] = useState(false);



  useEffect(() => {
    console.log('USE EFFECT')


    db.collection('sightings').doc(params.id).get()
      .then((docRef) => {
        console.log(docRef.data())
        setSingleSighting(docRef.data())
        console.log({ singleSighting })
      })
      .catch((error) => { })
  }, [params.id])



  const handleCopyClick = () => {
    navigator.clipboard.writeText(window.location.href);
    console.log(`copied: ${window.location.href}`);
  };

  // Components
  const Loading = () => {
    return (
      <div className="sighting-modal__loading">
        <p>LOADING</p>
      </div>
    );
  };



  const SightingImages = () => {
    return (
      <div className='relative'>
        <div className='flex sticky h-16 md:top-0 border-b top-16 left-0 px-4 items-center border-b border-solid border-gray-10 bg-white w-full '>
          <button className='text-base text-green-90 border-r border-solid border-sage-10 absolute left-0 top-0 w-16 h-16 flex items-center justify-center px-4' onClick={(e) => setShowSightingImages(false)}><FontAwesomeIcon icon="arrow-left" /> </button>

          <div className='flex flex-col ml-auto mr-auto'>
            <span className='text-green-90 text-base flex ml-auto mr-auto leading-none mb-1'>{singleSighting.properties.title}</span>
            <span className='text-gray-70 text-sm flex ml-auto mr-auto leading-none'>{getCity(singleSighting)}</span>
          </div>
        </div>
        <div className='flex p-4 pb-0 flex-col w-full'>
          {singleSighting.properties.images.map((image) => {
            return <img src={image} className='w-full mb-4' />
          })}

        </div></div>
    )
  }



  const HeaderImages = () => {

    if (singleSighting.properties.images && singleSighting.properties.images.length > 0) {
      return (
        <div className='w-1/2 h-full'>
          <img className='w-full h-full object-cover' src={singleSighting.properties.images[0]} />
          <button className='absolute top-0 right-0 m-4  button button--sm' onClick={(e) => setShowSightingImages(true)}><FontAwesomeIcon icon="images" /> See all</button>
        </div>)
    }
    else {
      return null;
    }

  }

  const Flags = () => {
    let today = Math.floor(Date.now() / 1000);
    const week = 604000;
    let sightingDate = singleSighting.properties.sightingDate.seconds;
    const generateClass = (flag) => {
      let formatted = flag.toLowerCase();
      formatted = formatted.replace(/\s+/g, "-");
      return formatted;
    };
    return (
      <div className="flags">
        {today - week < sightingDate ? (
          <div className="flag new">New</div>
        ) : null}
        {singleSighting.properties.flags.map((flag, index) => (
          <div key={index} className={`flag h-8 flex items-center  capitalize ${generateClass(flag)}`}>
            {flag}
          </div>
        ))}
      </div>
    );
  };

  const ShareSection = () => {

    return (
      <div className='flex flex-col'>
        <p className='text-sm text-green-90 mb-4'>Be an advocate for abandoned rabbits</p>
        <a href='https://donate-can.keela.co/abandoned-rabbits' className='button mb-4 bg-specialGreen'>Donate</a>

        {/* <button
          className="button bg-cream hover:bg-white mb-4"
          onClick={() => {
            handleCopyClick();
          }}
        >
          <FontAwesomeIcon icon="link" />
          <span className='ml-2'
          >Copy link</span>
        </button> */}
        {/* <button className='button mb-4 bg-cream hover:bg-white'>Tweet</button> */}
        {/* <button className='button mb-4 bg-cream hover:bg-white'>Share on Facebook</button> */}
      </div>
    )
  }
  const DetailItem = (props) => {
    if (props.children && props.children !== "") {
      return (
        <h4 className='text-base mb-3'><span className='font-medium text-sm text-gray-green-70'>{props.title}: </span>
          {props.break && <br />}
          <span className=''>{props.children}</span>
        </h4>
      )
    }
    else {
      return null
    }
  }

  const StatusTag = (props) => {
    return (
      <div className={`capitalize mt-2 h-8 rounded-full px-3 inline-flex items-center status-tag status-tag--${props.status}`}>
        {props.status}
      </div>)
  }

  const handleBackButtonClick = () => {
    history.goBack()
  }


  const SightingDetails = () => {
    return (
      <>
        <Helmet>
          <title>{singleSighting.properties.title} | AbandonedRabbits.com</title>
          {singleSighting.properties.images.length > 1 &&
            <meta property="og:image" content={singleSighting.properties.images[0]} />

          }
          {/* <meta property="og:url" content={ogUrl} />
    <meta property="og:type" content={props.type} />
    <meta property="og:title" content={props.title} />
    <meta property="og:description" content={props.desc} /> */}
        </Helmet>
        <header className={`single-sighting-page-header relative flex h-96 bg-gray-20 w-full ${singleSighting.properties.images && singleSighting.properties.images.length >= 1 ? 'hasImage' : ''}`}>
          {location.state && location.state.from &&
            <button onClick={handleBackButtonClick}
              className="button button--sm absolute top-0 left-0 z-10 m-4 "
              to="/sightings"
            >
              <FontAwesomeIcon icon="arrow-left" size="xs" />
              <span className="inline-block ml-2">Back</span>
            </button>
          }

          <SingleSightingMap coordinates={singleSighting.geometry.coordinates} />
          <HeaderImages />
        </header>
        <section className="p-4 md:px-8 flex flex-row flex-wrap">
          <main className='w-full lg:w-3/4 mb-8 pt-4 mx-auto'>

            <div className='flex'>
              <h1 className="text-4xl text-green-90 serif font-semibold mb-4">
                {singleSighting.properties.title}
              </h1>
              {user.authedUser.email && <a className=' px-4 h-10 flex items-center ml-auto bg-white font-semibold text-sm rounded text-gray-green-90 shadow' href={`/admin${location.pathname}`}>Edit</a>}

            </div>

            <div className='flex mb-8'>
              <DisplayStatusTag status={singleSighting.properties.status} />
              <DisplaySightingTags
                count={singleSighting.properties.count}
                flags={singleSighting.properties.flags}
              />
            </div>

            <div className="flex flex-col text-gray-green-80 mt-4">

              <div className='row'>
                <div className='mb-8 w-full lg:w-1/2 col' >
                  <DetailItem title='Sighting date' break={true}>
                    <Moment format="LL">{singleSighting.properties.sightingDate}</Moment>
                  </DetailItem>
                </div>

                <div className='lg:w-1/2 w-full col capitalize'>

                  <DetailItem title='Species' break={true}>
                    {singleSighting.properties.species}
                  </DetailItem>
                </div>

              </div>
              <div className='row'>
                <div className='mb-8 w-full lg:w-1/2  col' >
                  <DetailItem title='Location' break={true}>
                    {singleSighting.location.place_name}
                  </DetailItem>
                  <DetailItem title='Location note' break={true}>
                    {singleSighting.properties.locationNote}
                  </DetailItem>
                </div>
                <div className='mb-8 w-full lg:w-1/2  col' >
                  <DetailItem title='Appearance' break={true}>
                    {singleSighting.properties.appearance}
                  </DetailItem>
                  <DetailItem title='Notes' break={true}>
                    {singleSighting.properties.notes}
                  </DetailItem>
                </div>
              </div>

            </div>



          </main>
          {/* <aside className='w-full lg:w-1/4'>
            <ShareSection />
          </aside> */}

        </section>
      </>
    );
  };


  if (singleSighting) {

    if (showSightingImages) {
      return <SightingImages />
    }
    else {
      return (
        <div className='single-sighting-page h-full'><SightingDetails /></div>
      )
    }

  }
  else {
    return <Loading />
  }



};

export default SingleSightingPage;
