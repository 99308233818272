import React from "react";
import parse from 'html-react-parser';

export default function PageHeader({ title, container, copy }) {

	return (
		<header className='pt-16 pb-4 flex items-end   border-b border-solid border-sage-10'>
			<div className={`flex flex-col w-full ${container === true ? 'container-with-sidebar' : 'px-8'}`}>
				<div className="lg:w-1/2 md:w-3/4">
				<h1 className='text-gray-green-80 text-3xl font-bold'>{title}</h1>
				{copy && <p className=" mt-4">{parse(copy)}</p>}
				</div>
			</div>
		</header>
	)

}