import { combineReducers } from "redux";

import sightingsReducer from "./sightingsReducer";
import newSightingReducer from "./newSightingReducer";
import appStateReducer from "./appStateReducer";
import userReducer from "./userReducer";
import adminReducer from "./adminReducer";

const rootReducer = combineReducers({
  sightings: sightingsReducer,
  newSighting: newSightingReducer,
  appState: appStateReducer,
  user: userReducer,
  admin: adminReducer, // Add more here
});

export default rootReducer;
