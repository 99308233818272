const doFilterCount = (input, filterBy) => {
	let output = input;
	filterBy = parseInt(filterBy, 10);
	if (filterBy === 1000) {
		// Any
		output = input.filter(function (sighting) {
			return sighting.properties.count < filterBy;
		});
	} else if (filterBy > 10) {
		// Colonies
		output = input.filter(function (sighting) {
			return sighting.properties.count > 10;
		});
	} else if (filterBy > 1 && filterBy <= 10) {
		// Small groups
		output = input.filter(function (sighting) {
			return (
				sighting.properties.count > 1 && sighting.properties.count <= 10
			);
		});
	} else if (filterBy === 1) {
		// Individuals
		output = input.filter(function (sighting) {
			return sighting.properties.count === 1;
		});
	}
	return output;
};

module.exports = { doFilterCount };