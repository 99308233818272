import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateAppState, updateFilters, updateFilterToggleState, resetFilters, closeAllFilterToggles, updateStatusFilters } from "../actions/appStateActions";
import { updateSightings, updateSightingsInViewport, clearAllFilters, } from "../actions/sightingsActions";
import mapboxgl, { Marker } from "mapbox-gl";
import MapboxGeocoder from "mapbox-gl-geocoder";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CurrentFilterTags from './CurrentFilterTags'

import { addGeoJsonWrapper } from "../helpers/addGeoJsonWrapper";

import { labelize, capitalize } from "../helpers/labelize";
import { createCountLabel } from "../helpers/createCountLabel";

import { doCombineFilters } from "../helpers/doCombineFilters";

import { AnimatePresence, motion } from "framer-motion";
import { statusOptions, dateOptions } from "../helpers/filterOptions";

// import { handleInputChange } from "react-select/src/utils";

const MapHeader = (props) => {
  const dispatch = useDispatch();

  const map = props.map;
  const mapSearchRef = React.useRef(null);

  const appState = useSelector((state) => state.appState);
  const originalUnfilteredSightings = useSelector(
    (state) => state.sightings.allSightings
  );

  const filterToggleState = useSelector((state) => state.appState.filterToggleState);


  const sightings = useSelector((state) => state.sightings.allSightings);
  const sightingsInViewport = useSelector((state) => state.sightings.sightingsInViewport);
  const unfilteredSightingsInViewport = useSelector((state) => state.sightings.unfilteredSightingsInViewport);
  const filteredSightings = useSelector(
    (state) => state.sightings.filteredSightings
  );

  const renderedFeatureCount = useSelector((state) => state.appState.renderedFeatureCount);

  const sortedBy = useSelector((state) => state.appState.sortedBy);

  const sortingOptions = [
    { value: undefined, label: "Sort sightings" },
    { value: "asc", label: "Newest to oldest" },
    { value: "desc", label: "Oldest to newest" },
  ];

  const [isSortedBy, setIsSortedBy] = useState();

  const [mobileFilterMenuIsOpen, setmobileFilterMenuIsOpen] = useState(false);

  const initialFilterToggleState = {
    status: false,
    count: false,
    date: false
  }
  const [filterToggles, setFilterToggles] = useState(initialFilterToggleState);

  // let geocoder = new MapboxGeocoder({
  //   accessToken: mapboxgl.accessToken,
  //   mapboxgl: mapboxgl,
  //   marker: false, // Do not use the default marker style
  //   placeholder: "Enter an address or landmark",
  // });

  // const initZoomToGeoCoder = (proximity) => {
  //   console.log('initZoomToGeocoder')

  //   let zoomToGeocoder = new MapboxGeocoder({
  //     accessToken: mapboxgl.accessToken,
  //     mapboxgl: mapboxgl,
  //     marker: false, // Do not use the default marker style
  //     placeholder: "Enter an address or landmark",
  //     proximity: proximity,
  //   });

  //   mapSearchRef.current.appendChild(zoomToGeocoder.onAdd(map));

  // };
  useEffect(() => {
    dispatch(resetFilters());

  }, [])

  if (map) {
    map.on('click', () => {
      appState.mobileFilterMenuIsOpen && dispatch(updateAppState({ mobileFilterMenuIsOpen: false }))
    })
  }

  const FiltersLoading = () => {
    return (
      <div className='h-full w-full bg-gray-20 ml-auto flex items-center loading-skeleton'>Loading</div>
    )
  }


  const DateFilterMenuItems = () => {
    return (
      <>
        <DateFilterMenuItem value="all-time" label='All time' />
        <DateFilterMenuItem value="last-year" label='Last year' />
        <DateFilterMenuItem value="last-90-days" label='Last 90 days' />
        <DateFilterMenuItem value="last-30-days" label='Last 30 days' />
        <DateFilterMenuItem value="last-7-days" label='Last 7 days' />
      </>
    )
  }

  const FlagFilterMenuItems = () => {
    return (
      <>
        <FlagFilterMenuItem value="rhdv-suspected" label='RHDV Suspected' />
        <FlagFilterMenuItem value="rhdv-confirmed" label='RHDV Confirmed' />
        <FlagFilterMenuItem value="Babies present" label='Babies present' />
        <FlagFilterMenuItem value="injured" label='Injured' />
      </>
    )
  }


  const FlagFilterToggle = () => {
    function toggleFlagFilter() {
      // setFilterToggles({status: !filterToggles.status, date: false, count: false})
      dispatch(updateFilterToggleState({
        flagFilterIsOpen: !filterToggleState.flagFilterIsOpen,
        statusFilterIsOpen: false,
        dateFilterIsOpen: false,
        countFilterIsOpen: false
      }))
    }

    const FlagFilterToggleLabel = () => {

      if (appState.filterFlags.length > 0) {
        return (
          <p>{labelize(appState.filterFlags[0])} {appState.filterFlags.length > 1 && '+ more'}</p>
        )
      } else {
        return (<p>None</p>)
      }

    }

    return (
      <div className='relative filter-toggle-wrapper'>
        <button onClick={toggleFlagFilter} className={`filter-toggle ${filterToggleState.statusFilterIsOpen && 'isOpen'}`}>
          <div className='flex flex-col w-full'>
            <span className='filter-toggle__name'>Flags</span>
            <span className="filter-toggle__value">
              <FlagFilterToggleLabel />
            </span>
          </div>
          <FontAwesomeIcon icon='chevron-down' className='ml-auto chevron' />
        </button>

        <div className={`filter-menu ${filterToggleState.flagFilterIsOpen ? 'open' : ''} `}>
          <FlagFilterMenuItems />
        </div>

      </div>
    )
  }



  const StatusFilterToggle = () => {
    function toggleStatusFilter() {
      // setFilterToggles({status: !filterToggles.status, date: false, count: false})
      dispatch(updateFilterToggleState({
        statusFilterIsOpen: !filterToggleState.statusFilterIsOpen,
        dateFilterIsOpen: false,
        countFilterIsOpen: false
      }))
    }

    return (
      <div className='relative filter-toggle-wrapper'>
        <button onClick={toggleStatusFilter} className={`filter-toggle ${filterToggleState.statusFilterIsOpen && 'isOpen'}`}>
          <div className='flex flex-col w-full'>
            <span className='filter-toggle__name'>Status</span>
            <span className="filter-toggle__value">
              {capitalize(appState.filterStatus)}
            </span>
          </div>
          <FontAwesomeIcon icon='chevron-down' className='ml-auto chevron' />
        </button>
        <div className={`filter-menu ${filterToggleState.statusFilterIsOpen ? 'open' : ''} `}>
          {statusOptions.map((option) => {
            return <StatusFilterMenuItem value={option.value} label={option.label} />
          })}
        </div>
      </div>
    )
  }

  const StatusFilterMenuItem = (props) => {
    let isSelected = appState.filterStatus === props.value

    function handleStatusFilterChange(name, value) {
      dispatch(updateAppState({ filterStatus: value }))
      handleFilter(name, value);
      switch (value) {
        case "urgent":
          setClusterColor('#ef555b', '#edd8d7')
          break;
        case "unknown":
          setClusterColor('#999999', '#eeeeee')
          break;
        case "rescued":
          setClusterColor('#48c08b', '#b8e5d5')
          break;
        case "deceased":
          setClusterColor('#000000', '#999999')
          break;
        case "general":
          setClusterColor('#f5c75c', "#FFF5DE")
          break;
        default:
          setClusterColor('#3d9b83', 'rgba(68,184,132,0.5)')
          break;
      }
    }

    return (
      <div className='flex filter-menu-item-wrapper'>
        <label onClick={(e) => e.stopPropagation()} htmlFor={`status${props.value}`} className={`${isSelected ? 'selected' : ''} filter-menu-item filter-menu-item--status items-center flex`}>
          <div className='filter-radio__outer-circle'>
            {isSelected && <span className='filter-radio__inner-circle'></span>}
          </div>
          <span className='filter-menu-item__text'>{props.label}</span>
          <span className={`status-marker shadow-sm bg--${props.value}`}></span>
        </label>
        <input id={`status${props.value}`} checked={isSelected} onChange={(e) => handleStatusFilterChange('filterStatus', props.value)} name='filterStatus' value={props.value} type='radio' />
      </div>
    )
  }

  const StatusFilterMenuItems = () => {
    return (
      <>
        <StatusFilterMenuItem value='all' label='All' />
        <StatusFilterMenuItem value='general' label='General' />
        <StatusFilterMenuItem value='urgent' label='Urgent' />
        <StatusFilterMenuItem value='rescued' label='Rescued' />
        <StatusFilterMenuItem value='deceased' label='Deceased' />
      </>
    )
  }


  function handleDateFilterChange(name, value) {
    console.log('handlefilterdate')
    // console.log({ e })
    dispatch(updateAppState({ filterDate: value }))
    handleFilter(name, value);
  }

  const addOrRemoveFlags = (value) => {
    let selectedFlags = appState.filterFlags;

    if (appState.filterFlags.includes(value)) {
      selectedFlags = appState.filterFlags.filter((item) => {
        return item !== value
      })
      dispatch(updateAppState({ filterFlags: selectedFlags }))
    } else {
      selectedFlags.push(value)
      dispatch(updateAppState({ filterFlags: selectedFlags }))
    }
    return (selectedFlags)
  }

  function handleFlagFilterChange(name, value) {
    handleFilter(name, addOrRemoveFlags(value));
  }


  const DateFilterMenuItem = (props) => {
    // console.log(appState.filterDate)
    let isSelected = appState.filterDate === props.value;
    return (
      <div className='flex filter-menu-item-wrapper'>
        <label onClick={(e) => e.stopPropagation()} htmlFor={`date-filter-${props.value}`} className={`${isSelected ? 'selected' : ''} filter-menu-item  flex `}>
          <div className='filter-radio__outer-circle'>
            {isSelected && <span className='filter-radio__inner-circle'></span>}
          </div>
          {/* {isSelected && <div className='filter-menu-item__check-wrapper'><FontAwesomeIcon icon='check' className='filter-menu-item__check' /></div>} */}

          <span className='filter-menu-item__text'>{props.label} </span>
        </label>
        <input id={`date-filter-${props.value}`} checked={isSelected} onChange={(e) => handleDateFilterChange('filterDate', props.value)} name='filterDate' value={props.value} type='radio' />
      </div>)
  }

  const FlagFilterMenuItem = (props) => {
    // console.log(appState.filterDate)
    let isSelected = appState.filterFlags.includes(props.value);
    return (
      <div className='flex filter-menu-item-wrapper'>
        <label onClick={(e) => e.stopPropagation()} htmlFor={`flag-filter-${props.value}`} className={`${isSelected ? 'selected' : ''} filter-menu-item  flex `}>
          {/* <div className='filter-radio__outer-circle'>
        {isSelected && <span className='filter-radio__inner-circle'></span>}
      </div> */}
          <div className='filter-menu-item__check-wrapper'>
            {isSelected && <FontAwesomeIcon icon='check' className='filter-menu-item__check' />}
          </div>

          <span className='filter-menu-item__text'>{props.label} </span>
        </label>
        <input id={`flag-filter-${props.value}`} checked={isSelected} onChange={(e) => handleFlagFilterChange('filterFlags', props.value)} name='filterFlags' value={props.value} type='checkbox' />
      </div>)
  }


  const DateFilterToggle = () => {
    function handleDateToggle() {
      dispatch(updateFilterToggleState({
        statusFilterIsOpen: false,
        dateFilterIsOpen: !filterToggleState.dateFilterIsOpen,
        countFilterIsOpen: false
      }))
    };


    return (
      <div className='relative filter-toggle-wrapper'>
        <button onClick={handleDateToggle} className={`filter-toggle ${filterToggleState.dateFilterIsOpen && 'isOpen'}`}>
          <div className='flex flex-col w-full'>
            <span className='filter-toggle__name'>Date range</span>
            <span className='filter-toggle__value'>{labelize(appState.filterDate)}</span>
          </div>
          <FontAwesomeIcon icon='chevron-down' className='ml-auto chevron' />
        </button>
        <div className={`filter-menu ${filterToggleState.dateFilterIsOpen ? 'open' : ''} `}>
          <DateFilterMenuItems />
        </div>
      </div>
    )
  }


  function handleCountFilterChange(name, value) {
    dispatch(updateAppState({ filterCount: value }))
    handleFilter(name, value);
  }

  const CountFilterMenuItem = (props) => {

    let isSelected = parseInt(appState.filterCount) === props.value;
    return (
      <div className='flex filter-menu-item-wrapper'>
        <label onClick={(e) => e.stopPropagation()} htmlFor={`date-filter-${props.value}`} className={`${isSelected ? 'selected' : ''} filter-menu-item filter-menu-item--count  flex `}>
          <div className='filter-radio__outer-circle'>
            {isSelected && <span className='filter-radio__inner-circle'></span>}
          </div>
          <span className='filter-menu-item__text'>{props.label} </span>
          {props.additionalLabel && <span className='filter-menu-item__additional-text ml-1 font-medium'>({props.additionalLabel})</span>}
        </label>
        <input id={`date-filter-${props.value}`} checked={isSelected} onChange={(e) => handleCountFilterChange('filterCount', props.value)} name='filterCount' value={props.value} type='radio' />
      </div>)
  }

  const CountFilterMenuItems = () => {
    return (
      <>
        <CountFilterMenuItem value={1000} label='Any' />
        <CountFilterMenuItem value={1} label='Individuals' />
        <CountFilterMenuItem value={10} label='Small Groups' additionalLabel='2-10' />
        <CountFilterMenuItem value={11} label='Colonies' additionalLabel='11+' />
      </>
    )
  }




  const CountFilterToggle = () => {
    function handleCountMenuToggle() {
      dispatch(updateFilterToggleState({
        countFilterIsOpen: !filterToggleState.countFilterIsOpen,
        dateFilterIsOpen: false,
        statusFilterIsOpen: false
      }))
    };

    let label = createCountLabel(appState.filterCount)

    return (
      <div className='relative filter-toggle-wrapper'>
        <button onClick={handleCountMenuToggle} className={`filter-toggle ${filterToggleState.countFilterIsOpen && 'isOpen'}`}>
          <div className='flex flex-col w-full'>
            <span className='filter-toggle__name'>Number of rabbits</span>
            <span className='filter-toggle__value'>{label}</span>
          </div>
          <FontAwesomeIcon icon='chevron-down' className='ml-auto chevron' />
        </button>
        <div
          className={`filter-menu filter-menu--count ${filterToggleState.countFilterIsOpen ? 'open' : ''} `}
        >
          <CountFilterMenuItems />
        </div>
      </div>
    )
  }


  const MobileFilterMenu = () => {
    return (
      <div
        initial={{ right: '-100%' }}
        animate={{ right: 0 }}
        // exit={{ right: '-50%' }}
        className='mobile-filter-menu'>
        <div className='flex sticky top-0 p-4 z-10 border-b border-solid border-sage-10 items-center justify-center bg-white '>
          <RenderedFeatureCount />
          <button className='mobile-filter-menu__close' onClick={() => dispatch(updateAppState({ mobileFilterMenuIsOpen: !appState.mobileFilterMenuIsOpen }))}>
            <FontAwesomeIcon icon='times' />
          </button>
        </div>
        <div className='py-2 px-3'>
          <label className='mobile-filter-label'>Status</label>
          <div>
            {statusOptions.map((option) => {
              return <StatusFilterMenuItem value={option.value} label={option.label} />
            })}
          </div>
        </div>
        <div className='py-2 px-3'>
          <label className='mobile-filter-label'>Date range</label>
          <DateFilterMenuItems />
        </div>

        <div className='py-2 px-3'>
          <label className='mobile-filter-label'>Number of rabbits</label>
          <CountFilterMenuItems />
        </div>
        <div className='py-2 px-3'>
          <label className='mobile-filter-label'>Flags</label>
          <FlagFilterMenuItems />
        </div>
        <ClearFilterButton />
      </div>
    )
  }



  const LargeFilters = () => {
    return (<div className={`large-filter-menu `}>

      <StatusFilterToggle />
      <DateFilterToggle />
      <CountFilterToggle />
      <FlagFilterToggle />
      <ClearFilterButton />

    </div>

    )
  }

  const setClusterColor = (fill, stroke) => {
    map.setPaintProperty('clusters', 'circle-color', fill);
    map.setPaintProperty('clusters', 'circle-stroke-color', stroke);
  }



  const handleFilter = (name, value) => {

    dispatch(
      updateAppState({
        filtersActivated: true,
      })
    );

    dispatch(updateFilters({
      [name]: value
    }))

    let combinedFilteredSightings = doCombineFilters(name, value, originalUnfilteredSightings, appState);

    dispatch(updateSightings(combinedFilteredSightings));


    map
      .getSource("sightingsSource")
      .setData(addGeoJsonWrapper(combinedFilteredSightings));
    map
      .getSource("hiddenSightingsSource")
      .setData(addGeoJsonWrapper(combinedFilteredSightings));


    setTimeout(() => {
      let renderedFeatures = map.queryRenderedFeatures({ layers: ['hiddenSightingsLayer'] })
      dispatch(updateAppState({ renderedFeatureCount: renderedFeatures.length }))
    }, 250);


  };

  const clearFilters = () => {

    dispatch(
      updateAppState({
        filtersActivated: false,
      })
    );
    dispatch(
      updateAppState({
        filterSpecies: "all",
      })
    );
    dispatch(
      updateAppState({
        filterCount: 1000,
      })
    );
    dispatch(
      updateAppState({
        filterDate: "all-time",
      })
    );
    dispatch(
      updateAppState({
        filterStatus: "all",
      })
    );
    dispatch(
      updateAppState({
        filterFlags: [],
      })
    );
    // setmobileFilterMenuIsOpen(false)
    dispatch(updateAppState({ mobileFilterMenuIsOpen: true }))
    dispatch(updateFilterToggleState({
      statusFilterIsOpen: false,
      dateFilterIsOpen: false,
      countFilterIsOpen: false
    }))
    dispatch(clearAllFilters({}));

    map.setPaintProperty('clusters', 'circle-color', '#3d9b83');
    map.setPaintProperty('clusters', 'circle-stroke-color', 'rgba(68,184,132,0.5)');
    map.getSource("sightingsSource").setData(addGeoJsonWrapper(originalUnfilteredSightings));
    map.getSource("hiddenSightingsSource").setData(addGeoJsonWrapper(originalUnfilteredSightings));

  };

  const ClearFilterButton = () => {
    if (appState.filterDate !== 'all-time' || appState.filterStatus !== 'all' || appState.filterCount !== 1000 || appState.filterFlags.length > 0) {
      return (<button className='clear-filter-button'
        onClick={clearFilters}>
        <FontAwesomeIcon icon='times-circle' className='mr-2 text-green-60' /><span>Clear filters</span></button>)
    } else {
      return null
    }
  };

  const speciesOptions = [
    { value: "all", label: "All" },
    { value: "wild", label: "Wild" },
    { value: "domestic", label: "Domestic" },
  ];

  function sortAsc(a, b) {
    let sightingA = a.properties.sightingDate.seconds;
    let sightingB = b.properties.sightingDate.seconds;
    let comparison = 0;
    if (sightingA > sightingB) {
      comparison = 1;
    } else if (sightingA < sightingB) {
      comparison = -1;
    }
    return comparison * -1;
  }

  function sortDesc(a, b) {
    let sightingA = a.properties.sightingDate.seconds;
    let sightingB = b.properties.sightingDate.seconds;
    let comparison = 0;
    if (sightingA > sightingB) {
      comparison = 1;
    } else if (sightingA < sightingB) {
      comparison = -1;
    }
    return comparison;
  }

  const handleSortByChange = (e) => {
    let selectedOption = e.target

    // console.log({selectedOption});
    let sortedSightings;
    if (selectedOption.value === "asc") {
      sortedSightings = sightingsInViewport.sort(sortAsc);
    } else if (selectedOption.value === "desc") {
      sortedSightings = sightingsInViewport.sort(sortDesc);
    } else {
      return;
    }
    setIsSortedBy(selectedOption);
    dispatch(updateAppState({ sortedBy: selectedOption.value }));
    // console.log("sortedBy: ", sortedBy);
    dispatch(updateSightingsInViewport(sortedSightings));
  };


  function handleMapHeaderClick(e) {
    if (e.target.classList.contains('map-header')) {
      dispatch(closeAllFilterToggles())
    }
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  }

  function handleMobileToggleClick(e) {
    e.stopPropagation();
    // setmobileFilterMenuIsOpen(!mobileFilterMenuIsOpen)
    dispatch(updateAppState({ mobileFilterMenuIsOpen: !appState.mobileFilterMenuIsOpen }))
    dispatch(updateFilterToggleState({
      statusFilterIsOpen: true,
      dateFilterIsOpen: false,
      countFilterIsOpen: false
    }))
    appState.mapSearchOpen && dispatch(updateAppState({ mapSearchOpen: false }))

    // console.log('toggle mobile menu')
  }



  const RenderedFeatureCount = () => {

    if (renderedFeatureCount > 0) {
      return (<p className='mobile-filter-results'>{renderedFeatureCount} sightings in this area</p>)
    }
    else {
      return (<p className='mobile-filter-results'>No matching sightings</p>)
    }

  }

  return (
    <>
      {/* {appState.mobileFilterMenuIsOpen && <div className='filter-backdrop'></div>} */}
      <div className='filter-tags'>
        <CurrentFilterTags handleFilter={handleFilter} updateClusters={true} setClusterColor={setClusterColor} addOrRemoveFlags={addOrRemoveFlags} />
      </div>
      {appState.mobileFilterMenuIsOpen && <MobileFilterMenu />}

      {appState.mapLoaded && !appState.mobileFilterMenuIsOpen &&

        <button className='mobile-filter-toggle mobile-filter-button' onClick={() => dispatch(updateAppState({ mobileFilterMenuIsOpen: !appState.mobileFilterMenuIsOpen }))}>

          <FontAwesomeIcon icon='filter' className='h-3 text-green-80' />
          <span className=''>Filters</span>
        </button>}


      <div className={`map-header-wrapper `}>

        <div onClick={(e) => handleMapHeaderClick(e)} className={`map-header ${appState.mobileFilterMenuIsOpen === true && 'mobileFiltersOpen'} `}>
          {props.children}
          {/* <div className='mt-4 w-64 bg-red-30' ref={mapSearchRef} id="geocoder-el" ></div> */}
          {appState.mapLoaded ? <LargeFilters /> : <FiltersLoading />}

        </div>



      </div>

    </>
  );
};

export default MapHeader;
