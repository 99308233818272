import React from "react"
import { useFormContext } from "react-hook-form";

export default function Input({ name, requiredMessage, placeholder, type, defaultValue }) {

	const { register } = useFormContext();

	return <input
		{...register(name, { required: requiredMessage })}
		name={name}
		id={`input-${name}`}
		className='form-field__input '
		type={type ? type : 'text'}
		autocomplete='off'
		defaultValue={defaultValue}
		placeholder={placeholder} />

}