const doFilterFlags = (input, filterBy) => {
	if (filterBy.length < 1) {
		return input
	}
	let filtered = []
	filterBy.forEach((filter) => {
		input.filter((item) => {
			if (item.properties.flags.includes(filter)) {
				filtered.push(item)
			}
		})
	})
	return filtered
}
module.exports = { doFilterFlags };