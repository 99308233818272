import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { updateAppState, updateSingleSightingModalIsOpen, updateFilters } from "../actions/appStateActions";
import { addSightings, updateFilteredSightingListSightings } from "../actions/sightingsActions";

import { Link } from "react-router-dom";
import { doCombineFilters } from "../helpers/doCombineFilters";
import { dateOptions, statusOptions, countOptions } from "../helpers/filterOptions";

import Moment from "react-moment";
import { db } from "../firebase";
import CurrentFilterTags from './CurrentFilterTags'

import DisplaySightingTags from './DisplaySightingTags'
import DisplayStatusTag from './DisplayStatusTag'

import PageHeader from './PageHeader.js';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function SightingList() {

	const SIGHTINGS_PER_PAGE = 25

	const dispatch = useDispatch()

	const allSightings = useSelector((state) => state.sightings.allSightings);
	const { filterStatus, filterDate, filterCount } = useSelector((state) => state.appState);
	const appState = useSelector((state) => state.appState);

	// useEffect(() => {
	// 	// console.log({allSightings})

	// 	const JAN_1_2023 = 1672531200000

	// 	const JAN_1_2022 = 1640995200000

	// 	const JAN_1_2021 = 1609459200000
	console.log({ allSightings })

	const formattedGeoJson = allSightings.map((sighting)=>{
		return {
			type:'Feature',
			geometry: sighting.geometry,
			properties: {...sighting.properties,
				location: sighting.location,
				created: sighting.created,
			}
		}
	})

	console.log({ "type": "FeatureCollection",
	"features": [...formattedGeoJson]})
	

	// 	const sightings2021 = allSightings.filter((sighting)=>{
	// 		return sighting.properties.sightingDate < JAN_1_2022 && sighting.properties.sightingDate > JAN_1_2021
	// 	})
	// 	const sightings2022 = allSightings.filter((sighting)=>{
	// 		return sighting.properties.sightingDate < JAN_1_2023 && sighting.properties.sightingDate > JAN_1_2022
	// 	})

	// 	const sightings2023 = allSightings.filter((sighting)=>{
	// 		return sighting.properties.sightingDate > JAN_1_2023 
	// 	})
	// 	let rabbits2021 = 0
	// 	sightings2021.forEach((sighting)=>{
	// 		rabbits2021 += parseInt(sighting.properties.count)
	// 	})
	// 	let rabbits2022 = 0
	// 	sightings2022.forEach((sighting)=>{
	// 		rabbits2022 += parseInt(sighting.properties.count)
	// 	})
	// 	let rabbits2023 = 0
	// 	sightings2023.forEach((sighting)=>{
	// 		rabbits2023 += parseInt(sighting.properties.count)
	// 	})
		
	// 	console.log(`==============2021============`)
	// 	console.log(`Sightings: ${sightings2021.length} `)
	// 	console.log(`Rabbits ${rabbits2021}`)
	// 	console.log(`==============2022============`)
	// 	console.log(`Sightings: ${sightings2022.length} `)
	// 	console.log(`Rabbits ${rabbits2022}`)
	// 	console.log(`==============2023============`)
	// 	console.log(`Sightings (so far): ${sightings2023.length}`)
	// 	console.log(`Rabbits (so far): ${rabbits2023}`)
	// 	console.log(`==============================`)
	
	// }, [allSightings]);

	const [currentPageSightings, setCurrentPageSightings] = useState([])

	const filteredSightingListSightings = useSelector((state) => state.sightings.filteredSightingListSightings);

	const [sightings, setSightings] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);

	const [numberOfPages, setNumberOfPages] = useState()

	const [sightingPageStartAt, setsightingPageStartAt] = useState(1);
	const [sightingPageEndAt, setsightingPageEndAt] = useState(25)


	const [filterActive, setFilterActive] = useState(false)


	useEffect(() => {
		const fetchInitialLimitedSightings = async () => {
			await db.collection('sightings')
				.where('status', '==', 'approved')
				.orderBy('properties.sightingDate', 'desc')
				.limit(SIGHTINGS_PER_PAGE)
				.onSnapshot(function (querySnapshot) {
					var items = [];
					querySnapshot.forEach(function (doc) {
						items.push({ id: doc.id, ...doc.data() });
					});
					setCurrentPageSightings(items);
				})
		};
		const fetchAllSightings = async () => {
			await db.collection('sightings')
				.where('status', '==', 'approved')
				.orderBy('properties.sightingDate', 'desc')
				.onSnapshot(function (querySnapshot) {
					var items = [];
					querySnapshot.forEach(function (doc) {
						items.push({ id: doc.id, ...doc.data() });
					});
					dispatch(addSightings(items))
					dispatch(updateFilteredSightingListSightings(items))
					setNumberOfPages(Math.floor(items.length / SIGHTINGS_PER_PAGE))
				})
		};

		if (allSightings.length > 0) {
			// If sightings already fetched and saved in store, display the first page of those sightings
			setCurrentPageSightings(allSightings.slice(0, SIGHTINGS_PER_PAGE))
			setNumberOfPages(Math.floor(allSightings.length / SIGHTINGS_PER_PAGE))
			dispatch(updateFilteredSightingListSightings(allSightings))
		} else {
			fetchInitialLimitedSightings()
				.then(() => {
					fetchAllSightings()
				})

		}

	}, [allSightings, dispatch]);

	function sortAsc(a, b) {
		let sightingA = a.properties.sightingDate.seconds;
		let sightingB = b.properties.sightingDate.seconds;
		let comparison = 0;
		if (sightingA > sightingB) {
			comparison = 1;
		} else if (sightingA < sightingB) {
			comparison = -1;
		}
		return comparison * -1;
	}

	function sortDesc(a, b) {
		let sightingA = a.properties.sightingDate.seconds;
		let sightingB = b.properties.sightingDate.seconds;
		let comparison = 0;
		if (sightingA > sightingB) {
			comparison = 1;
		} else if (sightingA < sightingB) {
			comparison = -1;
		}
		return comparison;
	}


	const navigateTo = (page) => {
		let sliceStart = page * SIGHTINGS_PER_PAGE
		if (page === 1) {
			sliceStart = 0
		}
		let sliceEnd = page * SIGHTINGS_PER_PAGE + SIGHTINGS_PER_PAGE
		let nextPageSightings = filteredSightingListSightings.slice(sliceStart, sliceEnd);
		setCurrentPageSightings(nextPageSightings)
		setsightingPageStartAt(page === 1 ? 1 : sliceStart)
		setsightingPageEndAt(sliceEnd)
		setCurrentPage(page)
	}

	function showNextButton() {
		console.log(`numberOfPages: ${numberOfPages}`)
		console.log(`currentPage: ${currentPage}`)

		return currentPage !== numberOfPages
	}

	function showPrevButton() {
		return currentPage !== 1
	}


	const Pagination = () => {
		return (
			<div className='flex w-full items-center p-2'>
				{filteredSightingListSightings.length > 0 &&
					<p className='text-sm h-full flex items-center px-2 text-gray-green-70'>Showing sightings: &nbsp;
						{sightingPageStartAt} - {sightingPageEndAt} of {filteredSightingListSightings.length}
					</p>
				}
				<div className='flex ml-auto'>
					{showPrevButton() && <button className='mr-2 hover:bg-gray-green-10  bg-white shadow flex justify-center items-center h-12 w-12 rounded-md' onClick={() => navigateTo(currentPage - 1)}><FontAwesomeIcon icon='arrow-left' size='xs' /></button>}
					{showNextButton() && <button className='mr-0  hover:bg-gray-green-10 bg-white justify-center shadow flex items-center h-12 w-12 rounded-md' onClick={() => navigateTo(currentPage + 1)}><FontAwesomeIcon icon='arrow-right' size='xs' /></button>}
				</div>
			</div>
		)
	}

	function LocationDetails(props) {

		let country = props.location.find(element => element.id.includes('country'));
		let region = props.location.find(element => element.id.includes('region'));
		let place = props.location.find(element => element.id.includes('place'));
		console.log({ place })
		console.log(place?.text)

		return (
			<>
				{place && <p className='text-gray-green-80 text-sm leading-tight'>{place?.text},</p>}
				{region && <p className='text-gray-green-80 text-sm leading-tight'>{region?.text}</p>}
			</>
		)


	}

	const SightingListItem = (props) => {
		let linkArg = {
			pathname: `/sighting/${props.sighting.id}`,
			state: { from: '/sighting-list/' }
		}
		return (
			<Link to={linkArg} className='w-full  sighting-list-card hover:bg-gray-green-10 border-b border-sage-10  bg-white focus:border-green-70  py-2  block '>
				<div className='flex flex-wrap items-center'>

					<div className='md:w-1/3 w-full px-4'>
						<h4 className='text-gray-green-90 text-base md:text-sm leading-none mb-3 font-medium md:mb-0 '>{props.sighting.properties.title}</h4>
					</div>

					<div className='w-full md:w-1/6 px-4 flex flex-col'>
						<time className='text-sm flex text-gray-green-80 mr-4 mb-2 md:mb-0 leading-none'><Moment format='ll'>{props.sighting.properties.sightingDate}</Moment></time>
					</div>

					<div className='w-full md:w-1/6 px-4 flex flex-col'>
						<LocationDetails location={props.sighting.location.context} />
					</div>

					<ul className='w-full md:w-1/3 text-right flex justify-end px-3 flex-wrap flex-row-reverse md:flex-row mt-2 md:mt-0'>
						{/* <ImageFlag images={props.sighting.properties.images} /> */}
						{/* {props.sighting.properties.count > 0 && <Flag>{props.sighting.properties.count} {props.sighting.properties.count > 1 ? ' rabbits' : ' rabbit'}</Flag>} */}
						<DisplaySightingTags
							flags={props.sighting.properties.flags}
							count={props.sighting.properties.count}
							images={props.sighting.properties.images}
							tagSize='small'
						/>
						<DisplayStatusTag
							status={props.sighting.properties.status}
							tagSize='small'
						/>
						{/* {props.sighting.properties.status &&
							<div className={`capitalize status - tag status - tag--${ props.sighting.properties.status } m - 1 mt - 0`}>
								{props.sighting.properties.status}
							</div>
						} */}
					</ul>
				</div>

			</Link>
		)
	}


	function handleFilter(name, value) {
		// let { name, value } = e.target;
		doCombineFilters(name, value, allSightings, appState)
		let filteredSightings = doCombineFilters(name, value, allSightings, appState);
		setCurrentPage(1);
		setsightingPageStartAt(1);
		setsightingPageEndAt(25);
		setCurrentPageSightings(filteredSightings.slice(0, SIGHTINGS_PER_PAGE))
		setNumberOfPages(Math.floor(filteredSightings.length / SIGHTINGS_PER_PAGE))
		dispatch(updateFilteredSightingListSightings(filteredSightings))
		dispatch(updateFilters({
			[name]: value
		}))
	}

	// const RenderSightingListItems = () => {

	// 	return (

	// 	)
	// }
	const RenderLoader = () => {
		return (
			<div className='w-full h-full flex items-center justify-center loading-skeleton rounded-none min-h-screen'>
				<h1>LOADING</h1>
			</div>
		)
	}

	const SightingListSelect = ({ data, name, defaultValue, label }) => {
		return (
			<div className='w-full md:w-1/3 px-2'>
				{/* <label className='text-sm ml-4 mb-2 text-gray-green-70'>{label}</label> */}
				<div className='relative'>
					<select name={name} className='sighting-list__select text-gray-green-90' defaultValue={defaultValue} onChange={(e) => handleFilter(e.target.name, e.target.value)}>
						{data.map((option) => {
							return <option value={option.value}>{option.label}</option>
						})}
					</select>
					<FontAwesomeIcon className='absolute top-2/4 right-4 flex h-4 w-4 -mt-2 z-10 text-gray-green-50' icon='caret-down' />
				</div>
			</div>
		)

	}


	const SightingListFilters = () => {
		// When implementing sticky:
		// https://codepen.io/vsync/pen/bGbmqZe?editors=0110
		return (
			<div className=' bg-white sighting-list-filters'>
				<div className='flex w-full flex-col md:flex-row justify-end px-2  '>
					<SightingListSelect data={statusOptions} label='Status' name='filterStatus' defaultValue={filterStatus} />
					<SightingListSelect data={dateOptions} label='Date' name='filterDate' defaultValue={filterDate} />
					<SightingListSelect data={countOptions} label='Number of rabbits' name='filterCount' defaultValue={filterCount} />
				</div>

			</div>
		)
	}





	return (
		<>
			<PageHeader title='Sighting list' />
			<main>
				<div className='border border-sage-10 m-4 rounded-lg bg-white'>
					<SightingListFilters />

					<div className='flex sighting-list__filter-tags border-b border-sage-10'>
						<CurrentFilterTags handleFilter={handleFilter} updateClusters={false} />
					</div>

					{/* <div className='pt-4 mb-2 hidden md:flex '>
						<div className='w-1/3 px-4  text-gray-green-60 text-xs font-semibold'>
							Title
						</div>
						<div className='w-1/6 px-4  text-gray-green-60 text-xs font-semibold'>
							Sighting date
						</div>
						<div className='w-1/6 px-4  text-gray-green-60 text-xs font-semibold'>
							Location
						</div>
						<div className='w-1/3 px-4  text-gray-green-60 text-xs font-semibold'>
							Details
						</div>
					</div> */}
					{currentPageSightings.length > 0 ?
						<div className='flex flex-col '>
							{currentPageSightings.map((sighting) => (
								<SightingListItem key={sighting.id} sighting={sighting} />
							))}
							<Pagination />
						</div>
						: <RenderLoader />
					}
				</div>


				<div className='flex w-full h-12 py-4 mb-4'>
				</div>
			</main>
		</>
	)
}
