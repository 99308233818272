import React from "react";

const Logo = () => {
	return (
		<svg className='logo' width="190" height="18" viewBox="0 0 190 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
			<path d="M12.351 14.237C12.144 14.996 11.615 15.18 11.247 15.18C10.787 15.18 10.373 14.835 10.373 14.03V9.982C10.373 6.9 8.993 4.715 5.612 4.715C2.668 4.715 0.828 6.44 0.437 9.246L0.598 9.292C1.196 7.544 2.162 6.992 3.588 6.992C5.497 6.992 6.003 8.257 6.003 9.706V10.097L4.485 10.419C1.748 10.994 0 12.42 0 14.513C0 16.054 1.104 17.48 3.013 17.48C4.646 17.48 5.704 16.583 6.072 15.387L6.141 15.41C6.371 16.675 7.314 17.48 8.924 17.48C10.718 17.48 12.19 16.445 12.581 14.26L12.351 14.237ZM5.359 15.18C4.508 15.18 3.933 14.467 3.933 13.294C3.933 12.075 4.37 11.109 5.681 10.856L6.003 10.787V14.927C5.865 15.111 5.658 15.18 5.359 15.18Z" />
			<path d="M20.8698 4.715C19.4668 4.715 18.1098 5.359 17.5118 6.578V0.345001L12.0148 2.024V2.139C12.8198 2.415 13.1418 2.99 13.1418 3.864V16.491C14.3608 17.112 16.0398 17.48 17.5348 17.48C21.6518 17.48 24.8028 14.72 24.8028 10.005C24.8028 6.854 23.1928 4.715 20.8698 4.715ZM18.2018 16.698C17.8338 16.698 17.6728 16.629 17.5118 16.514V6.992C17.6038 6.785 17.7418 6.647 18.2018 6.647C19.2368 6.647 20.0878 8.004 20.0878 11.661C20.0878 15.065 19.3288 16.698 18.2018 16.698Z" />
			<path d="M37.6043 14.237C37.3973 14.996 36.8683 15.18 36.5003 15.18C36.0403 15.18 35.6263 14.835 35.6263 14.03V9.982C35.6263 6.9 34.2463 4.715 30.8653 4.715C27.9213 4.715 26.0813 6.44 25.6903 9.246L25.8513 9.292C26.4493 7.544 27.4153 6.992 28.8413 6.992C30.7503 6.992 31.2563 8.257 31.2563 9.706V10.097L29.7383 10.419C27.0013 10.994 25.2533 12.42 25.2533 14.513C25.2533 16.054 26.3573 17.48 28.2663 17.48C29.8993 17.48 30.9573 16.583 31.3253 15.387L31.3943 15.41C31.6243 16.675 32.5673 17.48 34.1773 17.48C35.9713 17.48 37.4433 16.445 37.8343 14.26L37.6043 14.237ZM30.6123 15.18C29.7613 15.18 29.1863 14.467 29.1863 13.294C29.1863 12.075 29.6233 11.109 30.9343 10.856L31.2563 10.787V14.927C31.1183 15.111 30.9113 15.18 30.6123 15.18Z" />
			<path d="M49.5501 15.295V8.05C49.5501 5.911 48.3081 4.715 46.2151 4.715C44.6741 4.715 43.5011 5.451 42.9951 6.808V4.715L37.4981 6.279V6.394C38.3261 6.647 38.6251 7.13 38.6251 8.234V15.295C38.6251 16.238 38.1421 16.744 37.4981 17.02V17.135H43.6621V17.02C43.1791 16.79 42.9951 16.353 42.9951 15.525V7.222C43.2481 6.877 43.5931 6.647 44.1221 6.647C44.7891 6.647 45.1801 7.107 45.1801 8.05V15.525C45.1801 16.353 44.9961 16.79 44.5131 17.02V17.135H50.6771V17.02C50.0331 16.744 49.5501 16.238 49.5501 15.295Z" />
			<path d="M62.6869 15.295V0.345001L56.6149 2.208V2.323C57.8109 2.323 58.3169 2.99 58.3169 3.841V5.175C57.9489 4.899 57.4429 4.715 56.6379 4.715C53.4179 4.715 51.0259 7.544 51.0259 11.546C51.0259 15.019 52.5669 17.48 55.1199 17.48C56.8449 17.48 57.8799 16.399 58.3169 15.088V17.135H63.8139V17.02C63.1699 16.744 62.6869 16.468 62.6869 15.295ZM57.4889 15.18C56.5689 15.18 55.7409 13.478 55.7409 10.373C55.7409 7.613 56.4309 5.497 57.6499 5.497C57.9259 5.497 58.1329 5.543 58.3169 5.727V14.697C58.1789 14.973 57.9489 15.18 57.4889 15.18Z" />
			<path d="M70.1315 17.48C73.7885 17.48 76.4335 14.881 76.4335 11.086C76.4335 7.291 73.7885 4.715 70.1315 4.715C66.4745 4.715 63.8295 7.291 63.8295 11.086C63.8295 14.881 66.4745 17.48 70.1315 17.48ZM70.1315 16.56C68.9585 16.56 68.4295 15.111 68.4295 11.086C68.4295 7.061 68.9585 5.612 70.1315 5.612C71.3045 5.612 71.8335 7.061 71.8335 11.086C71.8335 15.111 71.3045 16.56 70.1315 16.56Z" />
			<path d="M88.3958 15.295V8.05C88.3958 5.911 87.1538 4.715 85.0608 4.715C83.5198 4.715 82.3468 5.451 81.8408 6.808V4.715L76.3438 6.279V6.394C77.1718 6.647 77.4708 7.13 77.4708 8.234V15.295C77.4708 16.238 76.9878 16.744 76.3438 17.02V17.135H82.5078V17.02C82.0248 16.79 81.8408 16.353 81.8408 15.525V7.222C82.0938 6.877 82.4388 6.647 82.9678 6.647C83.6348 6.647 84.0258 7.107 84.0258 8.05V15.525C84.0258 16.353 83.8418 16.79 83.3588 17.02V17.135H89.5228V17.02C88.8788 16.744 88.3958 16.238 88.3958 15.295Z" />
			<path d="M96.7107 14.053C94.6637 14.053 93.2837 12.719 92.9387 10.12H99.5627C99.5627 5.75 96.7797 4.715 94.8247 4.715C91.6967 4.715 89.4197 7.475 89.4197 11.339C89.4197 15.364 92.0187 17.48 94.6637 17.48C96.5037 17.48 98.8957 16.422 99.5167 12.88L99.3327 12.834C98.7117 13.708 97.8607 14.053 96.7107 14.053ZM94.3187 5.474C95.2387 5.474 95.7217 6.371 95.7217 9.407H92.8697C92.8467 9.2 92.8467 8.993 92.8467 8.763C92.8467 6.693 93.3757 5.474 94.3187 5.474Z" />
			<path d="M111.801 15.295V0.345001L105.729 2.208V2.323C106.925 2.323 107.431 2.99 107.431 3.841V5.175C107.063 4.899 106.557 4.715 105.752 4.715C102.532 4.715 100.14 7.544 100.14 11.546C100.14 15.019 101.681 17.48 104.234 17.48C105.959 17.48 106.994 16.399 107.431 15.088V17.135H112.928V17.02C112.284 16.744 111.801 16.468 111.801 15.295ZM106.603 15.18C105.683 15.18 104.855 13.478 104.855 10.373C104.855 7.613 105.545 5.497 106.764 5.497C107.04 5.497 107.247 5.543 107.431 5.727V14.697C107.293 14.973 107.063 15.18 106.603 15.18Z" />
			<path d="M126.053 4.715C124.075 4.715 122.58 6.233 122.419 10.373H122.281V4.715L116.968 6.302V6.417C117.75 6.67 118.095 7.291 118.095 8.533V15.295C118.095 16.238 117.612 16.744 116.968 17.02V17.135H124.052V17.02C123.201 16.836 122.465 16.422 122.465 15.295V12.259C122.465 9.292 123.454 8.602 124.926 8.602C125.639 8.602 125.984 8.694 126.283 8.878H126.398V4.715H126.053Z" />
			<path d="M138.756 14.237C138.549 14.996 138.02 15.18 137.652 15.18C137.192 15.18 136.778 14.835 136.778 14.03V9.982C136.778 6.9 135.398 4.715 132.017 4.715C129.073 4.715 127.233 6.44 126.842 9.246L127.003 9.292C127.601 7.544 128.567 6.992 129.993 6.992C131.902 6.992 132.408 8.257 132.408 9.706V10.097L130.89 10.419C128.153 10.994 126.405 12.42 126.405 14.513C126.405 16.054 127.509 17.48 129.418 17.48C131.051 17.48 132.109 16.583 132.477 15.387L132.546 15.41C132.776 16.675 133.719 17.48 135.329 17.48C137.123 17.48 138.595 16.445 138.986 14.26L138.756 14.237ZM131.764 15.18C130.913 15.18 130.338 14.467 130.338 13.294C130.338 12.075 130.775 11.109 132.086 10.856L132.408 10.787V14.927C132.27 15.111 132.063 15.18 131.764 15.18Z" />
			<path d="M147.275 4.715C145.872 4.715 144.515 5.359 143.917 6.578V0.345001L138.42 2.024V2.139C139.225 2.415 139.547 2.99 139.547 3.864V16.491C140.766 17.112 142.445 17.48 143.94 17.48C148.057 17.48 151.208 14.72 151.208 10.005C151.208 6.854 149.598 4.715 147.275 4.715ZM144.607 16.698C144.239 16.698 144.078 16.629 143.917 16.514V6.992C144.009 6.785 144.147 6.647 144.607 6.647C145.642 6.647 146.493 8.004 146.493 11.661C146.493 15.065 145.734 16.698 144.607 16.698Z" />
			<path d="M160.283 4.715C158.88 4.715 157.523 5.359 156.925 6.578V0.345001L151.428 2.024V2.139C152.233 2.415 152.555 2.99 152.555 3.864V16.491C153.774 17.112 155.453 17.48 156.948 17.48C161.065 17.48 164.216 14.72 164.216 10.005C164.216 6.854 162.606 4.715 160.283 4.715ZM157.615 16.698C157.247 16.698 157.086 16.629 156.925 16.514V6.992C157.017 6.785 157.155 6.647 157.615 6.647C158.65 6.647 159.501 8.004 159.501 11.661C159.501 15.065 158.742 16.698 157.615 16.698Z" />
			<path d="M167.864 4.531C169.152 4.531 170.141 3.519 170.141 2.277C170.141 1.012 169.152 0 167.864 0C166.576 0 165.587 1.012 165.587 2.277C165.587 3.519 166.576 4.531 167.864 4.531ZM170.164 15.295V4.715L164.667 6.279V6.394C165.495 6.647 165.794 7.13 165.794 8.234V15.295C165.794 16.238 165.311 16.744 164.667 17.02V17.135H171.291V17.02C170.647 16.744 170.164 16.238 170.164 15.295Z" />
			<path d="M179.64 14.007C179.433 14.582 178.927 15.18 178.03 15.18C177.225 15.18 176.788 14.536 176.788 13.547V5.681H178.904V5.106H176.788V1.081H176.765C174.948 2.668 173.062 4.071 171.291 5.589V5.704H172.418V14.168C172.418 16.077 173.384 17.48 175.569 17.48C177.961 17.48 179.341 15.778 179.847 14.076L179.64 14.007Z" />
			<path d="M184.34 17.48C187.79 17.48 189.423 15.111 189.423 12.788C189.423 11.408 188.802 10.258 187.284 9.545L183.903 7.981C183.236 7.659 182.937 7.176 182.937 6.693C182.937 6.118 183.351 5.635 184.455 5.635C185.559 5.635 187.146 6.187 188.066 8.694L188.204 8.717L189.63 6.049C188.388 5.129 186.847 4.715 185.375 4.715C181.718 4.715 180.223 7.084 180.223 8.97C180.223 10.35 180.821 11.592 182.477 12.374L186.042 14.03C186.594 14.26 186.847 14.674 186.847 15.18C186.847 15.755 186.479 16.468 185.191 16.468C183.42 16.468 181.81 14.789 181.235 12.765L181.097 12.742L179.717 15.847C180.913 16.813 182.569 17.48 184.34 17.48Z" />
		</svg>)
}

export default Logo;

