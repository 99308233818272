import React, { useEffect } from 'react'
import FieldGroup from '../FieldGroup'
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useDispatch } from 'react-redux';
import { updateSightingDetails } from "../../../actions/newSightingActions";


export default function FormFieldCount({ control }) {
	const { register } = useFormContext();
	const dispatch = useDispatch();

	console.log('FormFieldCountRender')

	const watchCount = useWatch({ control, name: 'count' })
	useEffect(() => {
		dispatch(updateSightingDetails({ count: parseInt(watchCount) }));
	}, [watchCount])

	return <FieldGroup
		label='How many rabbits were there?'
		fieldName={'count'}
		control={control}>
		<div className='flex flex-col'>
			<div className='h-12 flex relative items-center'>
				<Controller
					control={control}
					render={() => (<>
						<input className='form-field__input w-40 rounded inline-flex h-12 mb-4 text-center mt-4'
							{...register('count',
								{ required: 'Please let us know approximately how many rabbits you saw.' }
							)}
							type='number'
							min='1'
							max='999'
						/>
					</>
					)}
				/>
			</div>
		</div>
	</FieldGroup>
}

