import * as actions from "../actions/sightingsActions";

export const initialState = {
  allSightings: [],
  filteredSightings: [],
  filteredSightingListSightings: [],
  sightingsInViewport: [],
  unfilteredSightingsInViewport: []
};

export default function sightingsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.ADD_SIGHTINGS:
      return {
        ...state,
        allSightings: action.payload,
        filteredSightings: action.payload,
      };
    case actions.UPDATE_SIGHTINGS:
      return { ...state, filteredSightings: action.payload };
    case actions.UPDATE_FILTERED_SIGHTING_LIST_SIGHTINGS:
      return { ...state, filteredSightingListSightings: action.payload };
    case actions.UPDATE_SIGHTINGS_IN_VIEWPORT:
      return { ...state, sightingsInViewport: action.payload };
    case actions.UPDATE_UNFILTERED_SIGHTINGS_IN_VIEWPORT:
      return { ...state, unfilteredSightingsInViewport: action.payload };
    case actions.CLEAR_ALL_FILTERS:
      return { ...state, filteredSightings: state.allSightings, sightingsInViewport: state.unfilteredSightingsInViewport };
    default:
      return state;
  }
}
