import React, { useEffect, useCallback } from 'react'
import FieldGroup from '../FieldGroup'
import { Controller, useFormContext, useWatch } from "react-hook-form";

import { useDispatch } from 'react-redux';
import { updateSightingDetails } from '../../../actions/newSightingActions';

import { debounce } from 'lodash';

export default function FormFieldAppearance({ control, visible, required }) {
	const { register } = useFormContext();

	const dispatch = useDispatch()
	const watchAppearance = useWatch({ control, name: 'appearance' })


	useEffect(() => {
		watchAppearance && watchAppearance.length > 1 && debouncedCallback(watchAppearance);
	}, [watchAppearance]);

	const debouncedCallback = useCallback(
		debounce(appearance => {
			dispatch(updateSightingDetails({ appearance: appearance }));
		}, 500),
		[]
	);



	return <FieldGroup label='What did the rabbit look like?' visible={visible}>
		<Controller
			control={control}

			render={() => (
				<textarea
					className='form-field__text-area'
					placeholder='Eg. floppy ears, spotted'
					{...register('appearance')} />
			)}
		/>
	</FieldGroup>
}