
function doReverseGeocode({ lng, lat }) {
	let url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng}%2C%20${lat}.json?access_token=${process.env.REACT_APP_MAPBOX_GL_ACCESS_TOKEN}`;
	console.log({ lng })
	console.log({ lat })
	return fetch(url)
		.then((res) => res.json())
		.then((out) => {
			return out.features[0]
		})
		.catch((err) => {
			console.log(err);
			return err
		});
}

module.exports = { doReverseGeocode };
