const doFilterDate = (input, filterBy) => {
	let output = input;
	let startDate = 0;

	const now = Math.round(new Date() / 1000);
	const day = 86400;
	const week = day * 7;
	const days30 = day * 30;
	const days90 = day * 90;
	const year = day * 365;

	switch (filterBy) {
		case "all-time":
			startDate = 0;
			break;
		case "last-year":
			startDate = now - year;
			break;
		case "last-90-days":
			startDate = now - days90;
			break;
		case "last-30-days":
			startDate = now - days30;
			break;
		case "last-7-days":
			startDate = now - week;
			break;
		default:
			break;
	}

	output = input.filter(function (sighting) {
		return sighting.properties.sightingDate / 1000 > startDate;
	});

	return output;
};

module.exports = { doFilterDate };
