import React from 'react';
import ReactDOM from 'react-dom';
import mapboxgl from 'mapbox-gl';

// mapboxgl.accessToken = 'MAPBOX_ACCESS_TOKEN';

class SingleSightingMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lng: 5,
      lat: 34,
      zoom: 2
    };
  }

  componentDidMount() {
    const map = new mapboxgl.Map({
      accessToken: process.env.REACT_APP_MAPBOX_GL_ACCESS_TOKEN,
      container: this.mapContainer,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: this.props.coordinates,
      zoom: 13
    });

    map.on('load', () => {
      const marker = new mapboxgl.Marker({
        color: "#48c08b",
      });
      marker.setLngLat(this.props.coordinates)
        .addTo(map);
    });
  }

  render() {
    return (
      <div className='single-sighting-map'>
        <div ref={el => this.mapContainer = el} className='mapContainer' ></div>
      </div>
    )
  }
}

export default SingleSightingMap;