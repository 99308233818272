import React from "react";
import ReactDOM from "react-dom";
import mapboxgl, { Marker } from "mapbox-gl";
// import MapboxGeocoder from "mapbox-gl-geocoder";
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { db } from "../firebase";
import { debounce } from "lodash";
// import { browserHistory } from 'react-router';
import { Router, Link } from "react-router-dom";
// import { Transition, CSSTransition } from "react-transition-group";

// import Moment from "react-moment";
import Sidebar from "./Sidebar";

import { connect } from "react-redux";
import SidebarSightings from "./SidebarSightings";

import MapTabBar from "./MapTabBar";

import SightingModal from "./SightingModal";
import SightingMapPopUp from "./SightingMapPopUp";


import MapHeader from "./MapHeader";
// import ReportForm from "./ReportForm";

// import "../sass/_map.scss";

import { getFullDataFromSightingsInView } from "../helpers/getFullDataFromSightingsInView";
import { getLocationFromIPAddress } from "../helpers/getLocationFromIPAddress";

import { addGeoJsonFeatureWrapper } from "../helpers/addGeoJsonFeatureWrapper";
import { formatDate } from "../helpers/formatDate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class SightingMap extends React.Component {
  mapRef = React.createRef();
  popUpRef = React.createRef();
  zoomToRef = React.createRef();

  newGeocoderRef = React.createRef();
  newGeolocatorRef = React.createRef();



  constructor(props) {
    super(props);

    this.state = {
      geocoderRef: null,
      newGeocoderRef: this.newGeocoderRef,
      // newGeolocatorRef: this.newGeolocatorRef,
      lng: 5,
      lat: 34,
      showLoadingSpinner: true,
      reportPanelOpen: false,
      geocoderResultReturned: false,
      geolocationResultStatus: null,
      geolocationStatus: null,
      satelliteLayerVisible: false,
      satelliteLayerAdded: false,
      hasDragged: false,
      markerPresent: false,
      searchResult: null,
      geocoderInputIsFocused: false,
      formStep: "location",
      user: {
        firstName: null,
        lastName: null,
      },
      sighting: {
        count: null,
      },
    };
  }

  // getGeocoderRef = ref => {
  //   this.setState({
  //     geocoderRef: ref
  //   });
  // };

  // getGeolocatorRef = ref =>{
  //   this.setState({
  //     geolocatorRef: ref
  //   });
  // }

  componentDidMount() {
    const RED = '#e8af84';
    const RED_LIGHT = '#F8D4BF';
    const GREEN = '#83ceb3';
    const GREEN_LIGHT = '#B7E6D9';
    const YELLOW = '#f5c75c';
    const YELLOW_LIGHT = '#FFE2B3';
    const BLUE = '#87d2d9';



    let url = window.location.pathname
    let paths = url.split('/')
    if (paths.length === 3) {
      this.props.updateAppState({ singleSighting: true })
      console.log('single sighting')
    }

    console.log('this.state.showLoadingSpinner');
    console.log(this.state.showLoadingSpinner);

    let zoomToGeocoder



    // console.log(this.props.mapLoaded)
    // console.log(
    //   {paths}
    // )
    let filterStatusExpression = this.props.appState.filterStatusExpression;
    let filterDateExpression = this.props.appState.filterDateExpression;
    let filterCountExpression = this.props.appState.filterCountExpression

    // Construct geojson wrapper for sightings
    let sightingsGeoJSON = {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [
          // {
          //   "type": "Feature",
          //   "geometry": {
          //     "type": "Point",
          //     "coordinates": [
          //       -122.0674308,
          //       49.2579812000001
          //     ]
          //   }
          // }
        ],
      },
    };

    this.geojson = {
      type: "FeatureCollection",
      cluster: true,
      clusterMaxZoom: 20, // Max zoom to cluster points on
      clusterRadius: 50,
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [0, 0],
          },
        },
      ],
    };

    getLocationFromIPAddress()
      .then(location => {
        this.map && this.map.setCenter(location)
        this.setState({ geolocationResultStatus: "returned" });
        this.setState({ proximity: { longitude: location[0], latitude: location[1] } })
        return location
      })
      .catch(err => console.warn(err))



    const getSightingsFromFirebase = (addSightingsToMap) => {

      let now = Math.round(new Date() / 1000);
      const day = 86400;
      const days30 = day * 30;
      const week = day * 7;
      const days90 = day * 90;
      const year = day * 365;

      let dateFrom = now - days90;


      // db.collection("sightings").where("status", "==", "pending").where('properties.sightingDate', ">=", dateFrom * 1000).orderBy('properties.sightingDate', 'asc')
      db.collection("sightings").where("status", "==", "approved").orderBy('properties.sightingDate', 'desc')
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let sightingData = doc.data();
            // console.log('docID')
            // console.log(doc.id)
            // console.log({sightingData})
            sightingData.id = doc.id;
            sightingData.properties.id = doc.id;
            // sightingData.properties.id = sightingData.id;
            sightingsGeoJSON.data.features.push(sightingData);
          });
          this.props.addSightings(sightingsGeoJSON.data.features);
          addSightingsToMap();

        });
    };

    // let geoJsonData, geolocator;

    const initZoomToGeoCoder = (proximity) => {
      console.log('proximity from initZoomToGeocoder')
      console.log({ proximity })

      zoomToGeocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        marker: false, // Do not use the default marker style
        placeholder: "Enter an address or landmark",
        proximity: proximity,
      });

      // if (this.zoomToRef) {
      //   this.zoomToRef.current.appendChild(zoomToGeocoder.onAdd(this.map));
      //   zoomToGeocoder.on('result', () => {
      //     this.props.updateAppState({ mapSearchOpen: false })
      //   })
      // }

    };





    // const { lng, lat } = this.state;
    // let map, geojson, geocoder;

    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_GL_ACCESS_TOKEN;
    this.map = new mapboxgl.Map({
      container: this.mapRef.current,
      style: "mapbox://styles/josephcoombes/ckipaesmm0fd017mk9x9xb7kb",
      // center: getLocationFromIP(),
      // center: [49, 122],
      zoom: 3, // Starting zoom level
      // attributionControl: false
    })

    console.log(this.state.proximity && this.state.proximity)
    console.log(this.map)

    // let newGeocoder = new MapboxGeocoder({
    //   accessToken: mapboxgl.accessToken,
    //   mapboxgl: mapboxgl,
    //   marker: false, // Do not use the default marker style
    //   placeholder: "Enter an address or landmark",
    // });

    let canvas = this.map.getCanvasContainer();
    let mapboxNavControl = new mapboxgl.NavigationControl();

    this.map.addControl(mapboxNavControl, "top-right");

    // Create data dictionary to convert firebase data to geojson
    const reverseGeocode = (lng, lat) => {
      let url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng}%2C%20${lat}.json?access_token=${process.env.REACT_APP_MAPBOX_GL_ACCESS_TOKEN}`;
      fetch(url)
        .then((res) => res.json())
        .then((out) => {
          // console.log(out.features[0]);
          this.props.updateLocation(out.features[0]);
          // this.props.addReverseGeocodeDetails(out)
          // console.log('reverseReocoderesult')
          // console.log(out.features[0]);
        })
        .catch((err) => {
          throw err;
        });
    };


    const addSightingsToMap = () => {
      // this.map.addSource("sightingsSource", sightingsGeoJSON,{
      //   cluster: true,
      //   clusterMaxZoom: 10, // Max zoom to cluster points on
      //   clusterRadius: 50
      // })

      // console.log('sightingsGeoJSON.data.features')
      // console.log(sightingsGeoJSON.data.features)
      // let sightingsSource = {type: 'featureCollection', features: sightingsGeoJSON.data.features}

      // sightingsGeoJSON['cluster'] = true;


      // console.log({sightingsGeoJSON})
      // this.map.addSource("sightingsSource", {
      //   type: 'geojson',
      //   data: {
      //     type: 'FeatureCollection',
      //     features: sightingsGeoJSON.data.features
      //   },
      //   cluster: true,
      //   clusterMaxZoom: 10, // Max zoom to cluster points on
      //   clusterRadius: 50
      // })
      // Radius of each cluster when clustering points (defaults to 50));
      // this.map.addSource("sightingsSource", {
      //   type: 'geojson',
      //   // Point to GeoJSON data. This example visualizes all M1.0+ earthquakes
      //   // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
      //   data: sightingsGeoJSON,

      //   });
      // this.map.addSource('earthquakes', {
      //   type: 'geojson',
      //   // Point to GeoJSON data. This example visualizes all M1.0+ earthquakes
      //   // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
      //   data:
      //   'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson',
      //   cluster: true,
      //   clusterMaxZoom: 14, // Max zoom to cluster points on
      //   clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
      //   });



      if (this.props.allSightings.length > 0) {

        let featureCollection = {
          "type": 'FeatureCollection',
          "features": this.props.allSightings
        }

        this.map.addSource('sightingsSource', {
          type: 'geojson',
          // Point to GeoJSON data. This example visualizes all M1.0+ earthquakes
          // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
          data: featureCollection,
          cluster: true,
          clusterMaxZoom: 10, // Max zoom to cluster points on
          clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
        });

        this.map.addSource('hiddenSightingsSource', {
          type: 'geojson',
          // Point to GeoJSON data. This example visualizes all M1.0+ earthquakes
          // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
          data: featureCollection,
        });

        this.map.addLayer({
          id: 'clusters',
          type: 'circle',
          source: 'sightingsSource',
          filter: ['has', 'point_count'],
          paint: {
            "circle-radius": 5,
            'circle-stroke-width': 4,
            'circle-stroke-color': 'rgba(68,184,132,0.5)',
            // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
            // with three steps to implement three types of circles:
            //   * Blue, 20px circles when point count is less than 100
            //   * Yellow, 30px circles when point count is between 100 and 750
            //   * Pink, 40px circles when point count is greater than or equal to 750
            'circle-color': [
              'step',
              ['get', 'point_count'],
              '#3d9b83',
              100,
              '#3d9b83',
              750,
              '#3d9b83'
            ],
            'circle-radius': [
              'step',
              ['get', 'point_count'],
              20,
              100,
              30,
              750,
              40
            ]
          }
        });

      }
      this.map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'sightingsSource',
        filter: ['has', 'point_count'],
        paint: {
          'text-color': '#ffffff',
        },
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-size': 12,
          'text-font': ['Fabriga Bold', 'Arial Unicode MS Bold'],

        }
      });

      this.map.addLayer({
        id: 'unclustered-point',
        source: 'sightingsSource',
        filter: ['!', ['has', 'point_count']],
        type: "circle",
        paint: {
          "circle-radius": 8,
          'circle-stroke-width': 4,
          "circle-color": [
            "case",
            ["==", ["get", "status"], "urgent"],
            RED, // then, colors red
            ["==", ["get", "status"], "unknown"],
            "#999999", // then, colors red
            ["==", ["get", "status"], "rescued"],
            GREEN, //Green, formerly blue: #5676ff
            ["==", ["get", "status"], "deceased"], // when the value of ID property equals to 0,
            "#000000", // BLACK
            '#f5c75c'], //yelllow
          "circle-stroke-color": [
            "case",
            ["==", ["get", "status"], "urgent"],
            RED_LIGHT, //Green, formerly blue: #5676ff
            ["==", ["get", "status"], "unknown"],
            "#eeeeee", //Green, formerly blue: #5676ff
            ["==", ["get", "status"], "rescued"],
            GREEN_LIGHT, // then, colors red
            ["==", ["get", "status"], "deceased"], // when the value of ID property equals to 0,
            "#999999", // BLACK
            "#FFF5DE"] //yelllow
        },
      });



      // this.map.addSource("sightingsSource", sightingsGeoJSON); 
      this.map.addSource("unfilteredSightingsSource", sightingsGeoJSON);

      this.props.allSightings.forEach((sighting) => {

        // create a HTML element for each feature
        var el = document.createElement('div');
        el.className = 'custom-marker';
        el.classList.add(sighting.properties.species)

      })

      this.map.addLayer({
        id: "hiddenSightingsLayer",
        // source: "unfilteredSightingsSource",
        source: "hiddenSightingsSource",
        type: 'circle',
        paint: {
          'circle-opacity': 0
        }
      })



      // Once source data is finished loading get markers within viewport and add to store
      this.map.on('sourcedata', (e) => {
        // console.log('sourcedata')
        //  https://github.com/mapbox/mapbox-gl-redux/issues/5
        // https://github.com/mapbox/mapbox-gl-js/issues/2476
        // https://github.com/mapbox/mapbox-gl-js/issues/2358#issuecomment-203126055
        // https://github.com/mapbox/mapbox-gl-js/issues/8089
        let sightingsInViewport, unfilteredSightingsInViewport;
        if (this.map.getLayer('clusters')) {

          // sightingsInViewport = this.map.queryRenderedFeatures({ layers: ['unclustered-point'] })
          // console.log({sightingsInViewport})
          // let onlyGoodData = sightingsInViewport.filter(function(e){
          //   return e !== undefined
          // })
          // console.log({onlyGoodData})
        }

        debounce(() => {
          console.log("debounced update")
          if (this.map.getLayer('hiddenSightingsLayer')) {
            console.log("this.map.getLayer('hiddenSightingsLayer')")
            unfilteredSightingsInViewport = this.map.queryRenderedFeatures({ layers: ['hiddenSightingsLayer'] })

          }
        }, 300);

        // console.log({ e })

        if (e.isSourceLoaded) {
          console.log('on sourcedata')
          if (!this.props.appState.layersAdded) {
            this.props.updateAppState({ layersAdded: true })
          }
          if ((e.sourceId === 'hiddenSightingsSource' && this.map.getLayer('hiddenSightingsLayer'))) {
            console.log('count rendered features')
            let renderedFeatures = this.map.queryRenderedFeatures({ layers: ['hiddenSightingsLayer'] })
            this.props.updateAppState({ renderedFeatureCount: renderedFeatures.length })
          }
          // this.props.updateSightingsInViewport(sightingsInViewport)
          // this.props.updateSightingsInViewport(getFullDataFromSightingsInView(sightingsInViewport, this.props.allSightings))
          this.props.updateUnfilteredSightingsInViewport(unfilteredSightingsInViewport)
          // this.props.updateUnfilteredSightingsInViewport(unfilteredSightingInViewport)
        }
      });

    };



    this.map.on("load", (e) => {

      // this.props.updateMapLoaded(true);
      getSightingsFromFirebase(addSightingsToMap);
      initZoomToGeoCoder(this.state.proximity);
    });

    // let popup = new mapboxgl.Popup({
    //   closeButton: true,
    //   closeOnClick: true,
    // });

    // popUpTrigger.onClick(console.log("trigger clicked"));

    // this.map.on("mouseenter", "points", (e) =>{
    //   console.log(e.features)
    // })

    this.map.on('click', 'clusters', (e) => {

      var features = this.map.queryRenderedFeatures(e.point, {
        layers: ['clusters']
      });

      var clusterId = features[0].properties.cluster_id;
      this.map.getSource('sightingsSource').getClusterExpansionZoom(
        clusterId,

        (err, zoom) => {
          if (err) return;
          this.map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom
          });
        }
      );
    });

    this.map.on('mouseenter', 'clusters', function () {
      // console.log('cluster in')
    });
    this.map.on('mouseleave', 'clusters', function () {
      // console.log('cluster out')
    });

    this.map.on('moveend', () => {
      console.log('movend')
      if (this.map.getLayer('hiddenSightingsLayer')) {
        let renderedFeatures = this.map.queryRenderedFeatures({ layers: ['hiddenSightingsLayer'] })
        this.props.updateAppState({ renderedFeatureCount: renderedFeatures.length })
      }
    });

    // this.map.on("mouseleave", "sightingsLayer", (e) => {
    //   console.log("MOUSE LEAVE")
    //   this.props.updateAppState({hoveredSighting: null})
    //   this.map.getCanvas().style.cursor = "";
    //   newPopup.remove();
    // });

    // so we need to find the full sighting data using it's id
    let unclusteredPopUp = new mapboxgl.Popup({
      closeButton: false
    })

    // let hoveredSighting = this.props.allSightings.find((sighting) =>{
    //   return sighting.id === e.features[0].properties.id
    // })

    // const sourceCallback = () => {
    //   // assuming 'map' is defined globally, or you can use 'this'
    //   if (this.map.getSource('sightingsSource') && this.map.isSourceLoaded('sightingsSource') && this.map.sourceDataType !== 'metadata') {
    //     console.log('source loaded!');
    //   }
    // }
    // this.map.on('sourcedata', sourceCallback);

    this.map.once('idle', () => {
      console.log('everything should be loaded: idle')
      this.props.updateAppState({ mapLoaded: true })
    })

    // this.map.once('style.load', (ev) => {
    //   this.setState({ showLoadingSpinner: false })
    //   console.log('everything should be loaded: style.load')
    //   this.props.updateAppState({ showMarkersLoadingSpinner: false })

    // });


    this.map.on("mouseleave", "unclustered-point", (e) => {
      // this.map.setPaintProperty("newSightingPoint", "circle-color", "purple");
      this.map.getCanvas().style.cursor = "grabbing";
      unclusteredPopUp.remove();
    });

    this.map.on("mouseenter", "unclustered-point", (e) => {
      console.log(e.features[0].properties)
      this.map.getCanvas().style.cursor = "pointer";
      let geometry = e.features[0].properties.geometry && JSON.parse(e?.features?.[0]?.properties?.geometry);
      const placeholder = document.createElement("span");

      ReactDOM.render(<SightingMapPopUp key={e.features[0].properties.id} sighting={e.features[0]} />, placeholder);

      this.props.updateAppState({ hoveredSighting: e.features[0].properties.id })

      this.map.getCanvas().style.cursor = "pointer";

      geometry && geometry.coordinates && unclusteredPopUp.setLngLat(geometry.coordinates)
        .setDOMContent(placeholder)
        .addTo(this.map);


    });

    this.map.on("click", (e) => {
      // console.log('close filter toggles')
      this.props.closeAllFilterToggles();
    })

    this.map.on("click", "unclustered-point", (e) => {


      let id = e.features[0].properties.id;
      let sighting = this.props.sightings.allSightings.find(function (element) {
        return element.id === id;
      });
      if (id) {
        let path = `/sighting/${id}`;
        // this.props.history.push(path);
        this.props.updateAppState({ showSightingModal: true })
        // router.push('/foo')

      }
      // console.log("selected sighting should be: ", sighting);
      this.props.updateSelectedSighting(sighting);
      // console.log("clicked el: ", sighting);
      // let sighting = e.features[0].properties;
      let coordinates = e.features[0].geometry.coordinates.slice();




      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      // const openModal = () => {
      //   this.props.updateAppState({ modalVisible: true });
      // };


      // new mapboxgl.Popup({
      //   closeButton: true,
      //   closeOnClick: true,
      // })
      //   .setLngLat(coordinates)
      //   .setDOMContent(placeholder)
      //   .addTo(this.map);
    });

    //  88888888b                              dP
    //  88                                     88
    // a88aaaa    dP   .dP .d8888b. 88d888b. d8888P .d8888b.
    //  88        88   d8' 88ooood8 88'  `88   88   Y8ooooo.
    //  88        88 .88'  88.  ... 88    88   88         88
    //  88888888P 8888P'   `88888P' dP    dP   dP   `88888P'

    let onMove = (e) => {
      var coords = e.lngLat;
      // console.log(coords.lng);
      // console.log(coords.lat);
      // Set a UI indicator for dragging.
      canvas.style.cursor = "grabbing";
      // Update the Point feature in `geojson` coordinates
      // and call setData to the source layer `point` on it.
      this.geojson.features[0].geometry.coordinates = [coords.lng, coords.lat];
      // this.map.getSource("new-sighting-location").setData(this.geojson);
      this.props.updateAppState({
        hasDraggedMarker: true,
        lng: coords.lng,
        lat: coords.lat,
      });

      // this.setState({
      //   hasDragged: true,
      //   lng: coords.lng,
      //   lat: coords.lat,
      // });
    };

    let onUp = (e) => {
      var coords = e.lngLat;
      this.props.updateAppState({
        lng: coords.lng,
        lat: coords.lat,
      });

      reverseGeocode(coords.lng, coords.lat);

      canvas.style.cursor = "";
      // Unbind mouse/touch events
      this.map.off("mousemove", onMove);
      this.map.off("touchmove", onMove);
    };


    let newPopup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false
    });






    this.map.on("mouseenter", "sightingsLayer", (e) => {
      return;
      // Objects and arrays get converted to strings when they're stored in map data,
      // so we need to find the full sighting data using it's id

      let hoveredSighting = this.props.allSightings.find((sighting) => {
        return sighting.id === e.features[0].properties.id
      })
      const placeholder = document.createElement("span");
      ReactDOM.render(<SightingMapPopUp test='foo' sighting={hoveredSighting} />, placeholder);

      this.props.updateAppState({ hoveredSighting: e.features[0].properties.id })

      this.map.getCanvas().style.cursor = "pointer";

      newPopup.setLngLat(hoveredSighting.geometry.coordinates)
        .setDOMContent(placeholder)
        .addTo(this.map);


    });

    // Change it back to a pointer when it leaves.
    this.map.on("mouseleave", "sightingsLayer", (e) => {
      // console.log("MOUSE LEAVE")
      this.props.updateAppState({ hoveredSighting: null })
      this.map.getCanvas().style.cursor = "";
      newPopup.remove();
    });

    this.map.on("mouseleave", "newSightingPoint", (e) => {
      // this.map.setPaintProperty("newSightingPoint", "circle-color", "purple");
      canvas.style.cursor = "";
    });

    this.map.on("mousedown", "newSightingPoint", (e) => {
      // Prevent the default map drag behavior.
      e.preventDefault();
      canvas.style.cursor = "grab";
      this.map.on("mousemove", onMove);
      this.map.once("mouseup", onUp);
    });

    this.map.on("touchstart", "newSightingPoint", (e) => {
      if (e.points.length !== 1) return;
      // Prevent the default map drag behavior.
      e.preventDefault();
      this.map.on("touchmove", onMove);
      this.map.once("touchend", onUp);
    });
  }

  render() {


    //  88888888b                              dP      dP     dP                          dP dP
    //  88                                     88      88     88                          88 88
    // a88aaaa    dP   .dP .d8888b. 88d888b. d8888P    88aaaaa88a .d8888b. 88d888b. .d888b88 88 .d8888b. 88d888b. .d8888b.
    //  88        88   d8' 88ooood8 88'  `88   88      88     88  88'  `88 88'  `88 88'  `88 88 88ooood8 88'  `88 Y8ooooo.
    //  88        88 .88'  88.  ... 88    88   88      88     88  88.  .88 88    88 88.  .88 88 88.  ... 88             88
    //  88888888P 8888P'   `88888P' dP    dP   dP      dP     dP  `88888P8 dP    dP `88888P8 dP `88888P' dP       `88888P'


    // document.getElementById('filters').addEventListener('change', function(e) {
    //   var day = e.target.value;
    //   if (day === 'all') {
    //     // `null` would not work for combining filters
    //     filterDay = ['!=', ['string', ['get', 'Day']], 'placeholder'];
    //   }
    //   /* the rest of the if statement */
    //   map.setFilter('collisions', ['all', filterHour, filterDay]);
    // });

    const handleMapSearchToggle = () => {
      this.props.updateAppState({ mapSearchOpen: !this.props.appState.mapSearchOpen })

      setTimeout(() => {
        const inputEl = document.querySelector('.map-search .mapboxgl-ctrl-geocoder.mapboxgl-ctrl input')
        inputEl && inputEl.focus();
      }, 100);

      // setTimeout(() => {
      //   this.props.appState.mapSearchOpen && this.props.updateAppState({ mapSearchOpen: false });
      // }, 10000)


    }



    return (
      <>
        <div className={`app-wrapper sighting-map-page
        ${this.props.appState.mobileFilterMenuIsOpen ? 'mobileFilterMenuIsOpen' : ''}
        map-tab:${this.props.appState.mapTab && this.props.appState.mapTab}
        `}>

          {/* <PublicNav map={this.map} page={'sighting-map'} /> */}

          {!this.props.appState.singleSighting === true &&
            <main className='map-sidebar-wrapper'>
              {this.props.appState.showSightingModal && <SightingModal />}



              {/* <div className="sidebar ">
              <section className="sidebar-sightings">
                <SidebarSightings map={this.map} popup={this.popup} />
              </section>
            </div> */}

              <div className={`sighting-map-wrapper ${this.props.appState.mapLoaded === true ? 'map:loaded' : 'map:loading'}`}>
                <div id="sighting-map" ref={this.mapRef}></div>

                {/* <button onClick={(e) => handleSearchButtonClick(e)}>Search</button> */}
                {!this.props.appState.mapLoaded && <div className='loading-skeleton sighting-map__loading-skeleton'></div>}
              </div>

              {/* {!this.props.appState.mobileFilterMenuIsOpen &&
                <div className={`map-search-wrapper ${!this.props.appState.mapLoaded ? 'hidden' : ''} `}>
                  <button onClick={handleMapSearchToggle} className="map-search-toggle">
                    <FontAwesomeIcon icon={this.props.appState.mapSearchOpen ? 'times' : 'search'} />
                  </button>
                  <div className={`map-search-backdrop ${this.props.appState.mapSearchOpen && 'visible'}`}>
                    <div className={`map-search relative `} ref={this.zoomToRef}>
                    </div>
                  </div>
                </div>} */}

              {this.props.appState.mapLoaded && <MapHeader map={this.map} />}

              <Link
                to='/report/'
                className='fixed bottom-4 left-4  button'
              >Report Sighting</Link>


            </main>}


        </div>
      </>
    );
  }
}

// Map Redux state to React component props
const mapStateToProps = (state) => ({
  formStep: state.appState.formStep,
  resultReturned: state.appState.resultReturned,
  mapLoaded: state.appState.mapLoaded,
  error: state.appState.error,
  errorText: state.appState.errorText,
  geoLocationResult: state.newSighting.location,
  user: state.user,
  newSighting: state.newSighting,
  appState: state.appState,
  sightings: state.sightings,
  allSightings: state.sightings.allSightings,
});

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    updateMapLoaded: (args) =>
      dispatch({ type: "UPDATE_MAP_LOADED", payload: args }),
    updateAppState: (args) =>
      dispatch({ type: "UPDATE_APP_STATE", payload: args }),
    updateFilters: (args) =>
      dispatch({ type: "UPDATE_FILTERS", payload: args }),
    updateFormStep: (data) =>
      dispatch({ type: "UPDATE_FORM_STEP", payload: data }),
    addGeolocateCoordinates: (data) =>
      dispatch({ type: "ADD_GEOLOCATE_COORDINATES", payload: data }),
    addReverseGeocodeDetails: (data) =>
      dispatch({ type: "ADD_REVERSE_GEOCODE_DETAILS", payload: data }),
    registerResultReturned: (data) =>
      dispatch({ type: "REGISTER_RESULT_RETURNED", payload: data }),
    showError: (data) => dispatch({ type: "SHOW_ERROR", payload: data }),
    updateSelectedSighting: (data) =>
      dispatch({ type: "UPDATE_SELECTED_SIGHTING", payload: data }),
    closeAllFilterToggles: (data) =>
      dispatch({ type: "CLOSE_ALL_FILTER_TOGGLES", payload: data }),
    updateSightingsInViewport: (data) =>
      dispatch({ type: "UPDATE_SIGHTINGS_IN_VIEWPORT", payload: data }),
    updateUnfilteredSightingsInViewport: (data) =>
      dispatch({ type: "UPDATE_UNFILTERED_SIGHTINGS_IN_VIEWPORT", payload: data }),
    updateLocation: (data) =>
      dispatch({ type: "UPDATE_LOCATION", payload: data }),
    addSightings: (data) => dispatch({ type: "ADD_SIGHTINGS", payload: data }),
    updateUserLocation: (data) =>
      dispatch({ type: "UPDATE_USER_LOCATION", payload: data }),
  };
};

// Connect Redux to React
export default connect(mapStateToProps, mapDispatchToProps)(SightingMap);
