// Create Redux action types
export const ADD_SIGHTINGS = "ADD_SIGHTINGS";
export const ADD_APPROVED_SIGHTINGS = "ADD_APPROVED_SIGHTINGS";
export const ADD_PENDING_SIGHTINGS = "ADD_PENDING_SIGHTINGS";
export const ADD_ARCHIVED_SIGHTINGS = "ADD_ARCHIVED_SIGHTINGS";

export const ADD_SINGLE_SIGHTING = "ADD_SINGLE_SIGHTING";
export const ADD_USER_DATA = "ADD_USER_DATA";
export const UPDATE_SIGHTING = "UPDATE_SIGHTING";
export const ADD_ADMIN_EMAIL = "ADD_ADMIN_EMAIL";


export const addSightings = (data) => ({
  type: ADD_SIGHTINGS,
  payload: data,
});

export const addAdminEmail = (data) => ({
  type: ADD_ADMIN_EMAIL,
  payload: data,
});

export const addApprovedSightings = (data) => ({
  type: ADD_APPROVED_SIGHTINGS,
  payload: data,
});
export const addPendingSightings = (data) => ({
  type: ADD_PENDING_SIGHTINGS,
  payload: data,
});
export const addArchivedSightings = (data) => ({
  type: ADD_ARCHIVED_SIGHTINGS,
  payload: data,
});

export const addSingleSighting = (data) => ({
  type: ADD_SINGLE_SIGHTING,
  payload: data,
});

export const addUserData = (data) => ({
  type: ADD_USER_DATA,
  payload: data,
});

export const updateSighting = (data) => ({
  type: UPDATE_SIGHTING,
  payload: data,
});
