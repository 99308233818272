import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { updateAppState, updateSingleSightingModalIsOpen } from "../actions/appStateActions";
import { Link } from "react-router-dom";
import { formatDate } from "../helpers/formatDate";
import Moment from "react-moment";
import { motion, AnimatePresence } from 'framer-motion';

import DisplaySightingTags from './DisplaySightingTags';
import DisplayStatusTag from './DisplayStatusTag';

// import "../sass/_sightingModal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SightingModal = (props) => {
  const dispatch = useDispatch();


  const history = useHistory();
  const params = useParams();
  let sightings = useSelector((state) => state.sightings.allSightings);
  let sighting = useSelector((state) => state.appState.selectedSighting);
  const [singleSighting, setSingleSighting] = useState();

  console.log({ params })
  console.log(sighting)


  // if params? that means user is coming from main map -
  // if no params then load sighting from Firebase

  // Get id from URL and find corresponding sighting in all sightings
  function findMatchingSighting() {
    console.log('findMatchingSighting')
    let matchingSighting = sightings.find(function (element) {
      return element.id === params.id;
    });
    console.log({ matchingSighting })
    matchingSighting &&
      setSingleSighting(matchingSighting)
  }

  // useEffect(() => {
  //   params && sighting && history.push(`/sighting/${sighting.id}`)
  //   },[sightings])


  // const closeModal = useCallback((event) =>{
  //   dispatch(updateSingleSightingModalIsOpen(false))
  //   dispatch(updateAppState({singleSighting: false}))
  //   dispatch(updateAppState({showSightingModal: false}))
  //   history.push("/sightings");
  // })
  const closeModal = useCallback((event) => {
    console.log('close modal')
    dispatch(updateSingleSightingModalIsOpen(false))
    dispatch(updateAppState({ singleSighting: false }))
    dispatch(updateAppState({ showSightingModal: false }))
    // history.push("/sightings");
  })

  const handleUserKeyPress = useCallback((event) => {

    if (event.keyCode === 27) {
      closeModal()
    }
  }, [closeModal]);
  // When mounted store sighting from

  useEffect(() => {
    if (params && sightings) {
      console.log("should be finding matching sighting")
      findMatchingSighting();
    };
  }, [findMatchingSighting, params, sightings])

  useEffect(() => {
    // dispatch(setSingleSighting(sighting));
    // Once params are available find matching sighting
    //  if (params && sightings) {
    //   console.log("should be finding matching sighting")
    //   findMatchingSighting();
    // }
    window.onpopstate = function (event) {
      console.log('back that ass up');
      console.log(event)
      dispatch(updateAppState({ showSightingModal: false }))
    }

    window.addEventListener("keydown", handleUserKeyPress);

    return () => {
      window.onpopstate = () => { }
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [dispatch, handleUserKeyPress]);

  // useEffect(() => {
  //   setSingleSighting(sighting);
  // });
  useEffect(() => {
    console.log('UPDATE MDOAL IS OPEN')
    dispatch(updateSingleSightingModalIsOpen(true));
  }, [dispatch]);






  // Handlers
  const handleBackdropClick = (e) => {
    console.log('backdropclick')
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    closeModal()
  };

  function handleCloseButtonClick() {
    closeModal();
  }
  const handleCopyClick = () => {
    navigator.clipboard.writeText(window.location.href);
    console.log(`copied: ${window.location.href}`);
  };

  // Components
  const Loading = () => {
    return (
      <div className="sighting-modal__loading">
        <p>LOADING</p>
      </div>
    );
  };



  const Images = () => {

    if (sighting.properties.images && sighting.properties.images.length > 0) {
      return (
        <header className="modal-header">
          <div className='modal-header__inner'>
            <div className='w-full h-full'>
              <img className='w-full h-full object-cover' src={sighting.properties.images[0]} />
            </div>
          </div>
        </header >
      )
    }
    else {
      return null;
    }

  }


  const Content = () => {
    let linkArg = {
      pathname: `/sighting/${sighting.id}`,
      state: { from: '/sighting-map/' }
    }

    function getCountryAndProvince(location) {
      console.log({location})
      if(!location) return {}
      let country = '';
      let province = '';
    
      if (location.context && Array.isArray(location.context)) {
        for (const item of location.context) {
          if (item.id.startsWith('country.')) {
            country = item.text_en_CA || item.text;
          } else if (item.id.startsWith('region.')) {
            province = item.text_en_CA || item.text;
          }
        }
      }
    
      return { country, province };
    }
    const { country, province } = getCountryAndProvince(sighting.location)||{}
    return (
      <>

        <Images />

        <div className="sighting-modal__body">

          <div className="flex flex-col">

            <p className='text-gray-60 font-semibold text-sm'><Moment format="LL">{sighting.properties.sightingDate}</Moment></p>

            <h1 className="text-2xl serif text-green-90 font-semibold flex mb-2 mr-16">
              {sighting.properties.title}
            </h1>
            <p className="text-gray-60 font-semibold text-sm">{province}</p>
            <p className="text-gray-60 font-semibold text-sm mb-2">{country}</p>

            <div className='flex flex-wrap -mx-1'>
              <DisplayStatusTag
                status={sighting.properties.status}
                size='small'
              />

              <DisplaySightingTags
                count={sighting.properties.count}
                images={sighting.properties.images}
                flags={sighting.properties.flags}
                size='small'
              />
            </div>
          </div>

          <button
            className="sighting-modal__close  flex p-2 items-center justify-center w-12 h-12"
            to="/"
            onClick={() => handleCloseButtonClick()}
          >
            <FontAwesomeIcon icon="times" size="xs" />
          </button>


          <Link onClick={() => dispatch(updateAppState({ showSightingModal: false }))} className='button w-full text-center justify-center mt-4' to={linkArg}>
            <span>View full sighting</span>
          </Link>
        </div>
      </>
    );
  };
  const Modal = () => {
    return (
      <>
        <div
          onClick={(e) => handleBackdropClick(e)}
          className="sighting-modal__backdrop"
        >
        </div>
        <div className="sighting-modal-wrapper ">
          <div
            className="sighting-modal shadow-lg">
            {sighting ? <Content /> : <Loading />}
          </div>
        </div>
      </>
    );
  };

  return <>{<Modal />}</>;
};

export default SightingModal;
