// import firebaseconfig from "./firebase";
import firebase from "firebase";
import { db } from "./firebase";


export const authMethods = {
  // firebase helper methods go here...
  signup: (email, password, setErrors, setToken) => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      //make res asynchonous so that we can make grab the token before saving it.
      .then(async (res) => {
        const token = await Object.entries(res.user)[5][1].b;
        //set token to localStorage
        await localStorage.setItem("ar_firebase_token", token);
        await localStorage.setItem("userData", null);
        setToken(token);
        //grab token from local storage and set to state.
        console.log(res);
      })
      .catch((err) => {
        setErrors((prev) => [...prev, err.message]);
      });
  },
  signin: (email, password, setErrors, setTokenUpdateUser) => {
    //change from create users to...
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      //everything is almost exactly the same as the function above
      .then(async (res) => {
        const token = await Object.entries(res.user)[5][1].b;

        let userData = {}
        userData.displayName = res.user.displayName
        userData.email = res.user.email
        userData.uid = res.user.uid

        // //set token to localStorage
        await localStorage.setItem("ar_firebase_token", token);
        await localStorage.setItem("userData", JSON.stringify(userData));

        //check if doc for this authenticated user exists in users collection

        var docRef = db.collection("users").doc(res.user.uid);
        docRef.get().then((doc) => {
          if (doc.exists) {
            console.log("Document data:", doc.data());
          } else {
            db.collection("users").doc(res.user.uid).set({
              email: res.user.email,
            }).then((docRef) => {
              console.log("Doc created: ", docRef.id);
            })
              .catch((error) => console.error("Error adding document: ", error))
          }
        }).catch((error) => {
          console.log("Error getting document:", error);
        });

        setTokenUpdateUser(window.localStorage.token, userData);
      })
      .catch((err) => {
        setErrors((prev) => [...prev, err.message]);
      });
  },
  //no need for email and password
  signout: (setErrors, handleSignoutSuccess) => {
    // signOut is a no argument function
    firebase
      .auth()
      .signOut()
      .then((res) => {
        //remove the token
        localStorage.removeItem("ar_firebase_token");
        localStorage.removeItem("userData");
        //set the token back to original state
        handleSignoutSuccess(null);
      })
      .catch((err) => {
        //there shouldn't every be an error from firebase but just in case
        setErrors((prev) => [...prev, err.message]);
        //whether firebase does the trick or not i want my user to do there thing.
        localStorage.removeItem("ar_firebase_token");
        localStorage.removeItem("userData");
        handleSignoutSuccess(null);
        console.error(err.message);
      });
  },
};
