export const ADD_SIGHTINGS = "ADD_SIGHTINGS";
export const UPDATE_SIGHTINGS = "UPDATE_SIGHTINGS";
export const UPDATE_SIGHTINGS_IN_VIEWPORT = "UPDATE_SIGHTINGS_IN_VIEWPORT";
export const UPDATE_UNFILTERED_SIGHTINGS_IN_VIEWPORT = "UPDATE_UNFILTERED_SIGHTINGS_IN_VIEWPORT";
export const UPDATE_FILTERED_SIGHTING_LIST_SIGHTINGS = "UPDATE_FILTERED_SIGHTING_LIST_SIGHTINGS";


export const CLEAR_ALL_FILTERS = "CLEAR_ALL_FILTERS";

export const addSightings = (sightings) => ({
  type: ADD_SIGHTINGS,
  payload: sightings,
});

export const updateSightings = (sightings) => ({
  type: UPDATE_SIGHTINGS,
  payload: sightings,
});

export const updateFilteredSightingListSightings = (sightings) => ({
  type: UPDATE_FILTERED_SIGHTING_LIST_SIGHTINGS,
  payload: sightings,
});

export const updateSightingsInViewport = (data) => ({
  type: UPDATE_SIGHTINGS_IN_VIEWPORT,
  payload: data,
});

export const updateUnfilteredSightingsInViewport = (data) => ({
  type: UPDATE_UNFILTERED_SIGHTINGS_IN_VIEWPORT,
  payload: data,
});

export const clearAllFilters = (sightings) => ({
  type: CLEAR_ALL_FILTERS,
});
