import React from 'react';


export default function DisplayStatusTag(props) {

	return (
		<div className={` status-tag status-tag--${props.status} status-tag--${props.tagSize}`}>
			{props.status}
		</div>
	)

}