// src/firebase.js

// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";

// Add the Firebase products that you want to use
import "firebase/firestore";
import "firebase/auth";

// TODO: Replace the following with your app's Firebase project configuration
// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASE_URL,
//   projectId: "abandoned-rabbits",
//   storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_APP_ID,
//   // apiKey: "AIzaSyBJeCgqWnXBY2g7S69p90qEDPPGl3z0oz4",
//   // authDomain: "abandoned-rabbits.firebaseapp.com",
//   // databaseURL: "https://abandoned-rabbits.firebaseio.com",
//   // projectId: "abandoned-rabbits",
//   // storageBucket: "abandoned-rabbits.appspot.com",
//   // messagingSenderId: "163947665960",
//   // appId: "1:163947665960:web:62914a50137127d978731d",
// };

// Dec 24

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCiEmIxxNUblLbAl3-N9xENM3Zbdkjshsc",
  authDomain: "abandoned-rabbits-v2.firebaseapp.com",
  projectId: "abandoned-rabbits-v2",
  storageBucket: "abandoned-rabbits-v2.appspot.com",
  messagingSenderId: "1042632676889",
  appId: "1:1042632676889:web:99a5bcab7a510baacbfc88",
  measurementId: "G-LXGN608BFZ"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

firebase.auth();

export const db = firebase.firestore();
export const fb = firebase;
// // export const storage =
// export const  storage = firebase.storage();
// export const  storageRef = storage.ref();
