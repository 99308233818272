import React from 'react'
import FormFieldError from '../FormFieldError/FormFieldError'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormState } from "react-hook-form";

import PropTypes from 'prop-types';

import './FieldGroup.scss'

export default function FieldGroup({ label, children, control, fieldName, labelActionComponent, customError, notice }) {


	const { errors } = useFormState({ control });

	let error = errors?.[fieldName]?.message

	if (customError) {
		error = customError
	}

	return <section className={`form-field-group -mx-4 rounded-lg  ${error ? 'bg-red-20 form-field-group--error' : ''}`}>
		<div className='flex'>
			<h4 className={`form-field__label ${error ? 'text-red-60' : ''}`}>{label} </h4>
			{error && <FontAwesomeIcon icon='exclamation-circle' className='ml-auto mr-0 text-red-60' />}
			{labelActionComponent && labelActionComponent}
		</div>
		<div className='flex flex-col'>
			{children}
		</div>
		{error && <div className='text-red-60 text-sm mt-1'>{error}</div>}
		{notice && <div className='bg-white border border-solid border-sage-10 -mx-4 rounded p-4 text-sm mt-1'>{notice}</div>}
	</section>
}

FieldGroup.propTypes = {
	label: PropTypes.string,
	fieldName: PropTypes.string,
	customError: PropTypes.string,
	control: PropTypes.object,
	labelActionComponent: PropTypes.element,
	children: PropTypes.node
}
