// function addGeoJsonWrapper(object) {
//   return object;
// }

function addGeoJsonFeatureWrapper(geometry, location, properties) {
  return {
    type: "Feature",
    geometry: geometry,
    location: location,
    properties: properties,
    test_id: "2343434",
  };
}

module.exports = { addGeoJsonFeatureWrapper };
