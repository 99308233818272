// Packages
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateAppState } from "../../actions/appStateActions";
import { clearNewSightingData } from "../../actions/newSightingActions";
import { useParams, useHistory } from "react-router-dom";
import { db, fb } from "../../firebase";

import { FormProvider, useForm, Controller, useFormState } from "react-hook-form";
// import { DevTool } from "@hookform/devtools";



// Helpers
import constructSightingTitle from '../../helpers/constructSightingTitle'

// Components

// import ReportFormNav from "./ReportFormNav";

import FormMapDetails from "./FormMapDetails";
import FormContactDetails from "./FormContactDetails";
import FormSightingDetails from "./FormSightingDetails";
import PageHeader from "../PageHeader";

import FormTabs from "./FormTabs/FormTabs";
import FormFooter from './FormFooter/FormFooter'

const ReportFormWrapper = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  let history = useHistory();
  const methods = useForm();
  const { control } = useForm()

  const { isValid, errors } = useFormState({ control })

  console.log('<ReportFormWrapper/> Render')

  useEffect(() => {
    console.log({ params })
  }, [])

  const updateFormStep = (step) => {
    dispatch(updateAppState({ formStep: step }));
  }

  const formStep = useSelector(
    (state) => state.appState.formStep
  );

  const appState = useSelector(
    (state) => state.appState
  );

  const firebaseStatus = useSelector(
    (state) => state.appState.firebaseStatus
  );

  const user = useSelector((state) => state.user);

  // const storeCount = useSelector((state) => state.newSighting.count);
  // const storeSpecies = useSelector((state) => state.newSighting.species);
  // const storeAppearance = useSelector((state) => state.newSighting.appearance);
  // const storeFlags = useSelector((state) => state.newSighting.flags);
  // const storeSightingDate = useSelector((state) => state.newSighting.sightingDate);
  // const storeNotes = useSelector((state) => state.newSighting.notes);
  // const storeRhdv = useSelector((state) => state.newSighting.rhdv);
  // const storeImages = useSelector((state) => state.newSighting.images);
  // const storeInjuredWild = useSelector((state) => state.newSighting.injuredWild);
  // const storeStatus = useSelector((state) => state.newSighting.status);
  // const storeLocation = useSelector((state) => state.newSighting.location);
  // const storeLocationNote = useSelector((state) => state.newSighting.locationNote);



  // const newSighting = useSelector(
  //   (state) => state.newSighting
  // );

  const authedUser = useSelector(
    (state) => state.user.authedUser
  );


  // const handleNextStep = () => {
  //   if (formStep === "location") {
  //     updateFormStep("sightingDetails");
  //   } else if (formStep === "sightingDetails") {
  //     updateFormStep("personalDetails");
  //   }
  // };

  // const handlePrevStep = () => {
  //   if (formStep === "sightingDetails") {
  //     updateFormStep("location");
  //   } else if (formStep === "personalDetails") {
  //     updateFormStep("sightingDetails");
  //   }
  // };



  // const handleSuccess = () => {
  //   // fb.auth().signOut();
  //   dispatch(clearNewSightingData())
  //   dispatch(updateAppState({ firebaseStatus: "success" }));
  //   history.push('/thank-you')
  // };

  // const handleFirebaseSubmit = () => {
  //   console.log('handleSubmit')
  //   console.log(`appState.contactPageValid: ${appState.contactPageValid}`)

  //   if (!storeLocation) {
  //     dispatch(updateAppState({ showValidationErrors: true }))
  //     return false
  //   }
  //   let userDocRef;
  //   let userID;

  //   dispatch(updateAppState({ firebaseStatus: "submitting" }));
  //   let now = new Date().toISOString();

  //   const createSightingDoc = (userDocRef) => {
  //     console.log('createSightingDoc')
  //     db.collection("sightings")
  //       .add({
  //         status: "pending",
  //         created: now,
  //         userRef: userDocRef,
  //         type: "Feature",
  //         geometry: {
  //           type: "Point",
  //           coordinates: storeLocation.geometry
  //             .coordinates,
  //         },
  //         location: storeLocation,
  //         properties: {
  //           title: constructSightingTitle(
  //             storeCount,
  //             storeLocation.text
  //           ),
  //           count: Number(storeCount),
  //           appearance: storeAppearance,
  //           sightingDate: storeSightingDate,
  //           images: storeImages,
  //           species: storeSpecies,
  //           flags: storeFlags,
  //           status: storeStatus,
  //           locationNote: storeLocationNote ? storeLocationNote : '',
  //           notes: storeNotes
  //           // categories: newSighting.categories,
  //         },
  //       })
  //       .then((docRef) => {
  //         db.collection("sightings")
  //           .doc(docRef.id)
  //           .update({ id: docRef.id });
  //         console.log("Doc created: ", docRef.id);
  //       })
  //       .catch((error) => console.error("Error adding document: ", error))
  //       .then(handleSuccess());
  //   }

  //   const createAnonUserDoc = (anonUID) => {
  //     console.log('createUserDoc')
  //     // First create user
  //     db.collection("users")
  //       .add({
  //         firstName: user.firstName,
  //         lastName: user.lastName,
  //         email: user.email,
  //         phone: user.phone,
  //         newsletterConsentGiven: user.newsletterConsentGiven,
  //         anonUID: anonUID
  //       })
  //       .then((docRef) => {
  //         userDocRef = docRef.id;
  //         console.log("UserDoc created: ", userDocRef);
  //         return userDocRef;
  //       })
  //       .then((userDocRef) => {
  //         createSightingDoc(userDocRef)
  //       })
  //       .catch((error) => console.error("Error adding document: ", error));
  //   }


  //   const sendToFirebase = () => {
  //     let now = Date.now();

  //     let currentUser = fb.auth().currentUser

  //     fb.auth().signInAnonymously()
  //       .then(() => {
  //         console.log('signed in anonymously')
  //       })
  //       .catch((error) => {
  //         var errorCode = error.code;
  //         var errorMessage = error.message;
  //         console.log(errorCode)
  //         console.log(errorMessage)
  //       });

  //     if (authedUser && authedUser.uid) {

  //       console.log('ALREADY SIGNED IN, DONT CREATE USER DOC')
  //       createSightingDoc(authedUser.uid)

  //     } else {

  //       console.log('create anon user')

  //       fb.auth().onAuthStateChanged(function (anonUser) {
  //         if (anonUser) {
  //           createAnonUserDoc(anonUser.uid);
  //         }
  //       });

  //     }

  //   };

  //   sendToFirebase();
  // };

  // const FormStepFooter = (props) => {

  //   function handleNextStepClick() {

  //     function navigate(location) {
  //       dispatch(updateAppState({ showValidationErrors: false }))
  //       history.push(location)
  //     }

  //     switch (formStep) {
  //       case 'location':
  //         appState.locationPageValid ?
  //           navigate('details') : dispatch(updateAppState({ showValidationErrors: true }))
  //         break;
  //       case 'details':
  //         appState.detailsPageValid ?
  //           navigate('contact') : dispatch(updateAppState({ showValidationErrors: true }))
  //         break;

  //       default:
  //     }
  //   }

  //   function currentPageIsValidated(formStep) {
  //     switch (formStep) {
  //       case 'location':
  //         return appState.locationPageValid
  //       case 'details':
  //         // code block
  //         return appState.detailsPageValid
  //       case 'contact':
  //         return appState.contactPageValid
  //       default:
  //     }
  //   }

  //   function NextLink({ disabled }) {
  //     return (
  //       <button onClick={handleNextStepClick}
  //         className={` ${disabled ? '' : 'disabled'} form-footer-button ml-auto form-footer-button__next`}>
  //         Next
  //       </button>
  //     )
  //   }

  //   function PreviousLink({ currentPage }) {
  //     let previousPage
  //     switch (currentPage) {
  //       case 'location':
  //         return null
  //       case 'details':
  //         previousPage = 'location'
  //         break;
  //       case 'contact':
  //         previousPage = 'details'
  //         break;
  //       default:
  //     }return (
  //       <Link to={previousPage}>Back</Link>
  //     )
  //   }


  //   const RenderSubmitButton = () => {
  //     if (props.formStepRoute === 'contact') {
  //       return <SubmitButtonMarkup />
  //     } else {
  //       return null;
  //     }
  //   }

  //   const SubmitButtonMarkup = (props) => {
  //     return (
  //       <button
  //         onClick={handleSubmit}
  //         className="bg-green-60 hover:bg-green-70 text-white font-bold py-2 px-4 rounded ml-auto self-end"
  //         disabled={firebaseStatus === 'submitting' ? true : false}
  //       >
  //         {firebaseStatus === 'submitting' && 'Submitting'}
  //         {firebaseStatus !== 'submitting' && 'Submit report'}
  //       </button>
  //     )
  //   }

  //   return (
  //     <div className="form-step__footer mt-4 w-full block mb-8 ">
  //       <div className="w-full relative flex">

  //         <PreviousLink currentPage={formStep} />

  //         {(formStep === 'location' || formStep === 'details') && <NextLink disabled={currentPageIsValidated(formStep) ? true : false} />}

  //         <RenderSubmitButton {...props} />

  //       </div>
  //     </div>
  //   );
  // };

  const FormSuccess = () => {
    return (<h1>SUCCESS</h1>)
  }



  const handleValidate = () => {
    methods.trigger()
    console.log(isValid)
    console.log(methods.control)
    return isValid
  }


  return (
    <>
      <div className="report-page pb-12">

        <PageHeader title='Report a rabbit sighting' container={true} />
        <FormTabs />

        <section className={`container-with-sidebar `}>


          {firebaseStatus !== "success" && (
            <>
              {props.formStepRoute === "location" && (
                <FormMapDetails />
              )}
              {props.formStepRoute === "details" && (
                <FormSightingDetails {...methods} />
              )}
              {props.formStepRoute === "contact" && (
                <FormContactDetails />
              )}
            </>
          )}



        </section>
      </div >
    </>
  );
};

export default ReportFormWrapper;
