const doFilterStatus = (input, filterBy) => {
	let output = input;
	if (filterBy === "all") {
		return output
	} else {
		output = input.filter(function (sighting) {
			return sighting.properties.status === filterBy;
		});
	}
	return output;
};

module.exports = { doFilterStatus };