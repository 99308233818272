import React, { useState, useEffect, ReactDOM } from "react";

import { useFormContext } from 'react-hook-form';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import './form-control.css';

const FormControl = ({
	value,
	label,
	fieldName,
	required,
	selected,
	fieldType,
	defaultValue,
	labelClasses }) => {
	const { register } = useFormContext();

	return <label
		className={`form-control form-control--${fieldType} ${labelClasses} ${selected ? 'checked ' : ''}`}
		htmlFor={`${fieldName}-${value}`}
	>
		<input
			id={`${fieldName}-${value}`}
			{...register(fieldName, { required: required }
			)}

			value={value}
			type={fieldType ? fieldType : 'radio'} />

		{fieldType === 'radio' && <span className={`form-control__indicator`}>
			<span className={`form-control__indicator-icon`}></span>
		</span>}

		{fieldType === 'checkbox' && <div className={`form-control__indicator`}>
			{selected && <FontAwesomeIcon className='form-control__indicator-icon' icon="check" size="xs" />}
		</div>}


		<span>{label}</span>
	</label>

}
export default FormControl