import React, { useEffect } from 'react'

import { statusOptions } from '../../../data/reportFormOptions'

import FieldGroup from '../FieldGroup'
import FormControl from '../../FormControl'
import { Controller, useWatch } from "react-hook-form";

import { updateSightingDetails } from "../../../actions/newSightingActions";

import { useDispatch } from 'react-redux';

export default function FormFieldStatus({ control, visible }) {
	const dispatch = useDispatch()

	const watchStatus = useWatch({ control, name: 'status' })


	useEffect(() => {
		dispatch(updateSightingDetails({ status: watchStatus }));
	}, [watchStatus])

	return <FieldGroup
		label='Status'
		visible={visible}
		control={control}
		fieldName='status'
	>
		{statusOptions.map((option) => {
			return <Controller
				control={control}
				key={`status-${option.value}`}
				render={() => (
					<FormControl
						{...option}
						required={true}
						fieldName='status'
						defaultValue='general'
						selected={watchStatus === option.value}
						fieldType='radio' />
				)}
			/>
		})}
	</FieldGroup>
}