export const UPDATE_USER_FIRST_NAME = 'UPDATE_USER_FIRST_NAME'
export const UPDATE_USER_LAST_NAME = 'UPDATE_USER_LAST_NAME'
export const UPDATE_USER_EMAIL = 'UPDATE_USER_EMAIL'
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS'
export const UPDATE_ALL_USER_DETAILS = 'UPDATE_ALL_USER_DETAILS'

export const UPDATE_USER_LOCATION = 'UPDATE_USER_LOCATION'
export const UPDATE_AUTHED_USER = 'UPDATE_AUTHED_USER'
export const RESET_AUTHED_USER = 'RESET_AUTHED_USER'

export const updateUserFirstName = firstName => ({
  type: UPDATE_USER_FIRST_NAME,
  payload: firstName,
})

export const updateUserLastName = lastName => ({
  type: UPDATE_USER_LAST_NAME,
  payload: lastName,
})

export const updateAuthedUser = userData => ({
  type: UPDATE_AUTHED_USER,
  payload: userData,
})

export const resetAuthedUser = userData => ({
  type: RESET_AUTHED_USER,
  payload: userData,
})

export const updateUserEmail = email => ({
  type: UPDATE_USER_EMAIL,
  payload: email,
})

export const updateUserDetails = data => ({
  type: UPDATE_USER_DETAILS,
  payload: data,
})

export const updateAllUserDetails = data => ({
  type: UPDATE_ALL_USER_DETAILS,
  payload: data,
})

export const updateUserLocation = data => ({
  type: UPDATE_USER_LOCATION,
  payload: data,
})
