import React, { useEffect, useState, useRef } from "react";
// import ReactDOM from "react-dom";
import { useParams, useLocation, useHistory } from "react-router-dom";
import mapboxgl from "mapbox-gl";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PageHeader from "../PageHeader";
import AdminNav from "./AdminNav";
import SingleSightingMap from "./SingleSightingMap";
// import MapboxGeocoder from "mapbox-gl-geocoder";
import DatePicker from "react-datepicker";

import { FormProvider, useForm } from "react-hook-form";

import ImageUpload from '../ReportForm/ImageUpload/ImageUpload';
import Moment from "react-moment";
import moment from 'moment';

import "react-datepicker/dist/react-datepicker.css";

import { updateSighting } from "../../actions/adminActions";

import Select from "react-select";

import { db, fb } from "../../firebase"; // add

import "../../sass/_admin-single-sighting.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AdminSingleSighting = () => {
  const singleSightingMapRef = React.useRef(null);
  // singleSightingMapRef = React.createRef();
  const params = useParams();
  const history = useHistory();
  const [sighting, setSighting] = useState([]);
  const [hasChanged, setHaschanged] = useState('false');
  const [coordinates, setCoordinates] = useState();
  const [updatedFields, setUpdatedFields] = useState([]);
  const [user, setUser] = useState();
  const [selectedTab, setSelectedTab] = useState('sighting');

  const authedUser = useSelector((state) => state.user.authedUser);

  const methods = useForm();

  const [freshlyUploadedImages, setFreshlyUploadedImages] = useState([])

  let uploadedImages = useSelector(
    (state) => state.newSighting.images
  );

  useEffect(() => {
    // setSelectedTab('approved');
    getSightingsFromFirebase()
    console.log(sighting)
  }, [])

  const getSightingsFromFirebase = (status) => {

    db.collection("sightings")
      .doc(params.id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          let sighting = doc.data();
          // this.singleSighting = docData;
          // this.props.addSingleSighting(docData);
          // this.setState({ sighting: { ...docData } });
          let userDocRef = sighting.userRef;
          // addMarkerToMap(docData);
          setSighting(sighting)
          setCoordinates(sighting.location.geometry.coordinates)
          return userDocRef;
        } else {
          // doc.data() will be undefined in this case
          // Need to handle this better
          console.log("No such document!");
        }
      })
      .then((userDocRef) => {
        db.collection("users")
          .doc(userDocRef)
          .get()
          .then((doc) => {
            let user = doc.data()
            // console.log("user data", doc.data());
            // this.props.addUserData(doc.data());
            // console.log(this.props.user);
            setUser(user)
          });
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  }
  const Loading = () => {
    return (<h1>LOADING</h1>)
  }

  function deleteSighting() {
    if (window.confirm("Delete this sighting?")) {
      db.collection("sightings").doc(sighting.id).delete().then(() => {
        console.log("Document successfully deleted!");
        history.push('/admin')
      }).catch((error) => {
        console.error("Error removing document: ", error);
      });
    }
  }

  const handleSave = () => {
    db.collection("sightings").doc(sighting.id).update(sighting)
      .catch((error) => console.error("Error adding document: ", error))
      .then(console.log('success'));
  }

  // const SightingSpecies = () => {
  //   const handleSpeciesChange = (e) => {
  //     setSighting({
  //       ...sighting, properties: {
  //         ...sighting.properties,
  //         species: e.target.value
  //       }
  //     })
  //   }
  //   return (
  //     <select value={sighting.properties.species} onChange={(e) => handleSpeciesChange(e)}>
  //       <option value='wild'>Wild</option>
  //       <option value='domestic'>Domestic</option>
  //     </select>
  //   )
  // }
  const SightingCount = () => {

    // TO DO - STORE ORIGINAL STATE
    const handleCountChange = (e) => {
      setSighting({
        ...sighting, properties: {
          ...sighting.properties,
          count: e.target.value
        }
      })
    }
    return (
      <>
        <span>Origin value:</span>
        <select value={sighting.properties.count} onChange={(e) => handleCountChange(e)}>
          <option value={1}>1</option>
          <option value={2}>2</option>
          <option value={3}>3</option>
          <option value={4}>4</option>
          <option value={5}>5+</option>
          <option value={10}>10+</option>
          <option value={20}>20+</option>
          <option value={30}>30+</option>
        </select>
      </>
    )
  }

  const saveData = (queryVal, successFunction) => {
    db.collection("sightings").doc(params.id).update(queryVal)
      .catch((error) => console.error("Error adding document: ", error))
      .then(successFunction());
  }



  // const CheckBoxField = () =>{
  //   return(
  //   <label
  //         className={`form-field__checkbox-label ${
  //           current.includes(props.value) ? "selected" : ""
  //         }`}
  //       > 
  //         <div className='form-field__checkbox-circle'>
  //         {current.includes(props.value) && <FontAwesomeIcon icon="check" size="xs" />}
  //         </div>
  //         <input
  //           name={props.name}
  //           value={props.value}
  //           onClick={(e) => handleCheckboxChange(props.name, e.target.value)}
  //           checked={current.includes(props.value)}
  //           type="checkbox"
  //         ></input>
  //         <span>{props.label}</span>
  //         {/* <Field type='checkbox' name='flags' onChange={handleCheckboxChange} value={props.value} /> */}
  //       </label>
  //   )
  // }

  const SightingAppearance = () => {
    const handleAppearanceChange = (e) => {
      setSighting({
        ...sighting, properties: {
          ...sighting.properties,
          appearance: e.target.value
        }
      })
    }
    return (
      <div >
        <label className='text-sm'>Appearance</label>
        <textarea onBlur={(e) => handleAppearanceChange(e)} defaultValue={sighting.properties.appearance} />
      </div>
    )
  }

  const CheckBoxField = (props) => {
    return (
      <label>
        {props.label}
        {/* <input onChange={props.onChange} type="checkbox" name={props.fieldName} value={props.value}
    defaultChecked={props.type.includes(props.value)} /> */}
      </label>
    )
  }

  const SightingCategories = () => {
    const handleCategoryChange = (e) => {
      let currentCategories = sighting.properties.categories;

      if (currentCategories.includes(e.target.value)) {
        // If item is already in array then remove it on click
        currentCategories = currentCategories.filter(
          (item) => item !== e.target.value
        );
        setSighting({
          ...sighting, properties: {
            ...sighting.properties,
            categories: currentCategories
          }
        })
      } else {
        currentCategories.push(e.target.value)
        setSighting({
          ...sighting, properties: {
            ...sighting.properties, categories: currentCategories
          },
        });
      }
    }
    return (
      <div>
        <h4>Categories</h4>
        <CheckBoxField onChange={handleCategoryChange} type={sighting.properties.categories} name="categories" value="rescued" label="Rescued" />
        <CheckBoxField onChange={handleCategoryChange} type={sighting.properties.categories} name="categories" value="urgent" label="Urgent" />
        <CheckBoxField onChange={handleCategoryChange} type={sighting.properties.categories} name="categories" value="deceased" label="Deceased (non-RHDV)" />
        <CheckBoxField onChange={handleCategoryChange} type={sighting.properties.categories} name="categories" value="desceased-rhdv" label="Deceased RHDV" />
      </div>
    )
  }



  const handleDateChange = () => {

  }

  const SightingDate = () => {
    console.log(sighting.properties.sightingDate)
    const formattedDate = new Date(sighting.properties.sightingDate)
    console.log({ formattedDate })
    return (
      <div>
        <DatePicker
          maxDate={new Date()}
          selected={formattedDate}
          onChange={handleDateChange}
          // onBlur={handleDateBlur}
          dateFormat="MMMM d, yyyy"
          // onChangeRaw={handleDateChangeRaw}
          todayButton="Today"
        />
      </div>
    )
  }



  function EditableTextField(props) {
    const inputRef = useRef();
    const [isEditing, setIsEditing] = useState(false);
    const [submitStatus, setSubmitStatus] = useState('initial');
    const [fieldValue, setFieldValue] = useState(props.originalValue);
    let newValue;


    function handleSuccess() {
      setTimeout(() => {
        setSubmitStatus('complete')
      }, 1000);
      setTimeout(() => {
        setSubmitStatus('initial')
      }, 5000);
    }

    const handleButtonClick = () => {
      setIsEditing(!isEditing)
      if (inputRef.current) {
        newValue = inputRef.current.value;
        setFieldValue(inputRef.current.value);
        if (newValue !== props.originalValue) {
          setSubmitStatus('submitting');
          saveData({ [props.firebaseQuery]: inputRef.current.value }, handleSuccess)
        }
      }
    }
    const Controls = () => {
      if (isEditing) {
        return (
          <button className='p-2 rounded bg-green-60 text-xs text-white font-semibold' onClick={handleButtonClick}>
            Save
          </button>
        )
      }
      else {
        return (
          <button className='right-0  p-2 text-xs text-gray-60 font-semibold' onClick={handleButtonClick}>
            Edit
          </button>
        )
      }
    }

    const InputMarkup = (props) => {

      if (props.textarea) {
        return (
          <textarea ref={inputRef} className='form-field__input' defaultValue={fieldValue} />
        )
      } else {
        return (
          <input ref={inputRef} className='form-field__input' defaultValue={fieldValue} />
        )

      }



    }

    return (
      <div className='w-full mb-4'>
        <div className='flex items-end'>
          <label className='form-field__label flex'>{props.label}</label>
          <div className='ml-auto'>
            {submitStatus === 'initial' && <Controls />}
            {submitStatus === 'submitting' && <p>Submitting</p>}
            {submitStatus === 'complete' && <><FontAwesomeIcon icon='check' /><span>Saved</span></>}
          </div>
        </div>

        {isEditing ? <InputMarkup {...props} /> : <p style={{ minHeight: '40px' }} className='form-field__input h-auto min-h-10 cursor-not-allowed text-gray-60 flex items-center '>{fieldValue}</p>}

      </div>
    )
  }
  // end EditableTextField

  function EditableSelectField(props) {
    const inputRef = useRef();
    const [isEditing, setIsEditing] = useState(false);
    const [submitStatus, setSubmitStatus] = useState('initial');
    const [fieldValue, setFieldValue] = useState(props.originalValue);
    let newValue;

    function handleSuccess() {
      setTimeout(() => {
        setSubmitStatus('complete')
      }, 1000);
      setTimeout(() => {
        setSubmitStatus('initial')
      }, 5000);
    }

    const handleButtonClick = () => {
      setIsEditing(!isEditing)

      if (inputRef.current) {

        newValue = inputRef.current.value;
        setFieldValue(inputRef.current.value);
        if (newValue !== props.originalValue) {
          setSubmitStatus('submitting');
          saveData({ [props.firebaseQuery]: inputRef.current.value }, handleSuccess)
        }
      }
    }
    const Controls = () => {
      if (isEditing) {
        return (
          <button className='p-2 rounded bg-green-60 text-xs text-white font-semibold' onClick={handleButtonClick}>
            Save
          </button>
        )
      }
      else {
        return (
          <button className='right-0  p-2 text-xs text-gray-60 font-semibold' onClick={handleButtonClick}>
            Edit
          </button>
        )
      }
    }


    return (
      <div className='w-full mb-8'>
        <div className='flex'>
          <label className='form-field__label'>{props.label}</label>
          <div className='ml-auto'>
            {submitStatus === 'initial' && <Controls />}
            {submitStatus === 'submitting' && <p>Submitting</p>}
            {submitStatus === 'complete' && <><FontAwesomeIcon icon='check' /><span>Saved</span></>}
          </div>
        </div>
        <div className={`${submitStatus === 'submitting' && 'loading-skeleton'} relative`}>
          {isEditing ?
            <select ref={inputRef} defaultValue={fieldValue}>
              {props.options.map((option) => {
                return <option value={option.value}>{option.title}</option>
              })}
            </select> : <p className='form-field__input flex items-center cursor-not-allowed text-gray-70 capitalize'>{fieldValue}</p>}
        </div>
      </div>
    )
  }


  function EditableCheckboxField(props) {
    const inputRef = useRef();
    const [isEditing, setIsEditing] = useState(false);
    const [submitStatus, setSubmitStatus] = useState('initial');
    const [fieldValue, setFieldValue] = useState(props.originalValue);
    let newValue;

    function handleSuccess() {
      setTimeout(() => {
        setSubmitStatus('complete')
      }, 1000);
      setTimeout(() => {
        setSubmitStatus('initial')
      }, 5000);
    }

    const handleSaveClick = () => {
      setIsEditing(false)

      console.log(`UPDATE FIREBASE ${fieldValue}`)
      setSubmitStatus('submitting');
      saveData({ [props.firebaseQuery]: fieldValue }, handleSuccess)
    }

    const handleButtonClick = () => {
      setIsEditing(!isEditing)

      // if()

      // if (inputRef.current) {

      //   newValue = inputRef.current.value;
      //   setFieldValue(inputRef.current.value);
      //   if (newValue !== props.originalValue) {
      //     console.log(`UPDATE FIREBASE ${fieldValue}`)
      //     setSubmitStatus('submitting');
      //     saveData({ [props.firebaseQuery]: fieldValue }, handleSuccess)
      //   }
      // }
    }
    const Controls = () => {
      if (isEditing) {
        return (
          <button className='p-2 rounded bg-green-60 text-xs text-white font-semibold' onClick={handleSaveClick}>
            Save
          </button>
        )
      }
      else {
        return (
          <button className='right-0  p-2 text-xs text-gray-60 font-semibold' onClick={handleButtonClick}>
            Edit
          </button>
        )
      }
    }

    function handleCheckboxChange(val) {

      if (fieldValue.includes(val)) {
        setFieldValue(fieldValue => fieldValue.filter(item => item !== val));
      } else {
        setFieldValue([...fieldValue, val]);
      }

    }


    return (
      <div className='w-full mb-8'>
        <div className='flex'>
          <label className='form-field__label'>{props.label}</label>
          <div className='ml-auto'>
            {submitStatus === 'initial' && <Controls />}
            {submitStatus === 'submitting' && <p>Submitting</p>}
            {submitStatus === 'complete' && <><FontAwesomeIcon icon='check' /><span>Saved</span></>}
          </div>
        </div>
        <div className={`${submitStatus === 'submitting' && 'loading-skeleton'} relative`}>
          {props.options.map((option, i) => {
            return (<div key={`checkbox-${option.value}-${i}`}>
              <label className='bg-white rounded border border-solid border-sage-10 flex items-center p-3'>
                <input onClick={() => handleCheckboxChange(option.value)} disabled={!isEditing} checked={fieldValue.includes(option.value)} type='checkbox' name={props.label} value={option.value} />
                {option.label}
              </label></div>)
          })}
        </div>
      </div>
    )
  }



  const saveImagesToFirebase = () => {

    function handleSuccess() {
      alert('SUCCESS')
    }
    let currentImages = sighting.properties.images;
    let tempImgArr = []

    currentImages.forEach((image) => {
      tempImgArr.push(image)
    })

    uploadedImages.forEach((image) => {
      tempImgArr.push(image)
    })

    console.log({ tempImgArr })

    saveData({ 'properties.images': tempImgArr }, handleSuccess)
  }



  function EditableSelectField(props) {
    const inputRef = useRef();
    const [isEditing, setIsEditing] = useState(false);
    const [submitStatus, setSubmitStatus] = useState('initial');
    const [fieldValue, setFieldValue] = useState(props.originalValue);
    let newValue;

    function handleSuccess() {
      setTimeout(() => {
        setSubmitStatus('complete')
      }, 1000);
      setTimeout(() => {
        setSubmitStatus('initial')
      }, 5000);
    }

    const handleButtonClick = () => {
      setIsEditing(!isEditing)
      if (inputRef.current) {
        newValue = inputRef.current.value;
        setFieldValue(inputRef.current.value);
        if (newValue !== props.originalValue) {
          setSubmitStatus('submitting');
          saveData({ [props.firebaseQuery]: inputRef.current.value }, handleSuccess)
        }
      }
    }





    const Controls = () => {
      if (isEditing) {
        return (
          <button className='p-2 rounded bg-green-60 text-xs text-white font-semibold' onClick={handleButtonClick}>
            Save
          </button>
        )
      }
      else {
        return (
          <button className='right-0  p-2 text-xs text-gray-60 font-semibold' onClick={handleButtonClick}>
            Edit
          </button>
        )
      }
    }


    return (
      <div className='w-full mb-8'>
        <div className='flex'>
          <label className='form-field__label'>{props.label}</label>
          <div className='ml-auto'>
            {submitStatus === 'initial' && <Controls />}
            {submitStatus === 'submitting' && <p>Submitting</p>}
            {submitStatus === 'complete' && <><FontAwesomeIcon icon='check' /><span>Saved</span></>}
          </div>
        </div>
        <div className={`${submitStatus === 'submitting' && 'loading-skeleton'} relative`}>
          {isEditing ?
            <select ref={inputRef} defaultValue={fieldValue}>
              {props.options.map((option) => {
                return <option value={option.value}>{option.title}</option>
              })}
            </select> : <p className='capitalize'>{fieldValue}</p>}
        </div>
      </div>
    )
  }

  const User = () => {

    if (sighting.userRef === 'legacy') {
      return (<h1>Legacy sighting</h1>)
    }
    else if (sighting.userRef && user) {
      return (<div className='container'>
        <h1>Submitted by</h1>
        <h2>{user.firstName} {user.lastName}</h2>
        <a href={`mailto:${user.email}`}>{user.email}</a>
        <h2>{user.phone}</h2>
      </div>)
    }
    else {
      return (<h1>User not found</h1>)
    }
  }

  const EditImages = () => {
    let images = sighting.properties.images;

    const handleDelete = image => {
      let confirmed = window.confirm('Are you sure you want to delete this image?')
      if (confirmed) {
        let images = sighting.properties.images;
        console.log({ images })

        images = images.filter((item) => {
          return item !== image
        })

        function handleSuccess() {
          console.log('Deleted from firebase document')
        }


        saveData({ 'properties.images': images }, handleSuccess)
        // Create a reference to the file to delete
        var deleteRef = fb.storage().refFromURL(image)


        // Delete the file
        deleteRef.delete().then(() => {
          // File deleted successfully
          console.log('maybe deleted')
        }).catch(function (error) {
          console.log('error')
          console.log(error)
          // Uh-oh, an error occurred!
        });
        console.log({ images })

      } else {
        return
      }

    }
    if (images.length > 0) {
      return (
        images.map((image) => {
          return <div className='relative'>
            <button className='absolute bg-red-50' onClick={() => handleDelete(image)}>Delete</button>
            <img className='w-36' src={image} />
            <code>{image}</code>
          </div>
        })
      )
    } else {
      return <p>No images</p>
    }
  }

  function isSuperAdmin() {
    if ((authedUser && authedUser.email)) {
      if (authedUser.email === 'josephcoombes@me.com' || authedUser.email === 'sasharink@me.com') {
        return true
      }
    }
    else {
      return false
    }
  }


  const Sighting = () => {

    const flagOptions = [
      {
        value: "Babies present",
        label: "Babies present"
      },
      {
        value: "injured",
        label: "Injured"
      },
      {
        value: "rhdv-suspected",
        label: "(Suspected) Rabbit Hemorrhagic Disease (RHDV)"
      },
      {
        value: "rhdv-confirmed",
        label: "(Confirmed) Rabbit Hemorrhagic Disease (RHDV)"
      },
      {
        value: "recent",
        label: "Recent Abandonment"
      },
      {
        value: "feral",
        label: "Feral"
      }
    ]

    const countOptions = [
      {
        value: "0",
        title: "Unknown"
      },
      {
        value: "1",
        title: "1"
      },
      {
        value: "2",
        title: "2"
      },
      {
        value: "3",
        title: "3"
      },
      {
        value: "4",
        title: "4"
      },
      {
        value: "5",
        title: "5+"
      },
      {
        value: "10",
        title: "10+"
      },
      {
        value: "20",
        title: "20+"
      },
      {
        value: "50",
        title: "30+"
      },
      {
        value: "100",
        title: "100+"
      }
    ]

    const statusOptions = [
      {
        value: 'urgent',
        title: 'Urgent'
      },
      {
        value: 'general',
        title: 'General'
      },
      {
        value: 'rescued',
        title: 'Rescued'
      },
      {
        value: 'deceased',
        title: 'Deceased'
      },
      {
        value: 'unknown',
        title: 'Unknown'
      }
    ]

    const speciesOptions = [
      {
        value: 'domestic',
        title: 'Domestic'
      },
      {
        value: 'wild',
        title: 'Wild'
      }
    ]

    const publicationStatusOptions = [
      {
        value: 'pending',
        title: 'Pending'
      },
      {
        value: 'approved',
        title: 'Approved'
      },
      {
        value: 'archived',
        title: 'Archived'
      }
    ]

    console.log('sighting.properties')
    console.log(sighting.properties)
    // const coordinates = sighting.location.geometry.coordinates ? sighting.location.geometry.coordinates : undefined;
    return (
      <div className='container pt-8 pb-24'>
        {/* {sighting.properties.title ? <SightingTitle/> : <Loading/>}
      {sighting.properties.species ? <SightingSpecies/> : <Loading/>}
      {sighting.properties.count ? <SightingCount/> : <Loading/>} */}
        {sighting.properties && <>

          <div className='flex'>
            <div className='w-1/2'>
              <label className='form-field__label'>ID</label>
              <p className='form-field__input cursor-not-allowed flex items-center text-gray-70'>{params.id}</p>
            </div>

            <div className='w-1/2'>
              <Link className='btn' to={`/sighting/${params.id}`}>View Sighting</Link>
            </div>
          </div>


          <div className='w-1/2'>
            <EditableTextField
              label='Title'
              originalValue={sighting.properties.title}
              firebaseQuery='properties.title'
            />
          </div>

          <div className='w-1/2'>
            <EditableTextField
              label='Location note'
              originalValue={sighting.properties.locationNote}
              firebaseQuery='properties.locationNote'
              textarea={true}
            />
          </div>

          <div className='w-1/2'>
            <EditableTextField
              label='Appearance'
              originalValue={sighting.properties.appearance}
              firebaseQuery='properties.appearance'
              textarea={true}

            />
          </div>

          <div className='w-1/2'>
            <EditableTextField
              label='Notes'
              originalValue={sighting.properties.notes}
              firebaseQuery='properties.notes'
              textarea={true}

            />
          </div>

          <div className='w-1/4'>
            <EditableSelectField
              label='Publication status'
              originalValue={sighting.status}
              firebaseQuery='status'
              options={publicationStatusOptions}
            />
          </div>

          <div className='w-1/4'>
            <EditableSelectField
              label='Status'
              originalValue={sighting.properties.status}
              firebaseQuery='properties.status'
              options={statusOptions}
            />
          </div>

          <div className='w-1/4'>
            <EditableSelectField
              label='Species'
              originalValue={sighting.properties.species}
              firebaseQuery='properties.species'
              options={speciesOptions}
            />
          </div>

          <div className='w-1/4'>
            <EditableSelectField
              label='Count'
              originalValue={sighting.properties.count}
              firebaseQuery='properties.count'
              options={countOptions}
            />
          </div>

          <div className='w-1/4'>
            <label className="form-field__label">Sighting date</label>
            <SightingDate />
          </div>

          <div className='w-1/4'>
            <EditableCheckboxField
              label='Flags'
              originalValue={sighting.properties.flags}
              firebaseQuery='properties.flags'
              options={flagOptions}
            />
          </div>
          <div className='w-full'>
            <EditImages />
          </div>
          <div className='w-full'>
            <FormProvider {...methods}>

              <ImageUpload />
            </FormProvider>
            {uploadedImages && uploadedImages.length > 0 &&
              <div>
                <button onClick={() => saveImagesToFirebase()}>Save</button>
                NOTICE: Images won't actually be saved until you click SAVE
              </div>}
          </div>
          {isSuperAdmin() &&
            <button onClick={deleteSighting} className='bg-red-20 border border-solid p-4 rounded border-red-30 text-red-90'>Delete</button>
          }
        </>}
      </div>
    )
  }


  return (
    <>
      <PageHeader title='Edit sighting' />
      <div className=' border-b border-solid border-sage-10 '>
        <div className='container h-12 flex'>
          <button onClick={() => setSelectedTab('sighting')} className='flex items-center px-3 text-sm'>Sighting details</button>
          <button onClick={() => setSelectedTab('user')} className='flex items-center px-3 text-sm'>User details</button>
        </div>
      </div>
      <div className='container'>

        {selectedTab === 'sighting' ? <Sighting /> : <User />}
      </div>
    </>

  )

}

export default AdminSingleSighting;
