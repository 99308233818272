const getLocationFromIPAddress = (url) => {
	// const API_URL = 'https://api.ipstack.com/24.86.142.127?access_key=bfcd0de45e7e79c0838f7dab8875350c';
	// const API_URL = 'https://ipinfo.io/json';
	const API_URL = 'https://ipgeolocation.abstractapi.com/v1?api_key=5a4771ba1c2741b1be095462cf7e9349';

	return fetch(API_URL)  // return this promise
		.then(response => response.json())
		.then((json) => {
			let location = [
				json.longitude,
				json.latitude,
			]
			return location
		})
}

module.exports = { getLocationFromIPAddress };