// Create Redux action types
export const UPDATE_FORM_STEP = "UPDATE_FORM_STEP";
export const UPDATE_SINGLE_SIGHTING_MODAL_IS_OPEN = "UPDATE_SINGLE_SIGHTING_MODAL_IS_OPEN";
export const REGISTER_RESULT_RETURNED = "REGISTER_RESULT_RETURNED";
export const UPDATE_MAP_LOADED = "UPDATE_MAP_LOADED";
export const UPDATE_REPORT_MAP_LOADED = "UPDATE_REPORT_MAP_LOADED";
export const SHOW_ERROR = "SHOW_ERROR";
export const UPDATE_ERROR_TEXT = "UPDATE_ERROR_TEXT";
export const UPDATE_APP_STATE = "UPDATE_APP_STATE";
export const UPDATE_SELECTED_SIGHTING = "UPDATE_SELECTED_SIGHTING";

export const TOGGLE_FILTER_DROPDOWN = "TOGGLE_FILTER_DROPDOWN";

export const CLOSE_ALL_FILTER_TOGGLES = "CLOSE_ALL_FILTER_TOGGLES";
export const RESET_FILTERS = "RESET_FILTERS";
export const UPDATE_FILTERS = "UPDATE_FILTERS";
export const UPDATE_STATUS_FILTERS = "UPDATE_STATUS_FILTERS";
export const UPDATE_FILTER_TOGGLE_STATE = "UPDATE_FILTER_TOGGLE_STATE";

export const updateFormStep = (data) => ({
  type: UPDATE_FORM_STEP,
  payload: data,
});

export const registerResultReturned = (data) => ({
  type: REGISTER_RESULT_RETURNED,
  payload: data,
});

export const updateMapLoaded = (data) => ({
  type: UPDATE_MAP_LOADED,
  payload: data,
});

export const updateSingleSightingModalIsOpen = (data) => ({
  type: UPDATE_SINGLE_SIGHTING_MODAL_IS_OPEN,
  payload: data,
});

export const updateReportMapLoaded = (data) => ({
  type: UPDATE_REPORT_MAP_LOADED,
  payload: data,
});

export const showError = (data) => ({
  type: SHOW_ERROR,
  payload: data,
});

export const updateErrorText = (data) => ({
  type: UPDATE_ERROR_TEXT,
  payload: data,
});

export const updateSelectedSighting = (data) => ({
  type: UPDATE_SELECTED_SIGHTING,
  payload: data,
});

export const updateAppState = (data) => ({
  type: UPDATE_APP_STATE,
  payload: data,
});

export const updateStatusFilters = (data) => ({
  type: UPDATE_STATUS_FILTERS,
  payload: data,
});

export const updateFilterToggleState = (data) => ({
  type: UPDATE_FILTER_TOGGLE_STATE,
  payload: data,
});

export const closeAllFilterToggles = (data) => ({
  type: CLOSE_ALL_FILTER_TOGGLES,
  payload: data,
});

export const resetFilters = (data) => ({
  type: RESET_FILTERS,
  payload: data,
});

// export const updateFilters = (data) =>
//   Promise.resolve({
//     type: UPDATE_FILTERS,
//     payload: data,
//   });

export const toggleFilterDropdown = (data) => ({
  type: TOGGLE_FILTER_DROPDOWN,
  payload: data,
});

export const updateFilters = (data) => ({
  type: UPDATE_FILTERS,
  payload: data,
});
