const doFilterSpecies = (input, filterBy) => {
	let output = input;
	if (filterBy === "all") {
		output = input.filter(function (sighting) {
			return (
				sighting.properties.species === "wild" ||
				sighting.properties.species === "domestic"
			);
		});
	} else {
		output = input.filter(function (sighting) {
			return sighting.properties.species === filterBy;
		});
	}
	return output;
};

module.exports = { doFilterSpecies };