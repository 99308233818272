import React from 'react'
import { flagOptions } from '../../../data/reportFormOptions'

import FieldGroup from '../FieldGroup'
import FormControl from '../../FormControl'
import { Controller, useWatch } from "react-hook-form";

import { useEffect } from 'react';
import { updateSightingDetails } from '../../../actions/newSightingActions';
import { useDispatch } from 'react-redux';

export default function FormFieldFlags({ control, visible }) {
	const dispatch = useDispatch()
	const watchFlags = useWatch({ control, name: 'flags' })
	console.log('formfieldflags render')

	useEffect(() => {
		dispatch(updateSightingDetails({ flags: watchFlags }));
	}, [watchFlags])


	return <FieldGroup label='Flags' visible={visible}>
		{flagOptions.map((option) => {
			return <Controller
				control={control}
				key={`flags-${option.value}`}
				render={() => (
					<FormControl
						{...option}
						fieldName='flags'
						labelClasses='no-wrap'
						selected={watchFlags && watchFlags.includes(option.value)}
						fieldType='checkbox' />
				)}
			/>
		})}
	</FieldGroup>
}

