function formatDate(seconds) {
	// let d = new Date(seconds * 1000)
	// const year = d.getFullYear() // 2019
	// const date = d.getDate() // 23

	// const days = [
	// 	'Sun',
	// 	'Mon',
	// 	'Tue',
	// 	'Wed',
	// 	'Thu',
	// 	'Fri',
	// 	'Sat'
	// ]
	// const months = {
	// 	0: 'January',
	// 	1: 'February',
	// 	2: 'March',
	// 	3: 'April',
	// 	4: 'May',
	// 	5: 'June',
	// 	6: 'July',
	// 	7: 'August',
	// 	8: 'September',
	// 	9: 'October',
	// 	10: 'November',
	// 	11: 'December'
	// }

	// const dayIndex = d.getDay()
	// const dayName = days[dayIndex] // Thu	
	// let monthName = months[d.getMonth()]
	// let trimmed = monthName.substring(0,3);

	// const formatted = `${trimmed}. ${date},  ${year}`

	// return formatted
}

module.exports = { formatDate };
