import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { updateAppState } from "../actions/appStateActions";



import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Sidebar from "./Sidebar";
import { fromJSON } from "postcss";
import PageHeader from "./PageHeader";

const ThankYouPage = (props) => {
  const dispatch = useDispatch();

  const history = useHistory();
  const params = useParams();


  const firebaseStatus = useSelector(
    (state) => state.appState.firebaseStatus
  );

  useEffect(() => {

    console.log("USE EFFECT")
    firebaseStatus === 'success' && dispatch(updateAppState({ firebaseStatus: "" }))

  }, [])
  return (
    <>
      <PageHeader title='Thank you' />
      <div className='container-with-sidebar pt-8'>
        <h3 className='text-xl mb-8'>Thank for you reporting an abandoned rabbit sighting.</h3>
        <h4 className='text-xl mb-8'>Have another one to report? <Link to='/report' className='text-green-80'>Report another sighting.</Link></h4>

        <h4 className='mb-4'>If this is an urgent situation, please also contact your local rabbit rescue or animal shelter. <a className='text-green-80' href="https://abandonedrabbits.com/resources/">Find your local rabbit rescue here.</a></h4>

        <h4 className='mb-4'>Abandoned rabbits depend on us to advocate for them.</h4>

        <a className='text-green-80' href="http://eepurl.com/dIwdI9">Subscribe to Abandoned Rabbits Newsletter</a> to receive news updates, rescue stories, and calls to action. We promise we won't spam you!

        <h5 className='font-semibold mt-8'>Follow along on social media</h5>
        <ul>
          <li>Instagram <a className='text-green-80' href='https://www.instagram.com/abandonedrabbits/'>@abandonedrabbits</a></li>
          <li>Facebook <a className='text-green-80' href='https://www.facebook.com/abandonedrabbits/'>/abandonedrabbits</a></li>
          <li>Twitter <a className='text-green-80' href='https://twitter.com/Abandoned_Rbts'>@Abandoned_Rbts</a></li>
        </ul>
      </div>
    </>
  )



};

export default ThankYouPage;
