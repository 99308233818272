import React, { useState } from "react";
import { authMethods } from "./AuthMethods";
import { useHistory } from "react-router-dom";

import { useDispatch } from 'react-redux';
import { resetAuthedUser, updateAuthedUser } from "./actions/userActions";

export const firebaseAuth = React.createContext();

const AuthProvider = (props) => {
  const dispatch = useDispatch();
  const initState = { email: "", password: "" };
  const [inputs, setInputs] = useState(initState);
  const [errors, setErrors] = useState([]);
  const [token, setToken] = useState(null);
  let history = useHistory();

  const handleSignup = () => {
    // middle man between firebase and signup
    console.log("handleSignup");
    // calling signup from firebase server
    authMethods.signup(inputs.email, inputs.password, setErrors, setToken);
    console.log(errors, token);
  };

  const handleSignInSuccess = (token, userData) => {
    console.log('handleSignInSuccess')
    setToken(token);
    dispatch(updateAuthedUser(userData));
  }

  const handleSignin = () => {
    //changed to handleSingin
    console.log("handleSignin!!!!");
    // made signup signin
    authMethods.signin(inputs.email, inputs.password, setErrors, handleSignInSuccess);
    console.log(errors, token);
  };

  const handleSignOutSuccess = (token) => {
    dispatch(resetAuthedUser({}));
    // setToken(token);
    setToken(null)
    console.log('handlesignoutsuccess');
    history.push("/report");

  }

  const handleSignout = () => {
    authMethods.signout(setErrors, handleSignOutSuccess);
  };

  return (
    <firebaseAuth.Provider
      value={{
        handleSignup,
        handleSignin,
        token,
        inputs,
        setInputs,
        errors,
        handleSignout,
      }}
    >
      {props.children}
    </firebaseAuth.Provider>
  );
};

export default AuthProvider;
