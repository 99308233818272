


function getCity(location) {

	let context = location.location.context

	let city = context.find((entry) => {
		return entry.id.includes('place')
	})


	return city ? city.text : null;

}

module.exports = {
	getCity

};