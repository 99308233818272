import React, { useEffect } from 'react'
import { injuredOptions } from '../../../data/reportFormOptions'


import { Controller, useWatch } from "react-hook-form";
import { useDispatch } from 'react-redux';
import { updateSightingDetails } from '../../../actions/newSightingActions';

import FieldGroup from '../FieldGroup'
import FormControl from '../../FormControl'

export default function FormFieldInjured({ control }) {
	const watchInjured = useWatch({ control, name: 'injuredWild' })

	const dispatch = useDispatch()


	useEffect(() => {
		dispatch(updateSightingDetails({ injuredWild: watchInjured }));
	}, [watchInjured])

	function InjuredNotice() {
		return <><p className='font-semibold'>
			At this moment we are not tracking injured wild rabbits. </p>
			Please report this injury to your local wildlife rescue or animal welfare organization.
		</>
	}

	return <FieldGroup
		label='Is the rabbit injured?'
		notice={watchInjured === 'true' && <InjuredNotice />}
	>
		{injuredOptions.map((option) => {
			return <Controller
				control={control}
				key={`injuredWild-${option.value}`}
				render={() => (
					<FormControl
						{...option}
						fieldName='injuredWild'
						selected={watchInjured === option.value}
						fieldType='radio' />
				)}
			/>
		})}

		{/* {watchInjured === 'yes' && <p>Please report</p>} */}
	</FieldGroup>
}