import React, { useEffect } from 'react'
import { Controller, useWatch } from "react-hook-form";

//Data
import { rhdvOptions } from '../../../data/reportFormOptions'

//Redux
import { useDispatch } from 'react-redux'
import { updateSightingDetails } from '../../../actions/newSightingActions';

//Components
import FieldGroup from '../FieldGroup'
import FormControl from '../../FormControl'

export default function FormFieldRHDV({ control, visible }) {
	const watchRhdv = useWatch({ control, name: 'wildRhdv' })

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(updateSightingDetails({ rhdv: watchRhdv }));
	}, [watchRhdv])

	function Notice() {
		return <>
			<p className='font-semibold'>Thanks for reaching out to us!</p>
			<p>At this moment we're not tracking wild rabbits unless they are suspected of having contracted RHDV.</p>
		</>
	}


	return <FieldGroup label='Suspected of confirmed case of RHDV'
		notice={watchRhdv === 'no' && <Notice />}>
		{rhdvOptions.map((option) => {
			return <Controller
				control={control}
				key={`wildRhdv-${option.value}`}
				render={() => (
					<FormControl
						{...option}
						fieldName='wildRhdv'
						selected={watchRhdv === option.value}
						fieldType='radio' />
				)}
			/>
		})}
	</FieldGroup>
}