import React, { useEffect } from 'react'
import { speciesOptions } from '../../../data/reportFormOptions'

import FieldGroup from '../FieldGroup'
import FormControl from '../../FormControl'
import { Controller, useWatch } from "react-hook-form";
import { useDispatch } from 'react-redux';

import { updateSightingDetails } from "../../../actions/newSightingActions";

export default function FormFieldSpecies({ control }) {
	const watchSpecies = useWatch({ control, name: 'species' })
	const dispatch = useDispatch()


	useEffect(() => {
		dispatch(updateSightingDetails({ species: watchSpecies }));
	}, [watchSpecies])

	console.log('FormFieldSpecies render')

	return <FieldGroup
		label='Wild or domestic'
		visible={true}
		fieldName={'species'}
		control={control}
	>
		{speciesOptions.map((option) => {
			return <Controller
				control={control}
				key={`species-${option.value}`}
				render={() => (
					<>
						<FormControl
							{...option}
							required={'this is required'}
							fieldName='species'
							selected={watchSpecies === option.value}
							fieldType='radio' />
					</>
				)}
			/>
		})}
	</FieldGroup>
}