import * as actions from '../actions/userActions'

export const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  authedUser: {
    email: null,
    uid: null,
    displayName: null
  },
  newsletterConsentGiven: false

}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.UPDATE_USER_FIRST_NAME:
      return { ...state, firstName: action.payload }
    case actions.UPDATE_USER_LAST_NAME:
      return { ...state, lastName: action.payload }
    case actions.UPDATE_USER_EMAIL:
      return { ...state, email: action.payload }
    case actions.UPDATE_USER_LOCATION:
      return { ...state, location: action.payload }
    case actions.UPDATE_AUTHED_USER:
      return { ...state, authedUser: action.payload }
    case actions.RESET_AUTHED_USER:
      return {
        ...state, authedUser: {
          email: null,
          uid: null,
          displayName: null
        }
      }
    case actions.UPDATE_ALL_USER_DETAILS:
      let data = action.payload
      return { ...state, ...data }
    case actions.UPDATE_USER_DETAILS:
      let key = Object.keys(action.payload)[0]
      let val = action.payload[key]
      return { ...state, [key]: val }
    default:
      return state
  }
}

