import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

import { getLocationFromIPAddress } from "../../helpers/getLocationFromIPAddress";
import { doReverseGeocode } from "../../helpers/doReverseGeocode";

import { updateNewSightingLocation, updateSightingDetails } from "../../actions/newSightingActions";
import { updateAppState } from "../../actions/appStateActions";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AnimatePresence, motion } from 'framer-motion';

import appStateReducer from '../../reducers/appStateReducer';

import FormTabs from './FormTabs/FormTabs';
import FieldGroup from './FieldGroup';
import { FormProvider, useForm } from 'react-hook-form';
import FormFooter from './FormFooter/FormFooter';
import FormFooterButton from './FormFooter/FormFooterButton';
import { useHistory } from 'react-router-dom';

export default function ReportForm() {

	const dispatch = useDispatch();
	const history = useHistory()

	const mapContainer = useRef()
	const geocoderRef = useRef()

	const methods = useForm();

	const [center, setCenter] = useState()
	const [map, setMap] = useState()
	const [geolocationStatus, setGeolocationStatus] = useState('initial')
	const [mapMarkerAdded, setMapMarkerAdded] = useState(false)
	const [resultReturned, setResultReturned] = useState()

	const [locationError, setLocationError] = useState(false)

	//Redux
	const appState = useSelector((state) => state.appState);
	const newSighting = useSelector((state) => state.newSighting);
	const newSightingLocation = useSelector((state) => state.newSighting.location);


	useEffect(() => {

		newSightingLocation && dispatch(updateAppState({ locationPageValid: true }))
		setLocationError(false)

	}, [newSightingLocation])

	useEffect(() => {

		dispatch(updateAppState({ formStep: 'location' }))

	}, [])


	let marker = new mapboxgl.Marker({
		color: "#48c08b",
		draggable: true
	})

	marker.on('dragend', (e) => {
		console.log("DRAGEND")
		let { lng, lat } = marker.getLngLat();

		doReverseGeocode({ lng, lat })
			.then((res) => {
				dispatch(updateNewSightingLocation(res))
				// setInput and query do not work as desired, so had to use this hack
				document.getElementsByClassName('mapboxgl-ctrl-geocoder--input')[0].value = res.place_name
			});
	});


	const handleUseLocationClick = () => {

		setGeolocationStatus('loading')

		const GET_CURRENT_POSITION_OPTIONS = {
			enableHighAccuracy: true,
			timeout: 5000,
			maximumAge: Infinity
		};

		const getCurrentPositionSuccessCallback = (position) => {
			let lat = position.coords.latitude
			let lng = position.coords.longitude

			if (mapMarkerAdded) {
				marker.setLngLat([lng, lat])
			} else {
				marker.setLngLat([lng, lat])
					.addTo(map);
				setMapMarkerAdded(true)
			}

			map.flyTo({
				center: [lng, lat],
				zoom: 10
			})

			doReverseGeocode({ lng, lat })
				.then(response => {
					setGeolocationStatus('success');
					dispatch(updateNewSightingLocation(response));
					setResultReturned(true)
					document.getElementsByClassName('mapboxgl-ctrl-geocoder--input')[0].value = response.place_name

				}).catch((err) => {
					console.log(err);
					// this.props.updateAppState({ reportFormGeolocationStatus: 'error' })
					return err
				});

		};
		const getCurrentPositionErrorCallback = (err) => {
			setGeolocationStatus('error')
			console.warn(`ERROR(${err.code}): ${err.message}`);
		}

		navigator.geolocation.getCurrentPosition(getCurrentPositionSuccessCallback, getCurrentPositionErrorCallback, GET_CURRENT_POSITION_OPTIONS);
	}

	const initializeMap = ({ setMap, mapContainer }) => {
		const map = new mapboxgl.Map({
			accessToken: process.env.REACT_APP_MAPBOX_GL_ACCESS_TOKEN,
			container: mapContainer.current,
			style: "mapbox://styles/mapbox/streets-v11", // stylesheet location
			center: [0, 0],
			zoom: 10
		});
		let nav = new mapboxgl.NavigationControl({
			showCompass: false
		});
		map.addControl(nav, 'top-right')

		const geocoder = new MapboxGeocoder({
			accessToken: process.env.REACT_APP_MAPBOX_GL_ACCESS_TOKEN,
			mapboxgl: mapboxgl,
			marker: false, // Do not use the default marker style
			placeholder: "Enter an address or landmark",
			proximity: center,
			keepOpen: false,
		});

		// if (document.getElementsByClassName('mapboxgl-ctrl-geocoder--input')) {
		// 	document.getElementsByClassName('mapboxgl-ctrl-geocoder--input')[0].setAttribute('autocomplete', 'off')
		// }

		if (!newSighting.location) {
			getLocationFromIPAddress()
				.then(location => {
					map.setCenter(location)
				})
				.catch(err => console.warn(err))
		}

		if (geocoder) {
			geocoderRef.current.appendChild(geocoder.onAdd(map));
		}

		geocoder.on("result", (e) => {
			marker.setLngLat(e.result.geometry.coordinates)
				.addTo(map);
			setMapMarkerAdded(true)
			setGeolocationStatus('initial')
			dispatch(updateNewSightingLocation(e.result))
			// Show RefineLocationNotice after 1 second
			setTimeout(() => {
				setResultReturned(true)
			}, 1000)
		})

		map.on("load", () => {
			setMap(map);
			if (newSighting.location) {
				document.getElementsByClassName('mapboxgl-ctrl-geocoder--input')[0].value = newSighting.location.place_name
				map.setCenter(newSighting.location.geometry.coordinates)
				marker.setLngLat(newSighting.location.geometry.coordinates)
					.addTo(map);
			}
		});



	};


	useEffect(() => {

		if (!map) {
			initializeMap({ setMap, mapContainer });
		}
	}, [map])

	// if (geocoderRef) {
	// 	geocoderRef.current.appendChild(geocoder.onAdd(map));
	// 	// zoomToGeocoder.on('result', () => {
	// 	// 	this.props.updateAppState({ mapSearchOpen: false })
	// 	// })
	// }

	const UseLocationButton = ({ customClasses, label }) => {
		return <button
			disabled={geolocationStatus === 'loading'}
			onClick={() => handleUseLocationClick()}
			className={` text-sm ${customClasses && customClasses} ${geolocationStatus === 'loading' ? 'disabled cursor-not-allowed' : 'hover:underline'}`}>
			{geolocationStatus === 'loading' ? 'Searching' : label}
		</button>
	}

	const RefineLocationNotice = () => {
		const [noticeIsVisible, setNoticeIsVisible] = useState(true)
		if (noticeIsVisible) {
			return (
				<AnimatePresence>
					<motion.div
						initial={{ opacity: 0, y: 100 }}
						animate={{ opacity: 1, y: 0 }}
						exit={{ opacity: 0 }}
						className='absolute bottom-0 p-2 z-1  w-full '>
						<div className='bg-white box-shadow  text-gray-green-90 text-sm w-full rounded-lg py-2 pl-4 pr-14 relative '>
							<span className='font-semibold'>Does this look right?</span><br />
							You can drag the marker to move it to a more accurate location
							<button
								className='absolute right-0 top-0 h-10 w-10 m-2 rounded-lg text-gray-green-90 hover:bg-sage-10 flex items-center justify-center box-shadow bg-white'
								onClick={() => setNoticeIsVisible(false)}><FontAwesomeIcon icon='times' /></button>
						</div>
					</motion.div>
				</AnimatePresence>
			)
		}
		else return null
	}

	const GeolocationError = () => {
		return (
			<div className='bg-red-20 border border-solid border-red-30 text-red-90 text-sm rounded p-3 mb-3'>
				<p className=' font-semibold'>We couldn't find your location</p>
				<p>You can try searching with the box below or you can <UseLocationButton customClasses='underline hover:text-red-100' label='try again' /></p>
			</div>
		)
	}

	function handleTextareaBlur(e) {
		if (e.target.value !== newSighting.location) {
			dispatch(updateSightingDetails({ locationNote: e.target.value }))
		}
	}

	const showValidationError = () => {
		return (appState.showValidationErrors && !newSightingLocation);
	}

	function step2IsDisabled() {
		return !newSighting.location ? true : false;
	}

	function step3IsDisabled() {
		if (newSighting.count && newSighting.count >= 1 && newSighting.sightingDate) {
			return false
		} else {
			return true
		}
	}

	let newShowValidationError = false

	function handleClickNext() {
		if (!newSightingLocation) {
			console.log('set newShowValidAtionerror')
			setLocationError(true)
		} else {
			history.push('/report/details')
		}
	}

	function nextButtonIsEnabled() {
		if (!newSightingLocation) return false;
		return true
	}



	return (
		<div className=''>

			<div className="report-map mb-4" ref={mapContainer}>
				{resultReturned && <RefineLocationNotice />}
			</div>
			{geolocationStatus === 'error' && <GeolocationError />}

			<FormProvider {...methods} >

				<FieldGroup
					label='Where did you see the rabbit?'
					labelActionComponent={<UseLocationButton customClasses='ml-auto text-gray-70 hidden md:flex' label='Use current location' />}
					customError={locationError ? 'Location is required' : null}
				>
					<form autoComplete="off" >
						<div className='relative flex justify-items-stretch items-center '>
							<div id="report-form-geocoder" className={`${showValidationError() && 'hasError'} w-full `} ref={geocoderRef}>
							</div>
						</div>
					</form>
					<UseLocationButton
						customClasses='md:hidden text-gray-70 h-12 w-full mt-2 text-center text-gray-green-70 rounded-lg  p-3 bg-white  border border-solid border-sage-10 ' label='Use current location' />

				</FieldGroup>

				<FieldGroup label={'Location notes (optional)'}>
					<span className='form-field__helper-text'>Is there anything else we should know about the location?</span>
					<textarea className='form-field__text-area' onBlur={(e) => handleTextareaBlur(e)} />
				</FieldGroup>

				<FormFooter
					nextButton={<FormFooterButton enabled={nextButtonIsEnabled()} label='Next' clickFn={() => handleClickNext()} />}
				/>
			</FormProvider>
		</div >
	)


} 