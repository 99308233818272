import React, { useState, useEffect } from "react";
// import './App.css';

import algoliasearch from 'algoliasearch/lite';
import { useSelector, useDispatch } from "react-redux";
import { InstantSearch, RefinementList, ClearRefinements, Highlight, Configure, SearchBox, Hits, connectHighlight, Pagination, InfiniteHits, connectStateResults } from 'react-instantsearch-dom';
import { Link, useHistory } from "react-router-dom";
import DisplayStatusTag from './DisplayStatusTag';
import { updateAppState } from "../actions/appStateActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const searchClient = algoliasearch('9D29FPP2U5', 'c9c829299dd73a7a6a95ace4f527d354');


function AlgoliaSearch() {

	const dispatch = useDispatch()
	const history = useHistory()


	const algSearchState = useSelector(
		(state) => state.appState.algoliaSearchState
	);


	function handleSearchStateChange(state) {
		dispatch(updateAppState({ algoliaSearchState: state }))
	}






	const Results = connectStateResults(({ searchState }) => {
		if (algSearchState && algSearchState.query) {
			return <>
				<InfiniteHits hitComponent={Hit} />
			</>
		} else {
			return null
		}
	})


	const Hit = ({ hit }) => {
		const handleHitClick = (e, id) => {
			e.preventDefault();
			history.push(`/sighting/${id}`);
			dispatch(updateAppState({ algoliaSearchState: null }))
		}
		return (
			<>
				<a onClick={(e) => handleHitClick(e, hit.objectID)} href={`/sighting/${hit.objectID}`} key={hit.objectID} >
					<div className='font-semibold'><CustomHighlight attribute="properties.title" hit={hit} /></div>
					<CustomHighlight attribute="location.place_name" hit={hit} />
					<CustomHighlight attribute="properties.location_note" hit={hit} />

					<DisplayStatusTag status={hit.properties.status} tagSize='small' />
				</a>

			</>
		)
	};


	const CustomHighlight = connectHighlight(({ highlight, attribute, hit }) => {
		const titleHit = highlight({
			highlightProperty: '_highlightResult',
			attribute,
			hit
		});



		return (
			<div className='' key={hit.id} >

				<h3 className='text-sm'>{titleHit.map(
					part => part.isHighlighted ? <mark>{part.value}</mark> : part.value
				)}</h3>


				{/* <p className='text-xs'>{hit.location.place_name}</p> */}

			</div>
		);
	});



	return (
		<div className='algolia-search relative hidden lg:flex'>

			<InstantSearch
				searchClient={searchClient}
				indexName="sightings"
				onSearchStateChange={(state) => handleSearchStateChange(state)}
			>
				<div className='relative'>
					<SearchBox submit={null} translations={{
						submitTitle: 'Search for a sighting.',
						resetTitle: 'Clear your search query.',
						placeholder: 'Search for a sighting',
					}} />
					<div className='absolute top-2 left-3 text-gray-green-60'>
						<FontAwesomeIcon icon='search' size='sm' />
					</div>
				</div>
				<Configure hitsPerPage={6} />


				{/* <Hits hitComponent={Hit} /> */}
				<div className='algolia-dropdown shadow'>
					<Results />
					{/* <Pagination /> */}
				</div>

				{/* <Configure hitsPerPage={5} /> */}
				{/* <Configure
					filters="properties.status:approved"
					hitsPerPage={4}
					analytics={false}
					enablePersonalization={true}
					distinct
				/> */}
				{/* <SearchBox translations={{
					submitTitle: 'Submit your search query.',
					resetTitle: 'Clear your search query.',
					placeholder: 'Search for a sighting',
				}} /> */}
				{/* <Autocomplete
					onSuggestionSelected={this.onSuggestionSelected}
					onSuggestionCleared={this.onSuggestionCleared}
				/> */}
				{/* <Hits hitComponent={Hit} /> */}


				{/* <Results /> */}


			</InstantSearch>

		</div>
	)







}




// function Hit(props) {
// 	console.log('props from hit component')
// 	console.log({props})
// 	return (
// 		<div>
// 			<div className="hit-name">
// 				{/* <Highlight attribute="properties.title" hit={props.hit.properties.title} /> */}
// 				<CustomHighlight attribute="properties.title" hit={props.hit} />
// 			</div>
// 			{/* <div className="hit-description">
// 				<Highlight attribute="description" hit={props.hit} />
// 			</div> */}
// 		</div>
// 	);
// }




export default AlgoliaSearch;