
import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import './FormFooter.scss'

const FormFooter = ({ nextButton, prevButton }) => {

	return <div className='flex'>
		<div className='w-1/2 flex'>
			{prevButton && prevButton}
		</div>
		<div className='w-1/2 flex justify-end'>
			{nextButton && nextButton}
		</div>
	</div>
}
export default FormFooter