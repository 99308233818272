import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import AdminNav from "./AdminNav";
import PageHeader from "../PageHeader";
import { updateSightingDetails } from "../../actions/newSightingActions";

import FormMapDetails from "../ReportForm/FormMapDetails";
import FormContactDetails from "../ReportForm/FormContactDetails";
import FormSightingDetails from "../ReportForm/FormSightingDetails";

const NewSighting = (props) => {
  const dispatch = useDispatch();
  const [errorAppearance, setErrorAppearance] = useState({
    errorAppearance: null,
  });

  const appearance = useSelector((state) => state.newSighting.appearance);

  const handleAppearanceChange = (e) => {
    dispatch(updateSightingDetails({ appearance: e.target.value }));
  };

  const validateAppearance = (fieldValue) => {
    let error;
    if (!fieldValue) {
      console.log(" error on appearance");
      error = "Tell us what the rabbit looked like";
      setErrorAppearance({ errorAppearance: error });
    } else {
      console.log("no error on appearance");
      setErrorAppearance({ errorAppearance: null });
    }
  };



  return (
    <div className="admin-page">
      <AdminNav>
        <Link to="/admin">All sightings</Link>
      </AdminNav>
      <PageHeader title='Add a sighting' />

      <div className="container py-8">

        {/* <FormMapDetails /> */}
        <FormContactDetails />
        <FormSightingDetails />

      </div>
    </div>
  );
};

export default NewSighting;
