export const speciesOptions = [
	{
		label: 'Domestic',
		value: 'domestic'
	},
	{
		label: 'Wild',
		value: 'wild'
	}
]
export const injuredOptions = [
	{
		label: 'Yes',
		value: 'true'
	},
	{
		label: 'No',
		value: 'false'
	}
]
export const rhdvOptions = [
	{
		label: 'Yes',
		value: 'yes'
	},
	{
		label: 'No',
		value: 'no'
	}
]

export const flagOptions = [
	{ value: "Babies present", label: "Babies present" },
	{ value: "injured", label: "Injured" },
	{ value: "rhdv-suspected", label: "Suspected RHDV" },
	{ value: "rhdv-confirmed", label: "Confirmed RHDV" },
	{ value: "recent", label: "Recent Abandonment" },
	{ value: "feral", label: "Feral" },
]

export const statusOptions = [
	{
		value: "general",
		label: "General"
	},
	{
		value: "urgent",
		label: "Urgent"
	},
	{
		value: "rescued",
		label: "Rescued"
	},
	{
		value: "deceased",
		label: "Deceased"
	}
]