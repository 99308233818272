import React, { useEffect, useState, useRef } from "react";

import { formatDate } from "../helpers/formatDate";

import DisplaySightingTags from "./DisplaySightingTags";
import DisplayStatusTag from "./DisplayStatusTag";

import Moment from 'react-moment';

const SightingMapPopUp = (props) => {


	return (
		<div className='sighting-map-pop-up w-full max-w-xs'>

			<div className='p-3 text-gray-70 font-semibold text-xs'>
				<p className='text-xs'><Moment format='LL'>{props.sighting.properties.sightingDate}</Moment></p>
				<h5 className='text-green-80 serif leading-none text-lg font-semibold'>{props.sighting.properties.title !== undefined && props.sighting.properties.title}</h5>
				<div className='flex flex-wrap mt-3'>
					<DisplayStatusTag
						status={props.sighting.properties.status}
						tagSize='small'
					/>
					<DisplaySightingTags
						count={props.sighting.properties.count}
						flags={JSON.parse(props.sighting.properties.flags)}
						images={JSON.parse(props.sighting.properties.images)}
						tagSize='small'
					/>
				</div>

			</div>
		</div >
	)
};

export default SightingMapPopUp;
