import * as actions from "../actions/adminActions";

export const initialState = {
  sightings: [],
  singleSighting: null,
  updatedSighting: null,
  user: {},
  adminEmail: null
};

export default function adminReducer(state = initialState, action) {
  switch (action.type) {
    case actions.ADD_SIGHTINGS:
      return {
        ...state,
        sightings: action.payload,
      };
    case actions.ADD_PENDING_SIGHTINGS:
      return {
        ...state,
        pendingSightings: action.payload,
      };
    case actions.ADD_ARCHIVED_SIGHTINGS:
      return {
        ...state,
        archivedSightings: action.payload,
      };
    case actions.ADD_APPROVED_SIGHTINGS:
      return {
        ...state,
        approvedSightings: action.payload,
      };
    case actions.ADD_ADMIN_EMAIL:
      return {
        ...state,
        adminEmail: action.payload,
      };
    case actions.ADD_SINGLE_SIGHTING:
      return {
        ...state,
        singleSighting: action.payload,
        updatedSighting: action.payload,
      };
    case actions.ADD_USER_DATA:
      return {
        ...state,
        user: action.payload,
      };
    case actions.UPDATE_SIGHTING:
      return {
        ...state,
        updatedSighting: action.payload,
      };

    default:
      return state;
  }
}
