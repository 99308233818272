let { doFilterCount } = require('./doFilterCount');
let { doFilterDate } = require('./doFilterDate');
let { doFilterSpecies } = require('./doFilterSpecies');
let { doFilterStatus } = require('./doFilterStatus');
let { doFilterFlags } = require('./doFilterFlags');

function doCombineFilters(name, value, originalUnfilteredSightings, appState) {
	let { filterSpecies, filterCount, filterDate, filterStatus, filterFlags } = appState;
	let filteredSightings = originalUnfilteredSightings;

	console.log('doCombineFIlters')
	console.log(originalUnfilteredSightings.length)

	switch (name) {
		case "filterStatus":
			filteredSightings = doFilterStatus(
				originalUnfilteredSightings,
				value
			);
			filteredSightings = doFilterCount(filteredSightings, filterCount);
			filteredSightings = doFilterDate(filteredSightings, filterDate);
			filteredSightings = doFilterFlags(filteredSightings, filterFlags);
			break;
		case "filterSpecies":
			filteredSightings = doFilterSpecies(originalUnfilteredSightings, value);
			filteredSightings = doFilterCount(filteredSightings, filterCount);
			filteredSightings = doFilterDate(filteredSightings, filterDate);
			filteredSightings = doFilterFlags(filteredSightings, filterFlags);

			break;
		case "filterCount":
			filteredSightings = doFilterCount(originalUnfilteredSightings, value);
			filteredSightings = doFilterSpecies(filteredSightings, filterSpecies);
			filteredSightings = doFilterStatus(filteredSightings, filterStatus);
			filteredSightings = doFilterDate(filteredSightings, filterDate);
			filteredSightings = doFilterFlags(filteredSightings, filterFlags);

			break;
		case "filterDate":
			filteredSightings = doFilterDate(originalUnfilteredSightings, value);
			filteredSightings = doFilterSpecies(filteredSightings, filterSpecies);
			filteredSightings = doFilterStatus(filteredSightings, filterStatus);
			filteredSightings = doFilterCount(filteredSightings, filterCount);
			filteredSightings = doFilterFlags(filteredSightings, filterFlags);

			break;
		case "filterFlags":
			filteredSightings = doFilterFlags(originalUnfilteredSightings, value);
			filteredSightings = doFilterDate(filteredSightings, filterDate);
			filteredSightings = doFilterSpecies(filteredSightings, filterSpecies);
			filteredSightings = doFilterStatus(filteredSightings, filterStatus);
			filteredSightings = doFilterCount(filteredSightings, filterCount);
			break;
		default:
			break;
	}

	console.log(filteredSightings.length)
	return filteredSightings;
};

module.exports = { doCombineFilters };