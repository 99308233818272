import * as actions from "../actions/newSightingActions";

export const initialState = {

  count: null,
  appearance: "",
  notes: "",
  rhdv: null,
  sightingDate: null,
  species: "domestic",
  injuredWild: null,
  status: "general",
  flags: [],
  categories: [],
  images: [],
  location: null,
  locationFieldsValid: false,
  detailsFieldsValid: false,
  userDetailsValid: false
};



export default function newSightingReducer(state = initialState, action) {
  switch (action.type) {

    case actions.TESTING:
      return { ...state, formStep: action.payload };
    case actions.CLEAR_NEW_SIGHTING_DATA:
      return initialState
    case actions.ADD_GEOLOCATE_COORDINATES:
      return { ...state, newSighting: action.payload };
    case actions.UPDATE_LOCATION:
      return { ...state, location: action.payload };
    case actions.UPDATE_NEW_SIGHTING_LOCATION:
      return { ...state, location: action.payload };
    case actions.ADD_REVERSE_GEOCODE_DETAILS:
      return { ...state, location: action.payload.features[0] };
    case actions.UPDATE_SIGHTING_NUMBER_OF_RABBITS:
      return { ...state, count: action.payload };
    case actions.UPDATE_SIGHTING_APPEARANCE:
      return { ...state, appearance: action.payload };
    case actions.UPDATE_SIGHTING_DATE:
      return { ...state, sightingDate: action.payload };
    case actions.UPDATE_SIGHTING_IMAGES:
      return { ...state, images: [...state.images, action.payload] };
    case actions.UPDATE_SIGHTING_FLAGS:
      let currentFlags = state.flags;

      if (currentFlags.includes(action.payload)) {
        currentFlags = currentFlags.filter((item) => item !== action.payload);
        return {
          ...state,
          flags: currentFlags,
        };
      } else {
        return {
          ...state,
          flags: [...state.flags, action.payload],
        };
      }
    case actions.UPDATE_SIGHTING_CATEGORIES:
      let currentCategories = state.categories;

      if (currentCategories.includes(action.payload)) {
        currentCategories = currentCategories.filter(
          (item) => item !== action.payload
        );
        return {
          ...state,
          categories: currentCategories,
        };
      } else {
        return {
          ...state,
          categories: [...state.categories, action.payload],
        };
      }
    case actions.UPDATE_SIGHTING_DETAILS:
      let key = Object.keys(action.payload)[0];
      let val = action.payload[key];

      return {
        ...state, [key]: val,

      };
    default:
      return state;
  }
}
