import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";


import Moment from "react-moment";

import DisplaySightingTags from '../DisplaySightingTags';
import DisplayStatusTag from '../DisplayStatusTag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { db } from "../../firebase"; // add


export default function SightingListItem(props) {
	let id = props.data.id;
	const authedUser = useSelector((state) => state.user.authedUser);


	function deleteSighting(id) {
		console.log(`delete: ${id}`)
		if (window.confirm("Delete this sighting?")) {
			db.collection("sightings").doc(id).delete().then(() => {
				console.log("Document successfully deleted!");
			}).catch((error) => {
				console.error("Error removing document: ", error);
			});
		}
	}

	function isSuperAdmin() {
		if ((authedUser && authedUser.email)) {
			if (authedUser.email === 'josephcoombes@me.com' || authedUser.email === 'sasharink@me.com') {
				return true
			}
		}
		else {
			return false
		}
	}

	return (
		<div className="admin-sighting-row flex">
			<div className='w-1/4 flex flex-row px-4'>
				<Link
					to={{
						pathname: "/admin/sighting/" + id,
						state: {
							test: true,
						},
					}}
				>
					<h3 className='text-base text-green-90 font-semibold'>{props.data.properties.title}</h3>
				</Link>
			</div>
			<div className="w-1/6 flex px-4">
				<Moment className='text-xs' unix format="LL">{props.data.properties.sightingDate / 1000}</Moment>
			</div>
			<div className="w-1/2 flex px-4">
				<DisplayStatusTag
					status={props.data.properties.status}
					tagSize='small'

				/>
				<DisplaySightingTags
					count={props.data.properties.count}
					flags={props.data.properties.flags}
					images={props.data.properties.images}
					tagSize='small'
				/>

			</div>

			<div className="w-1/6 ml-auto flex px-4">
				<select
					defaultValue={props.data.status}
					onChange={(e) => props.handleStatusChange(id, e.target.value)}
					name="status"
					className="filter-select ml-auto"
				>
					<option value="approved">Approved</option>
					<option value="pending">Pending</option>
					<option value="archived">Archived</option>

				</select>
				{isSuperAdmin() &&
					<button className='flex h-10 w-10 text-xs border border-sage-10 border-solid text-gray-60 items-center justify-center' onClick={() => deleteSighting(props.data.id)}><FontAwesomeIcon icon='trash' /></button>
				}
			</div>

		</div>
	);
};
