import React, {useContext} from "react";

import { useSelector, useDispatch } from "react-redux";
import { updateAppState } from "../actions/appStateActions";
import { firebaseAuth } from "../AuthProvider";

import { Link } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";

import Logo from './Logo';
import AlgoliaSearch from './AlgoliaSearch';


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NavBar = (props) => {
	const dispatch = useDispatch();
	const params = useParams();

	const location = useLocation();
	// console.log(location.pathname);

	const { handleSignout } = useContext(firebaseAuth);


	let mobileNavIsOpen = useSelector(
		(state) => state.appState.mobileNavIsOpen
	);

	let navIsOpen = useSelector(
		(state) => state.appState.navIsOpen
	);

	const handleMobileNavToggle = () => {
		dispatch(updateAppState({ mobileNavIsOpen: !mobileNavIsOpen }))
	}

	const BUTTON_CLASSES = 'h-12 flex px-4 items-center rounded-md font-semibold bg-gray-green-10 hover:bg-gray-green-30 text-gray-green-90 mr-4'


	function TopLevelLinks() {
		return (
			<div className='ml-auto hidden xl:flex items-center'>
				{props.isAuthed ? 
				<button onClick={handleSignout} className={BUTTON_CLASSES}>Sign out</button> : 
				<Link to="/admin" className={BUTTON_CLASSES}>
					Sign-in
				</Link>}
				
				
				<Link to="/report" className=' h-12 flex px-4 items-center rounded-md font-semibold bg-gray-green-90 text-white'>
					Report sighting
				</Link>
			</div>
		)
	}


	return (
		<>
			<header className={`mobile-navbar border-b border-solid border-sage-10 text-green-90`}>
				<div className='md:w-72 px-4 xl:hidden'>
					<Logo />
				</div>
				<div className='flex px-4 w-full'>
					<AlgoliaSearch />
					<TopLevelLinks />
					<button onClick={handleMobileNavToggle} className='h-16 w-16 ml-auto -mr-4 xl:hidden flex items-center justify-center text-green-80'>
						{mobileNavIsOpen ? <FontAwesomeIcon icon='times' /> : <FontAwesomeIcon icon='bars' />}
					</button>
				</div>



			</header>
			{mobileNavIsOpen && <div onClick={handleMobileNavToggle} className='opacity-30 cursor-pointer bg-gray-green-80 fixed top-0 left-0 w-full h-full block z-10'></div>}
		</>
	);
};

export default NavBar;
