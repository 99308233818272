import React from 'react';

import { useSelector, useDispatch } from "react-redux";

import { updateAppState } from "../actions/appStateActions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { labelize, capitalize } from "../helpers/labelize";
import { createCountLabel } from "../helpers/createCountLabel";


const CurrentFilterTags = ({ handleFilter, setClusterColor, addOrRemoveFlags, updateClusters }) => {

	const appState = useSelector((state) => state.appState);
	const dispatch = useDispatch();


	function handleFilterClear(name, value) {


		if (name === 'filterStatus') {
			dispatch(updateAppState({ filterStatus: 'all' }))
			updateClusters && setClusterColor('#3d9b83', 'rgba(68,184,132,0.5)')
			handleFilter(name, 'all');
		}
		else if (name === 'filterDate') {
			dispatch(updateAppState({ filterDate: 'all-time' }))
			handleFilter(name, 'all-time');
		}
		else if (name === 'filterCount') {
			dispatch(updateAppState({ filterCount: 1000 }))
			handleFilter(name, 1000);
		}
		else if (name === 'filterFlags') {
			dispatch(updateAppState({ filterFlags: addOrRemoveFlags(value) }))
			handleFilter(name, addOrRemoveFlags(value));
		}

	}

	function FilterTag(props) {

		return (
			<button className={`filter-tag-button`} onClick={(e) => handleFilterClear(props.type, props.value)}>
				<div className={`filter-tag filter-tag--${props.type} filter-tag--${appState.filterStatus}`}>
					{props.type === 'filterStatus' && <span className={`status-marker mr-1 `}></span>}
					<span className='mr-1 leading-none'> {
						labelize(props.label)
					}</span>
					<FontAwesomeIcon icon='times-circle' />
				</div>
			</button>
		)

	}

	const FlagFilterTags = () => {
		if (appState.filterFlags.length > 0) {
			return (
				appState.filterFlags.map((filter) => {
					return (<FilterTag type='filterFlags' value={filter} label={labelize(filter)} />)
				})
			)
		} else {
			return null
		}
	}

	return (
		<>
			{appState.filterDate !== 'all-time' && <FilterTag type='filterDate' value={appState.filterDate} label={labelize(appState.filterDate)} />}
			{appState.filterStatus !== 'all' && <FilterTag type='filterStatus' value={appState.filterStatus} label={labelize(appState.filterStatus)} />}
			{parseInt(appState.filterCount) !== 1000 && <FilterTag type='filterCount' value={appState.filterCount} label={createCountLabel(appState.filterCount)} />}
			<FlagFilterTags />
		</>
	)

}

export default CurrentFilterTags