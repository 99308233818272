function createCountLabel(count) {
	count = parseInt(count)
	let label
	if (count === 1) {
		label = 'Individuals'
	} else if (count > 1 && count <= 10) {
		label = 'Small groups'
	}
	else if (count >= 11 && count < 1000) {
		label = 'Colonies'
	}
	else {
		label = 'Any'
	}
	return label;
}

module.exports = { createCountLabel };
