function getFullDataFromSightingsInView(sightingsInViewport, allSightings) {
	console.log('getFullDataFromSightingsInView()')
	let fullDataOfSightingsInViewport = []
	sightingsInViewport.forEach((sighting) =>{
		let match = allSightings.find(s => s.id === sighting.properties.id);
		fullDataOfSightingsInViewport.push(match)
	});
	return fullDataOfSightingsInViewport
}

module.exports = { getFullDataFromSightingsInView };
