import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { useHistory } from 'react-router-dom'

import { updateUserDetails, updateAllUserDetails } from "../../actions/userActions";
import { updateAppState } from "../../actions/appStateActions";

import { clearNewSightingData } from "../../actions/newSightingActions";
// Helpers
import constructSightingTitle from '../../helpers/constructSightingTitle'

import { debounce } from 'lodash';

import { db, fb } from "../../firebase";

import Input from "./Input";
import FormFooterButton from "./FormFooter/FormFooterButton";
import FieldGroup from "./FieldGroup";
import FormFooter from "./FormFooter/FormFooter";

const FormContactDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory()
  const authedUser = useSelector((state) => state.user.authedUser);
  const storeUser = useSelector((state) => state.user);

  const formStep = useSelector((state) => state.user.formStep);
  const appState = useSelector((state) => state.appState);


  const methods = useForm();
  const { getValues, control } = methods;

  const watchFirstName = useWatch({ control, name: 'firstName' })
  const watchLastName = useWatch({ control, name: 'lastName' })
  const watchPhone = useWatch({ control, name: 'phone' })
  const watchEmail = useWatch({ control, name: 'email' })

  const storeCount = useSelector((state) => state.newSighting.count);
  const storeSpecies = useSelector((state) => state.newSighting.species);
  const storeAppearance = useSelector((state) => state.newSighting.appearance);
  const storeFlags = useSelector((state) => state.newSighting.flags);
  const storeSightingDate = useSelector((state) => state.newSighting.sightingDate);
  const storeNotes = useSelector((state) => state.newSighting.notes);
  const storeRhdv = useSelector((state) => state.newSighting.rhdv);
  const storeImages = useSelector((state) => state.newSighting.images);
  const storeInjuredWild = useSelector((state) => state.newSighting.injuredWild);
  const storeStatus = useSelector((state) => state.newSighting.status);
  const storeLocation = useSelector((state) => state.newSighting.location);
  const storeLocationNote = useSelector((state) => state.newSighting.locationNote);


  // Redirect to Location page if location is missing, or redirect to details page if values are missing
  useEffect(() => {
    if (!storeLocation) {
      history.push(`/report/location`)
    } else if (!storeCount || !storeSpecies || !storeStatus || !storeSightingDate) {
      history.push(`/report/details`)
    }
  }, [])


  // If no location data, redirect to location page
  // POSSIBLY RE ADD THIS
  // useEffect(() => {
  //   if (!appState.detailsPageValid) {
  //     dispatch(updateAppState({ formStep: 'details' }))
  //     history.push(`/report/details`)
  //   } else {
  //     dispatch(updateAppState({ formStep: 'contact' }));
  //   }
  // }, [])

  function saveFieldsToStore() {
    dispatch(updateUserDetails({ email: watchEmail }));
    dispatch(updateUserDetails({ phone: watchPhone }));
    dispatch(updateUserDetails({ firstName: watchFirstName }));
    dispatch(updateUserDetails({ lastName: watchLastName }));
  }


  // This seems repetative, but a single debouncedUpdateState function didn't work with autocomplete

  const debouncedUpdateFirstName = useCallback(
    debounce((value) => {
      dispatch(updateUserDetails({ firstName: value }));
    }, 500),
    []
  );
  const debouncedUpdateLastName = useCallback(
    debounce((value) => {
      dispatch(updateUserDetails({ lastName: value }));
    }, 500),
    []
  );
  const debouncedUpdateEmail = useCallback(
    debounce((value) => {
      dispatch(updateUserDetails({ email: value }));
    }, 500),
    []
  );
  const debouncedUpdatePhone = useCallback(
    debounce((value) => {
      dispatch(updateUserDetails({ phone: value }));
    }, 500),
    []
  );


  // Send form input values to Redux store
  useEffect(() => {
    watchFirstName && watchFirstName.length > 1 && debouncedUpdateFirstName(watchFirstName)
  }, [watchFirstName]);

  useEffect(() => {
    watchLastName && watchLastName.length > 1 && debouncedUpdateLastName(watchFirstName)
  }, [watchLastName]);

  useEffect(() => {
    watchPhone && watchPhone.length > 6 && debouncedUpdatePhone(watchPhone)
  }, [watchPhone]);

  useEffect(() => {
    watchEmail && watchEmail.length > 5 && debouncedUpdateEmail(watchEmail)
  }, [watchEmail]);



  const firebaseStatus = useSelector(
    (state) => state.appState.firebaseStatus
  );

  const handleSuccess = () => {
    // fb.auth().signOut();
    dispatch(clearNewSightingData())
    dispatch(updateAppState({ firebaseStatus: "success" }));
    history.push('/thank-you')
  };



  const handleFireBaseSubmit = () => {
    console.log('handleSubmit')
    console.log(`appState.contactPageValid: ${appState.contactPageValid}`)

    if (!storeLocation) {
      dispatch(updateAppState({ showValidationErrors: true }))
      return false
    }
    let userDocRef;
    let userID;

    dispatch(updateAppState({ firebaseStatus: "submitting" }));
    let now = new Date().toISOString();

    const createSightingDoc = (userDocRef) => {
      console.log('createSightingDoc')
      db.collection("sightings")
        .add({
          status: "pending",
          created: now,
          userRef: userDocRef,
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: storeLocation.geometry
              .coordinates,
          },
          location: storeLocation,
          properties: {
            title: constructSightingTitle(
              storeCount,
              storeLocation.text
            ),
            count: Number(storeCount),
            appearance: storeAppearance,
            sightingDate: storeSightingDate,
            images: storeImages,
            species: storeSpecies,
            flags: storeFlags,
            status: storeStatus,
            locationNote: storeLocationNote ? storeLocationNote : '',
            notes: storeNotes
            // categories: newSighting.categories,
          },
        })
        .then((docRef) => {
          db.collection("sightings")
            .doc(docRef.id)
            .update({ id: docRef.id });
          console.log("Doc created: ", docRef.id);
        })
        .catch((error) => console.error("Error adding document: ", error))
        .then(handleSuccess());
    }

    const createAnonUserDoc = (anonUID) => {
      console.log('createUserDoc')
      // First create user
      db.collection("users")
        .add({
          firstName: storeUser.firstName,
          lastName: storeUser.lastName,
          email: storeUser.email,
          phone: storeUser.phone,
          newsletterConsentGiven: storeUser.newsletterConsentGiven,
          anonUID: anonUID
        })
        .then((docRef) => {
          userDocRef = docRef.id;
          console.log("UserDoc created: ", userDocRef);
          return userDocRef;
        })
        .then((userDocRef) => {
          createSightingDoc(userDocRef)
        })
        .catch((error) => console.error("Error adding document: ", error));
    }


    const sendToFirebase = () => {
      let now = Date.now();

      let currentUser = fb.auth().currentUser

      fb.auth().signInAnonymously()
        .then(() => {
          console.log('signed in anonymously')
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log(errorCode)
          console.log(errorMessage)
        });

      if (authedUser && authedUser.uid) {

        console.log('ALREADY SIGNED IN, DONT CREATE USER DOC')
        createSightingDoc(authedUser.uid)

      } else {

        console.log('create anon user')

        fb.auth().onAuthStateChanged(function (anonUser) {
          if (anonUser) {
            createAnonUserDoc(anonUser.uid);
          }
        });

      }

    };

    sendToFirebase();
  };

  const hasRequiredValues = () => {
    if (storeLocation
      && storeCount
      && storeSightingDate
      && storeSpecies
      && storeStatus
      && storeUser.firstName
      && storeUser.lastName
      && storeUser.email
      && storeUser.phone) {
      return true
    } else {
      return false
    }
  }
  const handleFormSubmit = (data) => {
    //Trigger validation
    methods.trigger()

    if (hasRequiredValues()) {
      handleFireBaseSubmit()
    }
  };


  return (
    <FormProvider {...methods} >
      <p className='text-gray-green-80 text-base mb-12 mt-8 max-w-3/4'>In the event that we need to ask questions about your report, we kindly request that you share some contact information with us. <span className='font-semibold'>Your personal details will not be shared or shown publicly.</span></p>

      {/* <DevTool control={control} /> */}
      <FieldGroup label='First name' control={control} fieldName={'firstName'}>
        <Input
          name='firstName'
          requiredMessage={'First name is required'}
          defaultValue={storeUser.firstName}
          autoComplete="off"
        />
      </FieldGroup>
      <FieldGroup label='Last name' control={control} fieldName={'lastName'}>
        <Input
          name='lastName'
          requiredMessage={'Last name is required'}
          defaultValue={storeUser.lastName}
          autoComplete="off"
        />
      </FieldGroup>

      <FieldGroup label='Email' control={control} fieldName={'email'}>
        <Input
          name='email'
          requiredMessage={'Email is required'}
          type='email'
          defaultValue={storeUser.email}
          autoComplete="off"
        />
      </FieldGroup>
      <FieldGroup label='Phone number' control={control} fieldName={'phone'}>
        <Input
          requiredMessage={'Phone number is required'}
          name='phone'
          type='tel'
          defaultValue={storeUser.phone}
          autoComplete="off"
        /></FieldGroup>

      <FormFooter
        prevButton={<FormFooterButton label='Back' clickFn={() => history.push('/report/details')} />}
        nextButton={<FormFooterButton enabled={hasRequiredValues()} label='Submit' clickFn={() => handleFormSubmit()} />}
      />
    </FormProvider>
  );
};



export default FormContactDetails
