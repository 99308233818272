const constructSightingTitle = (numberOfRabbits, location, species) => {
	let sentence, partOne;
	if (numberOfRabbits === 1) {
		partOne = 'Single rabbit'
	}
	else if (numberOfRabbits === 2) {
		partOne = 'Pair of rabbits'
	} else if (numberOfRabbits > 2 && numberOfRabbits < 9) {
		partOne = 'Small group of rabbits'
	} else if (numberOfRabbits >= 10 && numberOfRabbits < 49) {
		partOne = 'Colony of rabbits'
	} else if (numberOfRabbits > 50) {
		partOne = 'Large colony of rabbits'
	} else {
		partOne = 'Sighting'
	}

	sentence = `${partOne} near ${location}`;
	return sentence;
};

export default constructSightingTitle