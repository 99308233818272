// Create Redux action types
// export const GET_POSTS = 'GET_POSTS'
// export const GET_POSTS_SUCCESS = 'GET_POSTS_SUCCESS'
// export const GET_POSTS_FAILURE = 'GET_POSTS_FAILURE'
export const TESTING = "TESTING";

export const ADD_REVERSE_GEOCODE_DETAILS = "ADD_REVERSE_GEOCODE_DETAILS";
export const ADD_GEOLOCATE_COORDINATES = "ADD_GEOLOCATE_COORDINATES";
export const UPDATE_FORM_STEP = "UPDATE_FORM_STEP";
export const UPDATE_SIGHTING_DETAILS = "UPDATE_SIGHTING_DETAILS";
export const UPDATE_SIGHTING_NUMBER_OF_RABBITS =
  "UPDATE_SIGHTING_NUMBER_OF_RABBITS";
export const UPDATE_SIGHTING_APPEARANCE = "UPDATE_SIGHTING_APPEARANCE";
export const UPDATE_SIGHTING_DATE = "UPDATE_SIGHTING_DATE";
export const UPDATE_SIGHTING_FLAGS = "UPDATE_SIGHTING_FLAGS";
export const UPDATE_SIGHTING_CATEGORIES = "UPDATE_SIGHTING_CATEGORIES";
export const UPDATE_SIGHTING_IMAGES = "UPDATE_SIGHTING_IMAGES";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const CLEAR_NEW_SIGHTING_DATA = "CLEAR_NEW_SIGHTING_DATA";

export const UPDATE_NEW_SIGHTING_LOCATION = "UPDATE_NEW_SIGHTING_LOCATION";

export const testing = (newSighting) => ({
  type: TESTING,
  payload: newSighting,
});

export const clearNewSightingData = (newSighting) => ({
  type: CLEAR_NEW_SIGHTING_DATA
});

export const updateFormStep = (newSighting) => ({
  type: TESTING,
  payload: newSighting,
});

export const updateSightingDetails = (newSighting) => ({
  type: UPDATE_SIGHTING_DETAILS,
  payload: newSighting,
});

export const location = (newSighting) => ({
  type: UPDATE_LOCATION,
  payload: newSighting,
});

export const updateNewSightingLocation = (newSighting) => ({
  type: UPDATE_NEW_SIGHTING_LOCATION,
  payload: newSighting,
});

export const updateSightingNumberOfRabbits = (numberOfRabbits) => ({
  type: UPDATE_SIGHTING_NUMBER_OF_RABBITS,
  payload: numberOfRabbits,
});

export const updateSightingAppearance = (appearance) => ({
  type: UPDATE_SIGHTING_APPEARANCE,
  payload: appearance,
});

export const updateSightingDate = (date) => ({
  type: UPDATE_SIGHTING_DATE,
  payload: date,
});

export const updateSightingFlags = (flag) => ({
  type: UPDATE_SIGHTING_FLAGS,
  payload: flag,
});
export const updateSightingCategories = (category) => ({
  type: UPDATE_SIGHTING_CATEGORIES,
  payload: category,
});

export const updateSightingImages = (image) => ({
  type: UPDATE_SIGHTING_IMAGES,
  payload: image,
});

export const addGeolocateCoordinates = (coordinates) => ({
  type: ADD_GEOLOCATE_COORDINATES,
  payload: coordinates,
});

export const addReverseGeocodeDetails = (coordinates) => ({
  type: ADD_REVERSE_GEOCODE_DETAILS,
  payload: coordinates,
});
