import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../PageHeader";

// import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { db } from "../../firebase"; // add

import "../../sass/_admin.scss";
import AdminNav from "./AdminNav";

import ArchivedSightings from './ArchivedSightings';
import SightingListItem from './SightingListItem';

import { addAdminEmail } from "../../actions/adminActions";

import {
  addSightings,
  addArchivedSightings,
  addApprovedSightings,
  addPendingSightings,
} from "../../actions/adminActions";

const AdminSightings = () => {


  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState('approved');
  const [page, setPage] = useState(1)

  const [selectedSightings, setSelectedSightings] = useState([]);




  useEffect(() => {
    // setSelectedTab('approved');
    // getSightingsFromFirebase('approved')
  }, [])

  const fetchData = async (status) => {
    await db.collection('sightings')
      .where('status', '==', status)
      .orderBy('properties.sightingDate', 'desc')
      .limit(20)
      .onSnapshot(function (querySnapshot) {
        var items = [];
        querySnapshot.forEach(function (doc) {
          items.push({ id: doc.id, ...doc.data() });
        });
        console.log('first item ', items[0])
        setSelectedSightings(items);
      })
  };


  useEffect(() => {

    fetchData('approved');

  }, []);

  const showNext = ({ item }) => {
    if (selectedSightings.length === 0) {
      alert("Thats all we have for now !")
    } else {
      const fetchNextData = async () => {
        await db.collection('sightings')
          .where('status', '==', selectedTab)
          .orderBy('properties.sightingDate', 'desc')
          .limit(20)
          .startAfter(item.properties.sightingDate)
          .onSnapshot(function (querySnapshot) {
            const items = [];
            querySnapshot.forEach(function (doc) {
              items.push({ id: doc.id, ...doc.data() });
            });
            setSelectedSightings(items);
            setPage(page + 1)
          })
      };
      fetchNextData();
    }
  };

  const showPrevious = ({ item }) => {
    const fetchPreviousData = async () => {
      await db.collection('sightings')
        .where('status', '==', selectedTab)
        .orderBy('properties.sightingDate', 'desc')
        .endBefore(item.properties.sightingDate)
        .limitToLast(20)
        .onSnapshot(function (querySnapshot) {
          const items = [];
          querySnapshot.forEach(function (doc) {
            items.push({ id: doc.id, ...doc.data() });
          });
          setSelectedSightings(items);
          setPage(page - 1)
        })
    };
    fetchPreviousData();
  };


  // const getSightingsFromFirebase = (status) => {
  //   let sightings = [];
  //   db.collection("sightings").where("status", "==", status).orderBy("properties.sightingDate", "desc")
  //     .get()
  //     .then((querySnapshot) => {
  //       querySnapshot.forEach(function (doc) {
  //         let sightingData = doc.data()
  //         sightingData.id = doc.id;
  //         sightingData.properties.id = doc.id;
  //         sightings.push(sightingData);

  //       });
  //       setSelectedSightings(sightings)
  //     });
  // };




  const handleStatusChange = (id, status) => {
    console.log(
      `attempt to update document: ${id} to ${status}`
    )
    db.collection("sightings").doc(id).update({ status: status })
      .catch((error) => console.error("Error adding document: ", error))
      .then(console.log('success'));
  }


  const handleTabClick = (tab) => {


    setSelectedTab(tab)
    setPage(1)
    if (tab === 'your-sightings') {

    }
    else {
      fetchData(tab)
    }
    // getSightingsFromFirebase(tab)
  }


  const SightingsTabs = () => {
    return (
      <nav>
        <button
          className={`admin-sighting-tab ${selectedTab === 'approved' && 'active'} `}
          onClick={() => handleTabClick('approved')}
        >Approved</button>
        <button
          className={`admin-sighting-tab ${selectedTab === 'pending' && 'active'} `}
          onClick={() => handleTabClick('pending')}
        >Pending</button>
        <button
          className={`admin-sighting-tab ${selectedTab === 'archived' && 'active'} `}
          onClick={() => handleTabClick('archived')}
        >Archived</button>

        {/* <button
          className={`admin-sighting-tab ${selectedTab === 'your-sightings' && 'active'} `}
          onClick={() => handleTabClick('your-sightings')}
        >Your sightings</button> */}
      </nav>
    )
  }


  const SightingsRows = () => {
    return (
      selectedSightings.map((sighting) => {
        return <SightingListItem key={sighting.id} data={sighting} handleStatusChange={handleStatusChange} />
      })
    )

  }

  const UsersSightings = () => {
    const [usersSightings, setUsersSightings] = useState([]);

    const email = localStorage.getItem("ar_email");
    var userRefs = [];

    let userSightings = [];

    function getSightings() {
      console.log('getusersighings')
    }

    // const logCities = async () => {
    //   let citiesRef = db.collection('cities');
    //   let allCities = await citiesRef.get();
    //   for(const doc of allCities.docs){
    //     console.log(doc.id, '=>', doc.data());
    //   }
    // }

    db.collection('sightings')
      .where('userRef', '==', 'EQMVOJzHH5XGdaif8QCGDdlrKKf2')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(doc => {
          let docData = doc.data()
          console.log(docData)
        })

      })

    db.collection("users")
      .where('email', '==', email)
      .get()
      .then((querySnapshot) => {
        let accounts = []
        querySnapshot.forEach(doc => {
          accounts.push(doc.id)
        })
        console.log({ accounts })
        return accounts
      }).then((accounts) => {

        accounts.forEach(account => {
          console.log('foreachaccount')
          db.collection('sightings')
            .where('userRef', '==', account)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach(doc => {
                let sighting = doc.data()
                sighting.id = doc.id;
                console.log(sighting.id)
                // usersSightings.push(sighting)
                setUsersSightings(usersSightings => [...usersSightings, sighting]);
              })
            })
        })
        console.log('userSirhgings in foreach')
        console.log({ usersSightings })
        return usersSightings
      })
    // .catch((error) => console.error("Error adding document: ", error))
    // .then(getSightings());
    // .then((out) => {
    //   userRefs.forEach((ref) => {
    //     db.collection('sightings')
    //       .where('userRef', '==', ref)
    //       .onSnapshot(function (querySnapshot) {
    //         querySnapshot.forEach(function (doc) {
    //           userSightings.push(doc.data())
    //           console.log(userSightings)
    //         });
    //       })
    //   })
    // })


    return (
      <>
        {email && 'Sightings associated with:' + email}
        {usersSightings && usersSightings.map((sighting) => {
          return (
            <p>{sighting.properties.title}</p>
          )
        })}
      </>
    )
  }




  return (
    <div className='admin-page'>
      <AdminNav>
        <Link to="admin/new">
          {/* <button className="text-sm text-green-90 py-2 px-4 hover:bg-sage-10 rounded border border-sage-20 border-solid mr-2">Add a new sighting</button> */}
        </Link>
      </AdminNav>
      <PageHeader title='Sightings' />

      <div className="container py-8">
        <SightingsTabs />
        <div className="admin-sightings-table">
          {selectedSightings &&
            selectedSightings.length > 0 &&
            <>

              {/* {selectedTab === 'your-sightings' && <UsersSightings />} */}

              {(selectedTab === 'approved' || selectedTab === 'pending' || selectedTab === 'archived') &&
                <SightingsRows />}

            </>
          }


          {/* {selectedTab === 'archived' && <ArchivedSightings />} */}
        </div>
        <div className='flex w-full h-12 py-4 mb-4'>
          {
            //show previous button only when we have items
            page === 1 ? '' :
              <button className='mr-auto ml-0 bg-white shadow h-12 items-center px-4 rounded-md'
                onClick={() => showPrevious({ item: selectedSightings[0] })}>Previous</button>
          }

          {
            //show next button only when we have items
            selectedSightings.length < 5 ? '' :
              <button className='mr-0 ml-auto bg-white shadow flex items-center h-12 px-4 rounded-md'
                onClick={() => showNext({ item: selectedSightings[selectedSightings.length - 1] })}>Next</button>
          }
        </div>
      </div>
    </div>
  )
}

export default AdminSightings
