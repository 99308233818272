import React, { useEffect } from 'react'
import FieldGroup from '../FieldGroup'
import { Controller, useWatch } from "react-hook-form";
import DatePicker from 'react-datepicker';
import { useDispatch } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import './react-datepicker-overrides.scss'
import { updateSightingDetails } from '../../../actions/newSightingActions';

export default function FormFieldDate({ control, visible, setValue }) {
	const dispatch = useDispatch()
	const watchSightingDate = useWatch({ control, name: 'sightingDate' })


	useEffect(() => {
		let convertedDate = new Date(watchSightingDate).getTime();
		dispatch(updateSightingDetails({ sightingDate: convertedDate }));
	}, [watchSightingDate])


	return <FieldGroup
		label='When did you last see the rabbit?'
		control={control}
		fieldName='sightingDate'>
		<Controller
			control={control}
			name='sightingDate'
			rules={{ required: 'Please let us know when you last saw the rabbit. Approximate date is okay.' }}
			render={({ field, fieldState }) => (<>
				<DatePicker
					placeholderText='Select date'
					onChange={(date) => field.onChange(date)}
					selected={field.value}
					maxDate={new Date()}
					dateFormat="MMMM d, yyyy"
					todayButton="Today"
					className='form-field__input'
				/>
			</>
			)}
		/>
	</FieldGroup>
}