// add useContext
import React, { useContext } from "react";
import { firebaseAuth } from "../../AuthProvider";
import PageHeader from "../PageHeader";

const Signin = ({admin}) => {
  const { handleSignin, inputs, setInputs, errors } = useContext(firebaseAuth);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("handleSubmit");
    handleSignin();
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const SIGN_IN_COPY = `Thank you for your interest in our mapping project.
  The map feature is now visible only to administrators.
  If you are a rabbit rescue or otherwise require additional information, please reach out to us at <a class='text-green-70 hover:underline' href='mailto:info@abandonedrabbits.com'>info@abandonedrabbits.com</a>.`

  return (
    <>
      <PageHeader 
      title={admin ? 'Admin sign-in' : 'Sign-in'}
      copy={admin ? 'This is the sign-in for project administrators' : SIGN_IN_COPY}
      ></PageHeader>
      <div className='container py-8'>
        <form onSubmit={handleSubmit} className='max-w-sm'>
          {/* replace the div tags with a form tag */}
          {/* make inputs  */}
          <label className='form-field__label'>Email</label>
          <input
            className='form-field__input mb-4'
            onChange={handleChange}
            name="email"
            type='email'
            placeholder="Email"
            value={inputs.email}
          />
          <label className='form-field__label'>Password</label>
          <input
            className='form-field__input mb-4'
            onChange={handleChange}
            name="password"
            type="password"
            placeholder="Password"
            value={inputs.password}
          />
          <button className='h-12 bg-white flex items-center px-4 rounded text-green-90 mt-4 shadow'>Sign In</button>
          {errors.length > 0
            ? errors.map((error) => <p style={{ color: "red" }}>{error}</p>)
            : null}
        </form>
      </div>
    </>
  );
};

export default Signin;
