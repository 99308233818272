
function capitalize(s) {
	if (typeof s !== 'string') return ''
	return s.charAt(0).toUpperCase() + s.slice(1)
}


function labelize(string) {
	let label = string.replace(/-/g, ' ');
	label = capitalize(label)
	return label
}


module.exports = { labelize, capitalize };