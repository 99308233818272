import React, { useState, useEffect, useRef } from 'react';
import { connect, useSelector, useDispatch } from "react-redux";


import {
	updateSightingImages,
} from "../../../actions/newSightingActions";
import firebase from "@firebase/app";
import "@firebase/firestore";
import "@firebase/auth";
import "@firebase/storage";

import FieldGroup from '../FieldGroup';

import './ImageUpload.scss'


const ImageUpload = () => {
	const dispatch = useDispatch();

	const [uploadProgress, setUploadProgress] = useState({ uploadProgress: 0 });
	const [uploadStatus, setUploadStatus] = useState({ uploadStatus: null });
	const [drag, setDrag] = useState({ drag: false });
	const [stateFiles, setStateFiles] = useState([]);
	const [errorMessage, setErrorMessage] = useState();


	const fileRef = useRef();
	const progressRef = useRef();
	const dropzoneRef = useRef();

	//CONSIDER USING FINITE STATE MACHINE
	//UPLOAD STATUS OPTIONS = ['started', 'complete' ]


	useEffect(() => {
		const dropzone = dropzoneRef.current;
		// subscribe event
		dropzone.addEventListener("dragenter", handleDragIn);
		dropzone.addEventListener("dragleave", handleDragOut);
		dropzone.addEventListener("dragover", handleDrag);
		dropzone.addEventListener("drop", handleDrop);
		return () => {
			// unsubscribe event
			dropzone.removeEventListener("dragenter", handleDragIn);
			dropzone.removeEventListener("dragleave", handleDragOut);
			dropzone.removeEventListener("dragover", handleDrag);
			dropzone.removeEventListener("drop", handleDrop);
		};
	}, []);

	function checkSupportedFileType(ext) {
		let supported = false
		switch (ext) {
			case 'jpeg':
			case 'png':
			case 'webp':
			case 'heic':
				supported = true
				break;
			default:
		}
		console.log({ ext })
		console.log({ supported })
		return supported
	}

	function handleUnsupportedFileType(ext) {
		setUploadStatus('error')
		setErrorMessage(ext ? `${ext} files are not supported` : `This type of file is not supported`)
	}


	let dragCounter = 0;

	const handleDrag = (e) => {
		console.log("drag");
		e.preventDefault();
		e.stopPropagation();
	};
	const handleDragIn = (e) => {
		console.log("dragIn");
		e.preventDefault();
		e.stopPropagation();
		dragCounter++;
		if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
			setDrag({ drag: true });
		}
	};
	const handleDragOut = (e) => {
		console.log("dragOut");
		e.preventDefault();
		e.stopPropagation();
		dragCounter--;
		if (dragCounter === 0) {
			setDrag({ drag: false });
		}
	};
	const handleDrop = (e) => {
		console.log("drop");
		e.preventDefault();
		e.stopPropagation();
		setDrag({ drag: false });
		if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
			console.log("before handleFileUpload");
			handleFileUpload(e.dataTransfer.files);
			e.dataTransfer.clearData();
			dragCounter = 0;
		}
	};

	const handleFileUpload = (e) => {
		let passedFiles = [];

		setErrorMessage(null)
		setUploadStatus('started')

		// Normalize args from dropzone and input
		e.hasOwnProperty("target")
			? (passedFiles = e.target.files)
			: (passedFiles = e);

		let files = [];

		for (let i = 0; i < passedFiles.length; i++) {
			const newFile = passedFiles[i];
			console.log({ newFile })

			// let reader = new FileReader();
			// let url = reader.readAsDataURL(newFile);
			let previewUrl = URL.createObjectURL(newFile)
			newFile['previewUrl'] = previewUrl

			let randomFileNumber = (Math.random() * 100).toString();
			let fileID = randomFileNumber.split(".").join("");
			newFile["id"] = fileID;
			newFile["ext"] = newFile.type.split("image/").join("");
			// add an "id" property to each File object
			if (checkSupportedFileType(newFile.ext)) {
				files.push(newFile);
				setStateFiles((prevState) => [...prevState, newFile]);
			} else {
				handleUnsupportedFileType(newFile.ext)
				return false
			}

		}

		setUploadStatus({ uploadStatus: "started" });

		uploadToGCP(files)

	};

	//https://stackoverflow.com/questions/48371457/multiple-progress-bars-for-multiple-files-upload-firebase


	const uploadToGCP = (files) => {
		const promises = [];
		files.forEach((file) => {
			const uploadTask = firebase
				.storage()
				.ref()
				.child(`abandoned_rabbits_${file.id}.${file.ext}`)
				.put(file);
			promises.push(uploadTask);
			uploadTask.on(
				firebase.storage.TaskEvent.STATE_CHANGED,
				(snapshot) => {
					const progress =
						(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					if (snapshot.state === firebase.storage.TaskState.RUNNING) {
						setUploadProgress({ uploadProgress: progress });
						console.log(`Progress: ${progress}%`);
					}
				},
				(error) => console.log(error.code),
				async () => {
					const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
					dispatch(updateSightingImages(downloadURL));
				}
			);
		});
		Promise.all(promises)
			.then(() => setUploadStatus({ uploadStatus: "complete" }))
			.catch((err) => console.log(err.code));
	}

	const SingleFile = ({ previewUrl }) => {
		return <div >File <img className='h-16 w-16 object-cover' src={previewUrl} /> </div>
	}


	return (
		<div className={`form-field`}>
			<FieldGroup label='Images' customError={errorMessage}>
				{/* <label className="form-field__label">Images</label> */}
				<label
					htmlFor="upload"
					ref={dropzoneRef}
					className={`uploader__dropzone ${drag.drag && "dragging"}`}
				>
					<div className='uploader__inner'>
						<p className='mb-1'>
							Drag and drop to upload or <span>browse</span> to choose a file
						</p>
						<p className='text-xs'>Accepted file types: jpeg, png, heic</p>
						<input
							className="uploader__input"
							id="upload"
							type="file"
							ref={fileRef}
							onChange={handleFileUpload}
							multiple
						/>
					</div>
					{/* {stateFiles.map((file, i) => {
					return <SingleFile key={`image-upload-${i}`}  {...file} />
				})} */}
					{(uploadStatus.uploadStatus === "started" ||
						uploadStatus.uploadStatus === "complete") && (
							<progress
								className='w-full image-upload__progress'
								ref={progressRef}
								value={uploadProgress.uploadProgress}
							></progress>
						)}
					{uploadStatus.uploadStatus === "started" && (
						<p className='text-sm'>Uploading {stateFiles.length} files</p>
					)}
					{uploadStatus.uploadStatus === "complete" && (
						<p className='text-sm'>Success! Uploaded {stateFiles.length} files</p>
					)}
				</label>
			</FieldGroup>
		</div>
	)

}

export default ImageUpload;